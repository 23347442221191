import React, { ReactElement, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import * as Pages from './pages/index';
// import * as CommonPages from './common_lib_front/pages';
import Header from './components/header/header';
import store, { isLogedIn as checkIsLogedIn } from './common_lib_front/utilities/store';
import './css';

const loggedInRoutes = [
  <Route exact path="/">
    <Pages.Home />
  </Route>,
  <Route path={['/pass-builder/:registrationId', '/pass-builder']}>
    <Pages.PBWrapper />
  </Route>,
  <Route path="/upload-education">
    <Pages.EducationUpload />
  </Route>,
  <Route path="/pos-config">
    <Pages.POSConfig />
  </Route>,
  <Route path="/payment-config">
    <Pages.PaymentConfig />
  </Route>,
  <Route path="/add-community">
    <Pages.AddCommunity />
  </Route>,
  <Route path="/popup/:communityId/:portalName/create-group">
    <Pages.CreateGroupPopUp />
  </Route>,
  <Route path="/popup/:groupId/rename-group">
    <Pages.RenameGroupPopUp />
  </Route>,
  <Route path="/popup/pass-preview">
    <Pages.PassPreviewPopUp
      isOpen
    />
  </Route>,
  <Route path="/edit-profile/my-profile">
    <Pages.MyProfile />
  </Route>,
  <Route path="/edit-profile/reset-password">
    <Pages.ResetPassword />
  </Route>,
];

const loggedOutRoutes = [
  <Route exact path="/create-account">
    <Pages.CreateAccount />
  </Route>,
  <Route exact path="/login">
    <Pages.Login />
  </Route>,
  <Route exact path="/forgot-password">
    <Pages.ForgotPasswordEmail />
  </Route>,
  <Route exact path="/forgot-password/verification-code">
    <Pages.ForgotPasswordVerification />
  </Route>,
  <Route path="/forgot-password/reset-password/:identifier">
    <Pages.ForgotPasswordReset />
  </Route>,
  <Route path="/">
    <Redirect to="/login" />
  </Route>,
];

export default function App(): ReactElement {
  const [isLogedIn, setIsLogedIn] = useState<boolean>(checkIsLogedIn());
  store.subscribe(() => setIsLogedIn(checkIsLogedIn()));

  return (
    <div className="container">
      <Router>
        <Header />
        <Switch>
          {isLogedIn // check login
            ? loggedInRoutes
            : loggedOutRoutes }

        </Switch>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}
