import React, { ReactElement } from 'react';
import CommunitySelect from '../../components/communitySelect/communitySelect';
import EducationUpload from '../../components/educationUpload/educationUpload';
import InfoBackground from '../../components/infoBackground/infoBackground';
import style from './uploadEducation.module.css';
import fileImg from '../../assets/file.svg';
import useUploadEducation from './useUploadEducation';

export default function UploadEducation() :ReactElement {
  const {
    community,
    setCommunity,
    educations,
    educationsLoading,
    educationsError,
    deleteEducation,
    educationsRefetch,
    uploadEducation,
  } = useUploadEducation();

  return (
    <div className={style.container}>
      <div className={style.box}>
        <InfoBackground
          title="Upload Educations"
        >
          <div className={style.communitySelectBox}>
            <div className={style.innerBox}>
              <h4 className={style.locationTitle}>
                Education Location

              </h4>
              <div className={style.select}>
                <CommunitySelect
                  id="upload-education-community-select"
                  value={community}
                  changeHandler={(val) => setCommunity(val)}
                />

              </div>
              {' '}

            </div>
            {
          community
            ? (
              <div className={style.uploadBox}>
                <div className={style.flexBox}>
                  <div className={style.upload}>
                    <div>
                      <EducationUpload
                        id="education-upload"
                        uploadHandler={uploadEducation}
                      />
                      <div className={style.uploadedTitle}>
                        Uploaded
                      </div>
                      {educations.map((e) => (
                        <div
                          key={e.educationId}
                          className={style.uploadedItem}
                        >
                          <div className={style.uploaded}>
                            <img src={fileImg} alt="" />
                            {e.name}
                          </div>
                          <button
                            className={style.cancel}
                            onClick={() => {
                              deleteEducation(e.educationId)
                                .then(() => educationsRefetch());
                            }}
                          >
                            &nbsp;
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* <div className={style.upload}>
                    <div>
                      <EducationUpload
                        language="Spanish"
                        id="spanish-education-upload"
                      />
                      <div className={style.uploadedTitle}>
                        Uploaded
                      </div>
                      {educations
                        .filter((e) => e === 1)
                        .map((e) => (
                          <div className={style.uploadedItem}>
                            <div className={style.uploaded}>
                              <img src={fileImg} alt="" />
                              Guest Education Spanish
                            </div>
                            <button className={style.cancel}>&nbsp;</button>
                          </div>
                        ))}
                    </div>
                  </div> */}

                </div>
              </div>
            ) : null
        }

          </div>
        </InfoBackground>
      </div>
    </div>
  );
}
