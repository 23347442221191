import React, { ReactElement } from 'react';
import style from './dropContainer.module.css';

export default function DropContainer(props: {
  id: string,
  children: Array<ReactElement>,
  onDragOver: () => void,
  onDragLeave: () => void,
  title?: string,
}) :ReactElement {
  const {
    id,
    children,
    onDragOver,
    onDragLeave,
    title,
  } = props;

  function allowDrop(ev:any) {
    ev.preventDefault();
  }

  return (
    <div
      id={id}
      onDragOver={(ev:React.DragEvent) => {
        allowDrop(ev);
        onDragOver();
      }}
      onDragLeave={(ev:React.DragEvent) => {
        onDragLeave();
      }}
      className={style.dropContainer}
    >
      <div className={style.titleBox}>{title}</div>
      <div className={style.dropArea}>
        {children}

      </div>
    </div>
  );
}
DropContainer.defaultProps = {
  title: '',
};
