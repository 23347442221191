import { gql, useLazyQuery, useQuery } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import style from './instruction.module.css';

type Props = {
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  stepNum?: number,
  submitHandler?: () => void,
  changeData?: InstructionData,
  editChangeData?: (
    key: keyof InstructionData,
    val: InstructionData[keyof InstructionData]
  ) => void,
}

const GET_INFORMATION = gql`
query getPassInfo(
  $passInfoId: String!
){
  getPassInfo(
    passInfoId: $passInfoId
  ){
    success
    error
    data {
      customInstructions
    }
  }
}
`;

export type InstructionData = {
  customInstructions?: string,
  [key: string]: undefined | string,
}

export default function InstructionsForm(props: Props): ReactElement {
  const {
    registrationId,
    editBtnLink,
    showContinueBtn,
    stepNum,
    submitHandler,
    changeData,
    editChangeData,
  } = props;
  let form;

  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  const { data, loading, error } = useQuery<{
    getPassInfo: {
      data: InstructionData, error: string, success: boolean,
    }
  }>(GET_INFORMATION, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
  });
  return (
    <form
      className={form}
      onSubmit={() => {
        if (submitHandler) {
          submitHandler();
        }
      }}
    >
      {
        editBtnLink
          ? (
            <div className={style.editBtn}>
              <Link to={editBtnLink} className={style.link}>
                <GenericButton
                  title="Edit"
                />
              </Link>

            </div>
          ) : null
      }
      <div className={style.instructionBox}>
        <div className={style.insructionTitleBox}>
          <h4 className={style.instructionTitle}>
            Step
            {' '}
            {stepNum || 8}
            : Custom Pass Instructions
          </h4>

        </div>
        <label
          htmlFor="instruction-inpt"
          className={style.passInstructionLabel}
        >
          Instructions
          <textarea
            className={style.passInstructionTextArea}
            placeholder={data?.getPassInfo?.data?.customInstructions || ''}
            value={changeData?.customInstructions || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => (
              editChangeData && editChangeData('customInstructions', e.target.value)
            )}
          />
        </label>

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />
          </div>
        </div>
      ) : null}
    </form>
  );
}
InstructionsForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  stepNum: undefined,
  submitHandler: undefined,
  changeData: {},
  editChangeData: undefined,
};
