import React, { ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { ReactComponent as GuestImg } from '../../../assets/guestPassBuilder.svg';
import { ReactComponent as ResidentImg } from '../../../assets/residentPassBuilder.svg';
import { ReactComponent as VendorImg } from '../../../assets/vendorPassBuilder.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import CommunitySelect from '../../../components/communitySelect/communitySelect';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './intialization.module.css';
import useInitialization from './useInitialization';

// type Props = {
//   communityName?: string,
//   setCommunityName?: (val:string) => void,
//   portalName?: string,
//   setPortalName?: (val:string) => void,
// }

const portalConfigs: {
  name: string,
  portal: string,
  image: ReactElement,
}[] = [
  {
    name: 'Guest',
    portal: 'guest',
    image: <GuestImg />,
  },
  {
    name: 'Resident',
    portal: 'resident',
    image: <ResidentImg />,
  },
  {
    name: 'Vendor',
    portal: 'vendor',
    image: <VendorImg />,
  },
];

export default function Initialization() :ReactElement {
  // const {
  //   communityName, setCommunityName, portalName, setPortalName,
  // } = props;
  const {
    createPass,
    newPassId,
    newPassError,
    communityName,
    setCommunityName,
    portalName,
    setPortalName,
  } = useInitialization();

  if (newPassId) {
    return (
      <Redirect to={`/pass-builder/${newPassId}/pass-info`} />
    );
  }
  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder and Pass List"
      >
        <form
          onSubmit={(e:React.FormEvent) => {
            e.preventDefault();
            createPass();
          }}
          className={style.form}
        >
          <GenericAlert
            title={newPassError}
            hidden={!newPassError}
            color="red"
          />
          <div className={style.passLocationBox}>
            <div className={style.passLocationTitleBox}>
              <h4 className={style.passLocationTitle}>Pass Location</h4>

            </div>
            <CommunitySelect
              id="community-selector"
              value={communityName}
              changeHandler={setCommunityName}
            />
            {
              communityName
                ? (
                  <div className={style.communityBtnBox}>
                    <h4 className={style.selectPortalTitle}>
                      Select a portal

                    </h4>
                    <div className={style.roleBtnBox}>
                      {portalConfigs.map((cfg) => (
                        <button
                          type="button"
                          onClick={() => {
                            if (setPortalName) {
                              setPortalName(cfg.portal);
                            }
                          }}
                          className={`${style.roleBtn} ${portalName === cfg.portal ? style.active : ''}`}
                        >
                          <div className={`${style.colorLine} ${style.colorGuest}`} />
                          <span className={style.btnContent}>
                            {cfg.image}
                            <p className={style.text}>
                              {cfg.name}
                            </p>
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                ) : null
            }
            {
        portalName
          ? (
            <div className={style.actionBtnBox}>
              {/* <Link to="/pass-builder/000/pass-info"> */}
              <div className={style.actionBtn}>
                <GenericButton
                  type="submit"
                  title="Create Pass"
                />
              </div>
              {/* </Link> */}

              <div className={style.actionBtn}>
                <Link to={`/pass-builder/${communityName}/${portalName}/manage-passes`}>
                  <GenericButton
                    type="button"
                    title="Manage Passes"
                  />
                </Link>

              </div>

            </div>
          ) : null
      }
          </div>
        </form>

      </InfoBackground>

    </div>
  );
}
Initialization.defaultProps = {
  communityName: undefined,
  setCommunityName: undefined,
  portalName: undefined,
  setPortalName: undefined,
};
