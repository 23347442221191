import React, { ReactElement } from 'react';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import CommunitySelect from '../../components/communitySelect/communitySelect';
import InfoBackground from '../../components/infoBackground/infoBackground';
import style from './paymentConfig.module.css';
import usePaymentConfig from './usePaymentConfig';

export default function PaymentConfig() :ReactElement {
  const {
    community,
    setCommunity,
    allowFee,
    setAllowFee,
    flatFeeAmount,
    setFlatFeeAmount,
    feePercentage,
    setFeePercentage,
    submitHandler,
    alert,
  } = usePaymentConfig();

  return (
    <div className={style.container}>
      <div className={style.box}>
        <InfoBackground
          title="Payment Configuration"
        >
          <GenericAlert
            title={alert}
            hidden={!alert}
            color="red"
          />
          <div className={style.communitySelectBox}>
            <div className={style.innerBox}>
              <CommunitySelect
                id="community-selector"
                value={community}
                changeHandler={setCommunity}
              />
              <div>
                {
          community
            ? (
              <form
                className={style.infoInputPart}
                onSubmit={(e:React.FormEvent) => {
                  e.preventDefault();
                  submitHandler();
                }}
              >
                <h4 className={style.customTitle}>
                  Custom Credit Card Fee

                </h4>
                <div className={style.flexBox}>
                  <div className={allowFee ? style.customBtnActive : style.customBtnInactive}>
                    <GenericButton
                      title="Custom Credit Card Fee"
                      color={allowFee ? 'blue' : 'grey'}
                      clickHandler={() => setAllowFee(true)}
                    />

                  </div>
                  <div className={allowFee ? style.noInactive : style.noActive}>
                    <GenericButton
                      title="No"
                      color={allowFee ? 'grey' : 'blue'}
                      clickHandler={() => setAllowFee(false)}
                    />

                  </div>

                </div>
                {
                  allowFee
                    ? (
                      <div>
                        <h4 className={style.creditCardFeeTitle}>
                          Additional Credit Card Fee

                        </h4>
                        <div className={style.PercentageInput}>
                          <InputField
                            htmlFor="cc-fee-percent"
                            labelTitle="Percentage of the total transaction fee"
                            inputType="number"
                            step="any"
                            inputValue={feePercentage.toString()}
                            changeHandler={(e) => setFeePercentage(
                              (Number(e.target.value) >= 0) ? Number(e.target.value) : 0,
                            )}
                          />

                        </div>
                        <div className={style.fixedInput}>
                          <InputField
                            htmlFor="cc-fee-fixed"
                            labelTitle="Fixed fee that may be added to each transaction"
                            inputType="number"
                            step="any"
                            inputValue={flatFeeAmount.toString()}
                            changeHandler={(e) => setFlatFeeAmount(
                              (Number(e.target.value) >= 0) ? Number(e.target.value) : 0,
                            )}
                          />

                        </div>
                      </div>
                    ) : null
                }
                <div className={style.saveBtnBox}>
                  <div className={style.saveBtn}>
                    <GenericButton
                      title="Save Changes"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            ) : null
        }
              </div>

            </div>
          </div>
        </InfoBackground>
      </div>
    </div>
  );
}
