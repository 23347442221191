import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import DragAndDrop from '../../../components/dragAndDrop/dragAndDrop';
import style from './employeeInfo.module.css';
import useEmployeeInfoForm from './useEmployeeInfoForm';

type Props = {
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  stepNum?: number,
}

export default function EmployeeInfoForm(props: Props) :ReactElement {
  const {
    registrationId, editBtnLink, showContinueBtn, stepNum,
  } = props;
  const {
    requireEmployeeInfo,
    setRequireEmployeeInfo,
    employeeDocsList,
    editEmployeeDocsList,
    employeeInfoList,
    editEmployeeInfoList,
    submitHandler,
  } = useEmployeeInfoForm(registrationId);
  const history = useHistory();

  let form;

  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  console.log(employeeDocsList.find((e) => e.name === 'jobTitle'));
  return (
    <form
      className={form}
      onSubmit={(e:React.FormEvent) => {
        e.preventDefault();
        submitHandler()
          .then(() => history.push(`/pass-builder/${registrationId}/vehicle-info`));
      }}
    >
      {
            editBtnLink
              ? (
                <div className={style.editBtn}>
                  <Link to={editBtnLink} className={style.link}>
                    <GenericButton
                      title="Edit"
                    />
                  </Link>
                </div>
              ) : null
          }
      <div className={style.employeeInfoBox}>
        <div className={style.employeeInfoTitleBox}>
          <h1 className={style.employeeInfoTitle}>
            Step
            {' '}
            {stepNum || 2}
            : User Information
          </h1>

        </div>
        <div className={style.optionBox}>
          <h4 className={style.optionTitle}>
            Select if employee upload is required
          </h4>
          <div className={style.optionBtnsBox}>
            <div className={` ${requireEmployeeInfo === 'required' ? style.btnBlue : style.btnWhite}`}>
              <GenericButton
                title="Required"
                type="button"
                clickHandler={() => setRequireEmployeeInfo('required')}
                color="none"
              />

            </div>
            <div className={` ${requireEmployeeInfo === 'optional' ? style.btnBlue : style.btnWhite}`}>
              <GenericButton
                title="Optional"
                type="button"
                clickHandler={() => setRequireEmployeeInfo('optional')}
                color="none"
              />

            </div>
            <div className={` ${requireEmployeeInfo === 'disabled' ? style.btnBlue : style.btnWhite}`}>
              <GenericButton
                title="Disabled"
                type="button"
                clickHandler={() => setRequireEmployeeInfo('disabled')}
                color="none"
              />

            </div>
          </div>

        </div>

        {
        requireEmployeeInfo !== 'disabled'
          ? (
            <div className={style.dropContainer}>

              <div className={style.employeeInfoArea}>
                <DragAndDrop
                  id="employee-info-dnd"
                  containers={[
                    { title: 'Required', value: 'required' },
                    { title: 'Optional', value: 'optional' },
                    { title: 'Disabled', value: 'disabled' },
                  ]}
                  items={employeeInfoList}
                  updateItem={editEmployeeInfoList}
                />

              </div>
              <div className={style.employeeDocArea}>
                <DragAndDrop
                  id="employee-docs-dnd"
                  containers={[
                    { title: 'Required', value: 'required' },
                    { title: 'Optional', value: 'optional' },
                    { title: 'Disabled', value: 'disabled' },
                  ]}
                  items={employeeDocsList}
                  updateItem={editEmployeeDocsList}
                />

              </div>
            </div>
          ) : null
      }
        {' '}

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />

          </div>
        </div>
      ) : null}

    </form>
  );
}
EmployeeInfoForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  stepNum: undefined,
};
