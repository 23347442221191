import { gql, useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DragAndDropItem } from '../../../components/dragAndDrop/dragAndDrop';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './vehicleInfo.module.css';
import VehicleInfoForm from './vehicleInfoForm';

const EDIT_VEHICLE_INFO = gql`
mutation Mutation(
	$newVehicleInfoConfig: VehicleInfoConfigInput!, 
	$passInfoId: String!
) {
  setVehicleInfoConfig(
    newVehicleInfoConfig: $newVehicleInfoConfig
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function VehicleInfo(props: Props): ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  const history = useHistory();

  const [doEdit] = useMutation(EDIT_VEHICLE_INFO);
  const submitHandler = (data: Array<DragAndDropItem>) => {
    const newVehicleInfoConfig: any = {};
    data.forEach((i: DragAndDropItem) => {
      newVehicleInfoConfig[i.name] = i.value;
    });
    doEdit({
      variables: {
        passInfoId: registrationId,
        newVehicleInfoConfig,
      },
    });
    history.push(`/pass-builder/${registrationId}/price-info`);
  };

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <VehicleInfoForm
          registrationId={registrationId}
          showContinueBtn
          stepNum={stepNum}
          submitHandler={submitHandler}
        />
      </InfoBackground>

    </div>
  );
}
VehicleInfo.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
