import React, { ReactElement } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import useAddCommunity from './useAddCommunity';
import style from './addCommunity.module.css';
import InfoBackground from '../../components/infoBackground/infoBackground';

export default function AddCommunity(): ReactElement {
  const {
    currentCommunity,
    setCurrentCommunityId,
    allCommunities,
    editCommunity,
    saveChanges,
  } = useAddCommunity();

  return (
    <div
      className={style.container}
    >
      <div className={style.box}>
        <InfoBackground title="Add Community">
          <div className={style.flexBox}>
            <div className={style.communityList}>
              {allCommunities?.map((c) => (
                <button
                  key={c.communityId}
                  onClick={() => setCurrentCommunityId(c.communityId)}
                  className={currentCommunity.name === c.name ? style.active : style.inactive}
                >
                  {c.name}
                </button>
              ))}
              <div className={style.addNewBtn}>
                <button
                  onClick={() => setCurrentCommunityId('')}
                >
                  Add New Community
                </button>

              </div>
            </div>
            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                saveChanges();
              }}
              className={style.communityDetails}
            >
              <div className={style.infoBox}>
                <div className={style.inputArea}>
                  <div className={style.input}>
                    <InputField
                      htmlFor="community-name-inpt"
                      labelTitle="Community Name"
                      required
                      inputValue={currentCommunity.name}
                      changeHandler={(e) => editCommunity({ name: e.target.value })}
                    />
                  </div>
                  <div className={style.input}>
                    <InputField
                      htmlFor="community-id-inpt"
                      labelTitle="Community Id"
                      disabled={allCommunities?.some(
                        (c) => c.communityId === currentCommunity.communityId,
                      )}
                      required
                      inputValue={currentCommunity.communityId}
                      changeHandler={(e) => editCommunity(
                        { communityId: e.target.value.toLowerCase() },
                      )}
                    />

                  </div>
                  <div className={style.input}>
                    <InputField
                      htmlFor="community-registration-code-inpt"
                      labelTitle="Resident Registration Code"
                      inputValue={currentCommunity.registrationCode}
                      changeHandler={(e) => editCommunity({ registrationCode: e.target.value })}
                    />

                  </div>
                </div>
                <div className={style.checkBoxArea}>
                  <div className={style.checkBox}>
                    <InputField
                      htmlFor="community-host-box"
                      inputType="checkbox"
                      labelTitle="Host Portal"
                      checked={currentCommunity.host}
                      changeHandler={(e) => editCommunity({ host: e.target.checked })}
                    />
                  </div>
                  <div className={style.checkBox}>
                    <InputField
                      htmlFor="community-pass-features-box"
                      inputType="checkbox"
                      labelTitle="Host Pass Features"
                      checked={currentCommunity.passFeatures}
                      changeHandler={(e) => editCommunity({ passFeatures: e.target.checked })}
                    />

                  </div>
                  <div className={style.checkBox}>
                    <InputField
                      htmlFor="community-gate-code-box"
                      inputType="checkbox"
                      labelTitle="Resident Gate Code Features"
                      checked={currentCommunity.gateCode}
                      changeHandler={(e) => editCommunity({ gateCode: e.target.checked })}
                    />

                  </div>

                </div>

              </div>
              <div className={style.submitBtn}>
                <GenericButton
                  title={allCommunities?.some((c) => c.communityId === currentCommunity.communityId) ? 'Save Changes' : 'Add Community'}
                  type="submit"
                />

              </div>
            </form>
          </div>
        </InfoBackground>
      </div>
    </div>
  );
}
