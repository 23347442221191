import React, { ReactElement } from 'react';
import Card from '../../../components/card/card';
// import reportCenterIcon from '../../../assets/reportCenter.svg';
import passBuilderIcon from '../../../assets/passBuilder.svg';
import educationIcon from '../../../assets/education.svg';
import posConfigurationIcon from '../../../assets/pos.svg';
import paymentConfigurationIcon from '../../../assets/paymentConfig.svg';
import addCommunityIcon from '../../../assets/addCommunity.svg';
import style from './home.module.css';
import { Footer } from '../../../components/footer/footer';

export default function Home() :ReactElement {
  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={style.box__innerBox}>
          <Card
            img={passBuilderIcon}
            alt="Pass Builder"
            title="Admin Pass Builder"
            description="Build Passes"
            buttonLabel="Build Passes"
            href="/pass-builder"
          />
        </div>
        <div className={style.box__innerBox}>
          <Card
            img={educationIcon}
            alt="Education Upload"
            title="Upload Education"
            description="Upload Education"
            buttonLabel="Upload Education"
            href="/upload-education"
          />
        </div>
        <div className={style.box__innerBox}>
          <Card
            img={posConfigurationIcon}
            alt="Point Of Sale Configuration img"
            title="POS Configuration"
            description="Configure point of sale"
            buttonLabel="POS Configuration"
            href="/pos-config"
          />
        </div>
        <div className={style.box__innerBox}>
          <Card
            img={paymentConfigurationIcon}
            alt="Point Of Sale Configuration img"
            title="Payment Configuration"
            description="Configure Payment Fees"
            buttonLabel="Payment Configuration"
            href="/payment-config"
          />
        </div>
        <div className={style.box__innerBox}>
          <Card
            img={addCommunityIcon}
            alt="Add Community img"
            title="Add Community"
            description="Add or edit community"
            buttonLabel="Add Community"
            href="/add-community"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
