import { useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import { PopUp } from '../../../components/popUp/popUp';
import { CREATE_GROUP } from '../../passBuilder/managePasses/manageGqls';
import style from './createGroup.module.css';

interface ParamTypes {
  communityId: string
  portalName: string
}

export default function CreateGroup(): ReactElement {
  const history = useHistory();
  const { communityId, portalName } = useParams<ParamTypes>();
  const [groupName, setgroupName] = useState('');

  const [createGroup] = useMutation(CREATE_GROUP, {
    onError: () => {
      // console.log(e);
    },
    onCompleted: (d) => {
      console.log(d);
    },
  });

  const handleCreateGroup = () => {
    createGroup({
      variables: {
        name: groupName,
        communityId,
      },
    });
  };

  return (
    <PopUp
      title=""
      close={() => history.goBack()}
    >
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          history.goBack();
        }}
        className={style.form}
      >
        <div className={style.innerBox}>
          <div className={style.nameInput}>
            <InputField
              labelTitle="Please enter the group name"
              htmlFor="group-name-inpt"
              inputValue={groupName}
              changeHandler={(e) => setgroupName(e.target.value)}
            />
          </div>

        </div>
        <div className={style.createBtn}>
          <GenericButton
            title="Create Group"
            type="submit"
            clickHandler={handleCreateGroup}
          />
        </div>
      </form>
    </PopUp>
  );
}
