import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './instruction.module.css';
import InstructionsForm, { InstructionData } from './instructionsForm';

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

const EDIT_INFORMATION = gql`
mutation Mutation(
	$newPassInfo: PassInfoInput!,
	$passInfoId: String!
) {
  setPassInfo(
    newPassInfo: $newPassInfo
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

export default function Instructions(props: Props): ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const history = useHistory();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [changeData, setChangeData] = useState<InstructionData>({});
  const editChangeData = (
    key: keyof InstructionData,
    val: InstructionData[keyof InstructionData],
  ) => {
    const res = { ...changeData };
    res[key] = val;
    setChangeData(res);
  };

  const [doEdit, { error: editError }] = useMutation(EDIT_INFORMATION);
  const submitHandler = () => {
    doEdit({
      variables: {
        newPassInfo: { ...changeData },
        passInfoId: registrationId,
      },
    });
    history.push(`/pass-builder/${registrationId}/display-info`);
  };

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <InstructionsForm
          changeData={changeData}
          editChangeData={editChangeData}
          registrationId={registrationId}
          showContinueBtn
          stepNum={stepNum}
          submitHandler={submitHandler}
        />
      </InfoBackground>
    </div>
  );
}
Instructions.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
