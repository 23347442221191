import {
  gql, MutationFunction, MutationOptions, useQuery,
} from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import style from './passInfo.module.css';
import priceStyle from '../price/price.module.css';
import CheckBoxArea from '../../../components/checkBoxArea/checkBoxArea';

const GET_PASS_INFO = gql`
query GetPassInfo(
	$passInfoId: String!
) {
  getPassInfo (
	  passInfoId: $passInfoId
	) {
    success
    error
    data {
      name
      description
      passesPerRental
      portal
      pdkDeferCardCreation
      pdkDeferPersonCreation
      needsApplication
      passApprovalRequire
      pdkGroupIds
    }
  }
}
`;

export type passInfoFormData = {
  name?: string,
  description?: string,
  passesPerRental?: number,
  pdkDeferCardCreation?: boolean,
  pdkDeferPersonCreation?: boolean,
  needsApplication?: boolean,
  passApprovalRequire?: boolean,
  pdkGroupIds?: string[],
  [key: string]: undefined | string | string[] | number | boolean,
}

export type PDKGroup = {
  id: string,
  name: string,
}

type Props = {
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  changeData?: passInfoFormData,
  editChangeData?: (
    key: keyof passInfoFormData,
    val: passInfoFormData[keyof passInfoFormData]
  ) => void,
  submitHandler?: () => void,
  stepNum?: number,
  // eslint-disable-next-line react/require-default-props
  pdkGroups?: PDKGroup[],
}

export default function PassInfoForm(props: Props): ReactElement {
  const {
    registrationId,
    editBtnLink,
    showContinueBtn,
    changeData,
    editChangeData,
    submitHandler,
    stepNum,
    pdkGroups,
  } = props;
  let form;

  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  const { data, loading, error } = useQuery<{
    getPassInfo: {
      data: passInfoFormData, error: string, success: boolean,
    }
  }>(GET_PASS_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
  });
  useEffect(() => {
    // Check if data is defined and contains getPassInfo
    if (data && data.getPassInfo) {
      const passInfoData = data.getPassInfo.data;
      if (editChangeData && typeof editChangeData === 'function') {
        editChangeData('pdkDeferCardCreation', passInfoData?.pdkDeferCardCreation);
        editChangeData('pdkDeferPersonCreation', passInfoData?.pdkDeferPersonCreation);
        editChangeData('needsApplication', passInfoData?.needsApplication);
        editChangeData('passApprovalRequire', passInfoData?.passApprovalRequire);
        editChangeData('pdkGroupIds', passInfoData?.pdkGroupIds || []);
      }
    }
  }, [data]);
  return (
    <form
      className={form}
      onSubmit={() => {
        if (submitHandler) {
          submitHandler();
        }
      }}
    >
      {loading && <LoadingDiamonds />}
      <GenericAlert
        title={error?.message || data?.getPassInfo?.error}
        color="red"
        hidden={!(error || data?.getPassInfo?.error)}
      />
      {
        editBtnLink
          ? (
            <div className={style.editBtn}>
              <Link to={editBtnLink} className={style.link}>
                <GenericButton
                  title="Edit"
                />
              </Link>
            </div>
          ) : null
      }
      <div className={style.passInfoBox}>

        <div className={style.passInfoTitleBox}>
          <h4 className={style.passInfoTitle}>
            Step
            {' '}
            {stepNum || 1}
            : Pass Information
          </h4>

        </div>
        <div className={style.nameInput}>
          <h4 className={priceStyle.mainTitle}>Pass Name</h4>
          <InputField
            htmlFor="pass-name-inpt"
            inputPlaceholder={data?.getPassInfo?.data?.name || ''}
            inputValue={changeData?.name || ''}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => (
              editChangeData && editChangeData('name', e.target.value)
            )}
          />

        </div>
        <label
          htmlFor="pass-description-inpt"
          className={style.passDescriptionLabel}
        >
          <h4 className={priceStyle.mainTitle}>Pass Description</h4>
          <textarea
            id="pass-description-inpt"
            className={style.passDescriptionTextArea}
            placeholder={data?.getPassInfo?.data?.description || ''}
            value={changeData?.description || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => (
              editChangeData && editChangeData('description', e.target.value)
            )}
          />
        </label>
        <div className={priceStyle.chargePer}>
          <h4 className={priceStyle.mainTitle}>
            Defer PDK Person Creation: Delays creation of PDK person
            until credential number is added to the pass
          </h4>
          <div className={priceStyle.btnLeftRightAddonBox}>
            <div className={changeData?.pdkDeferPersonCreation ? priceStyle.btnBlue
              : priceStyle.btnWhite}
            >
              <GenericButton
                title="Yes"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('pdkDeferPersonCreation', true);
                  }
                }}
              />
            </div>
            <div className={changeData?.pdkDeferPersonCreation ? priceStyle.btnWhite
              : priceStyle.btnBlue}
            >
              <GenericButton
                title="No"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('pdkDeferPersonCreation', false);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={priceStyle.chargePer}>
          <h4 className={priceStyle.mainTitle}>
            Defer PDK Card Creation: Delays creation of PDK card
            until credential number is added to the pass
          </h4>
          <div className={priceStyle.btnLeftRightAddonBox}>
            <div className={changeData?.pdkDeferCardCreation ? priceStyle.btnBlue
              : priceStyle.btnWhite}
            >
              <GenericButton
                title="Yes"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('pdkDeferCardCreation', true);
                  }
                }}
              />
            </div>
            <div className={changeData?.pdkDeferCardCreation ? priceStyle.btnWhite
              : priceStyle.btnBlue}
            >
              <GenericButton
                title="No"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('pdkDeferCardCreation', false);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={priceStyle.chargePer}>
          <h4 className={priceStyle.mainTitle}>
            Show in Workstation (for annual pass requiring admin approval)
          </h4>
          <div className={priceStyle.btnLeftRightAddonBox}>
            <div className={changeData?.needsApplication ? priceStyle.btnBlue
              : priceStyle.btnWhite}
            >
              <GenericButton
                title="Yes"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('needsApplication', true);
                  }
                }}
              />
            </div>
            <div className={changeData?.needsApplication ? priceStyle.btnWhite
              : priceStyle.btnBlue}
            >
              <GenericButton
                title="No"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('needsApplication', false);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={priceStyle.chargePer}>
          <h4 className={priceStyle.mainTitle}>
            Require activation before sending QR code (ex: Isleworth Vendor Annual badges)
          </h4>
          <div className={priceStyle.btnLeftRightAddonBox}>
            <div className={changeData?.passApprovalRequire ? priceStyle.btnBlue
              : priceStyle.btnWhite}
            >
              <GenericButton
                title="Yes"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('passApprovalRequire', true);
                  }
                }}
              />
            </div>
            <div className={changeData?.passApprovalRequire ? priceStyle.btnWhite
              : priceStyle.btnBlue}
            >
              <GenericButton
                title="No"
                clickHandler={() => {
                  if (editChangeData && typeof editChangeData === 'function') {
                    editChangeData('passApprovalRequire', false);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={priceStyle.chargePer}>
          <h4 className={priceStyle.mainTitle}>
            PDK Groups
          </h4>
          {pdkGroups?.map((group) => (
            <CheckBoxArea
              checkDisabled={false}
              title={group.name}
              value={changeData?.pdkGroupIds?.includes(group.id)}
              clickHandler={(checked) => {
                const pdkGroupIds = changeData?.pdkGroupIds || [];
                const index = pdkGroupIds.indexOf(group.id);
                if (checked) {
                  if (index < 0) {
                    pdkGroupIds.push(group.id);
                  }
                } else if (index > -1) {
                  pdkGroupIds.splice(index, 1);
                }
                if (editChangeData && typeof editChangeData === 'function') {
                  editChangeData('pdkGroupIds', pdkGroupIds);
                }
              }}
            />
          ))}
        </div>
        {/* <div className={style.nunmberInput}>
          <InputField
            labelTitle="Number of Passes available per Rental"
            htmlFor="ppr-num-inpt"
            inputType="number"
            inputPlaceholder={data?.getPassInfo?.data?.passesPerRental?.toString() || ''}
            inputValue={changeData?.passesPerRental?.toString() || ''}
            changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => (
              editChangeData && editChangeData('passesPerRental', e.target.value)
            )}
          />

        </div> */}

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />
          </div>
        </div>
      ) : null}

    </form>
  );
}
PassInfoForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  changeData: {},
  editChangeData: undefined,
  submitHandler: undefined,
  stepNum: undefined,
};
