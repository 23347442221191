import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragAndDropItem } from '../../../components/dragAndDrop/dragAndDrop';
import style from './employeeInfo.module.css';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import EmployeeInfoForm from './employeeInfoForm';

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function EmployeeInfo(props: Props) :ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <EmployeeInfoForm
          registrationId={registrationId}
          showContinueBtn
          stepNum={stepNum}
        />
      </InfoBackground>

    </div>
  );
}
EmployeeInfo.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
