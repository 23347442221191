import React, { ReactChild, ReactElement } from 'react';
import lbgStyle from './forgetPWPagesBackground.module.css';
import logo from '../../../assets/symlivWhite.png';

interface Props {
  children?: ReactChild | Array<ReactChild>;
}

export default function ForgetPWPagesBackground(props:Props) :ReactElement {
  const { children } = props;

  return (

    <div className={lbgStyle.container}>
      <div className={`${lbgStyle.box} white `}>
        <div className={`${lbgStyle.box__left}  linerGradient `}><img src={logo} alt="" className={lbgStyle.box__img} /></div>
        <div className={lbgStyle.box__right}>
          {children}
        </div>
      </div>
    </div>

  );
}
ForgetPWPagesBackground.defaultProps = {
  children: (<></>),
};
