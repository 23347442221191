import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useEffect, useState } from 'react';

const FETCH = gql`
query GetCommunityById(
  $communityId: String!
) {
  getCommunityById(
    communityId: $communityId
  ) {
    success
    error
    data {
      flatFeeAmount
      feePercentage
    }
  }
}
`;

const SUBMIT = gql`
mutation EditCommunity(
  $communityInfo: CommunityInput!,
  $communityId: String!
) {
  editCommunity(
    communityInfo: $communityInfo,
    communityId: $communityId
  ) {
    success
    error
  }
}
`;

export default function usePaymentConfig() :{
  community: string,
  setCommunity: (val: string) => void,
  allowFee: boolean,
  setAllowFee: (val: boolean) => void,
  flatFeeAmount: number,
  setFlatFeeAmount: (val: number) => void,
  feePercentage: number,
  setFeePercentage: (val: number) => void,
  submitHandler: () => Promise<unknown>,
  alert: string,
  } {
  const [community, setCommunity] = useState<string>('');
  const [allowFee, setAllowFee] = useState<boolean>(false);
  const [flatFeeAmount, setFlatFeeAmount] = useState<number>(0);
  const [feePercentage, setFeePercentage] = useState<number>(0);
  const [alert, setAlert] = useState<string>('');

  useEffect(() => {
    if (alert) {
      setAlert('');
    }
  }, [community]);

  useEffect(() => {
    if (!allowFee) {
      setFeePercentage(0);
      setFlatFeeAmount(0);
    }
  }, [allowFee]);

  const [fetch] = useLazyQuery<{
    getCommunityById: {
      success: boolean,
      error: string,
      data?: Array<{
        flatFeeAmount: number,
        feePercentage: number,
      } | null>,
    }
  }>(FETCH, {
    fetchPolicy: 'network-only',
    variables: {
      communityId: community,
    },
    onCompleted: (d) => {
      if (d.getCommunityById.data?.length && d.getCommunityById.data[0]) {
        if (
          !d.getCommunityById.data[0].flatFeeAmount
          && !d.getCommunityById.data[0].feePercentage
        ) {
          setAllowFee(false);
        } else {
          setAllowFee(true);
          setFlatFeeAmount(d.getCommunityById.data[0].flatFeeAmount || 0);
          setFeePercentage(d.getCommunityById.data[0].feePercentage || 0);
        }
      }
    },
  });
  useEffect(() => {
    if (community) {
      fetch();
    }
  }, [community]);

  const [doSubmit] = useMutation(SUBMIT);
  const submitHandler = () => doSubmit({
    variables: {
      communityId: community,
      communityInfo: {
        flatFeeAmount,
        feePercentage,
      },
    },
  })
    .then((d) => {
      if (d.data.editCommunity.error) {
        throw Error(d.data.editCommunity.error);
      } else if (alert) {
        setAlert('');
      }
      fetch();
    })
    .catch((e) => setAlert(e.message));

  return {
    community,
    setCommunity,
    allowFee,
    setAllowFee,
    flatFeeAmount,
    setFlatFeeAmount,
    feePercentage,
    setFeePercentage,
    submitHandler,
    alert,
  };
}
