import { gql, useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './documents.module.css';
import DocumentsForm from './documentsForm';

const ADD_DOCUMENT = gql`
mutation AddDocument(
  $required: String!, 
  $name: String!, 
  $passInfoId: String!
) {
  addDocument(
    required: $required, 
    name: $name, 
    passInfoId: $passInfoId
  ) {
    error
    success
  }
} 
`;

const EDIT_DOCUMENT_STATUS = gql`
mutation EditDocument(
  $required: String!,
  $documentInfoId: String!
) {
  editDocumentStatus(
    required: $required,
    documentInfoId: $documentInfoId
  ) {
    success
    error
  }
} 
`;

const DELETE_DOCUMENT = gql`
mutation DeleteDocument(
  $documentInfoId: String!
) {
  deleteDocument(
    documentInfoId: $documentInfoId
  ) {
    success
    error
  }
} 
`;

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function Documents(props: Props) :ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const { registrationId } = useParams<{ registrationId: string }>();

  const [doAddDocument] = useMutation(ADD_DOCUMENT);
  const [doEditDocument] = useMutation(EDIT_DOCUMENT_STATUS);
  const [doDeleteDocument] = useMutation(DELETE_DOCUMENT);

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <DocumentsForm
          registrationId={registrationId}
          deleteDocument={(documentInfoId: string) => doDeleteDocument({
            variables: { documentInfoId },
          })}
          addDocument={(name: string) => doAddDocument({
            variables: {
              passInfoId: registrationId,
              name,
              required: 'optional',
            },
          })}
          submitHandler={(data) => Promise.all(data.map((item) => doEditDocument({
            variables: {
              documentInfoId: item.name,
              required: item.value,
            },
          })))}
          showContinueBtn
          stepNum={stepNum}
        />
      </InfoBackground>
    </div>
  );
}
Documents.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
