import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './price.module.css';
import PriceInfoForm from './priceForm';

const SET_PRICE_INFO = gql`
mutation Mutation (
  $passInfo: PriceInfoInput!,
  $passInfoId: String!
) {
  setPriceInfo (
    passInfo: $passInfo,
    passInfoId: $passInfoId
  ) {
    success
    error
  }
} 
`;

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function PriceInfo(props: Props) :ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const { registrationId } = useParams<{ registrationId: string }>();

  const [doSetPrice] = useMutation(SET_PRICE_INFO);

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <PriceInfoForm
          registrationId={registrationId}
          showContinueBtn
          stepNum={stepNum}
          submitHandler={(data) => doSetPrice({
            variables: {
              passInfoId: registrationId,
              passInfo: data,
            },
          })}
        />
      </InfoBackground>
    </div>
  );
}
PriceInfo.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
