import React, { ReactElement, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserProfileButton from '../userProfileButton/userProfileButton';
import style from './header.module.css';
// import languageIcon from '../../assets/language.svg';
import store, { isLogedIn as reduxIsLogedIn } from '../../common_lib_front/utilities/store';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import { ReactComponent as HamburgerImg } from '../../assets/hamburger.svg';
import { ReactComponent as HamburgerXImg } from '../../assets/hamburgerClose.svg';
import logoImg from '../../assets/symLiv.svg';

const HeaderLink = (props: {href: string, title: string, active?: boolean}) => {
  const { href, title, active } = props;
  let clickedStyle;
  let boxStyle;
  let activeText;
  if (active) {
    // TODO: fill in stle for active tab here
    clickedStyle = style.clicked;
    boxStyle = 'mainColor';
    activeText = style.activeText;
  } else {
    // TODO: set style for default tab here
  }
  return (
    <div className={`${style.navItemBox} ${clickedStyle}  `}>
      <Link to={href} className={`${style.navItem} ${activeText} `}>
        {title}
      </Link>
      {/* <div className={`${boxStyle} ${style.navLine}`} /> */}
    </div>
  );
};
HeaderLink.defaultProps = {
  active: false,
};

export const Header = () :ReactElement => {
  const [isLogedin, setIsLogedin] = useState<boolean>(reduxIsLogedIn());
  store.subscribe(() => setIsLogedin(reduxIsLogedIn()));
  const { pathname } = useLocation();
  const allowLinks = !/(\/$|\/login|\/create-account|\/forgot-password)/.test(pathname);
  const [showLinks, setShowLinks] = useState<boolean>(false);
  const smallScreen = useMediaQuery('(max-width: 767px)');

  let headerStyle;
  let invisiableBox;
  let profileBtn;
  let logoBox;
  let logoLayout;
  let profileBox;
  let helpBtn;

  if (isLogedin) { // any time the user is loged in
    // TODO: set styles for when the UserProfileButton should be displayed
    profileBtn = style.profileBtnApper;
    helpBtn = style.helpBtnApper;
  } else {
    // TODO: set styles for when the UserProfileButton is not displayed
    profileBtn = style.profileBtnDisapper;
    helpBtn = style.helpBtnDisapper;
  }

  if (showLinks) {
    // TODO: set styles for when navigation links are displayed
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
  } else {
    // TODO: set styles for when navigation links are not shown
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
  }

  if (allowLinks) {
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
    invisiableBox = style.invisibleBoxDisApper;
  } else {
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
  }

  return (
    <div className={`${headerStyle}`}>
      {smallScreen && allowLinks
        ? (
          <div className={style.hamburgerBtn}>
            <GenericButton
              title=""
              outline="none"
              color="none"
              icon={(className: string) => (showLinks
                ? <HamburgerXImg className={`${style.hamburgerCloseImg}`} />
                : <HamburgerImg className={`${style.hamburgerImg}`} />
              )}
              clickHandler={() => setShowLinks(!showLinks)}
            />
          </div>
        )
        : null}
      <div className={invisiableBox} />
      <Link to="/" className={logoBox}>
        {smallScreen
          ? <p className={style.companyName}>symLiv</p>
          : <img src={logoImg} alt="logo" className={logoLayout} /> }
      </Link>

      {allowLinks && (smallScreen ? showLinks : true)
        ? (
          <nav className={style.nav}>
            <HeaderLink
              href="/pass-builder"
              title="Pass Builder & Pass List"
              active={/\/pass-builder/.test(pathname)}
            />
            <HeaderLink
              href="/upload-education"
              title="Upload Education"
              active={/\/upload-education/.test(pathname)}
            />
            <HeaderLink
              href="/pos-config"
              title="POS Configuration"
              active={/\/pos-config/.test(pathname)}
            />
            <HeaderLink
              href="/payment-config"
              title="Payment Configuration"
              active={/\/payment-config/.test(pathname)}
            />
            <HeaderLink
              href="/add-community"
              title="Add Community"
              active={/\/add-community/.test(pathname)}
            />
          </nav>
        )
        : null}
      <div className={profileBox}>

        {/* <button className={`${style.language} textColorWhite
        thirdColor `}
        >
          <span className={style.languageIcon}>
            <LanguageIcon />
          </span>
          espa&#241;ol
        </button> */}

        <div className={profileBtn}>
          {isLogedin
            ? <UserProfileButton />
            : null}
          {/* <Link to="/guest/help">
            <p className={`${helpBtn} textColorMain`}>Help</p>
          </Link> */}

        </div>

      </div>
    </div>
  );
};

export default Header;
