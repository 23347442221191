import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryString } from '../../../common_lib_front/utilities/QueryString';
import { PopUp } from '../../../components/popUp/popUp';
import { checkBoxes } from '../../passBuilder/display/display';
import qrImg from '../../../assets/qrCode.svg';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import style from './passPreview.module.css';

const ConditionalField = (props: {
  show: boolean,
  body: ReactElement,
}) :ReactElement | null => {
  const { show, body } = props;
  if (show || process.env.REACT_APP_DEBUG === 'true') {
    return body;
  }
  return null;
};

export default function PassPreview(props: {
  displayParams?: checkBoxes;
  close?: () => void;
  isOpen: boolean;
}) :ReactElement {
  const history = useHistory();
  const { displayParams: __displayParams, close: __close, isOpen } = props;
  const displayParams :checkBoxes = __displayParams || QueryString.parse(history.location.search);
  const close = __close === undefined ? history.goBack : __close;
  return (
    <PopUp
      title="Pass Preview"
      close={close}
      isOpen={isOpen}
    >
      <div className={style.pass}>
        <div className={style.logo}>Logo</div>
        <h1 className={style.passName}>Pass Name</h1>
        <ConditionalField
          show={displayParams.QRCode}
          body={(<img src={qrImg} alt="QR code" />)}
        />
        <div className={style.timeBox}>
          <div className={style.validFromBox}>
            <h5 className={style.validFrom}>Valid From:</h5>
            <h5 className={`${style.validFromItems} `}>{formatDate()}</h5>
          </div>
          <div className={style.validToBox}>
            <h5 className={style.validFrom}>Valid To:</h5>
            <h5 className={`${style.validFromItems} `}>{formatDate()}</h5>
          </div>

        </div>
        <div className={style.infoBox}>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.firstName || displayParams.lastName}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Name:</p>
                  <p className={style.infoItem}>
                    {displayParams.firstName ? 'Neil' : ''}
                    {displayParams.lastName ? 'Armstrong' : ''}

                  </p>
                </div>
          )}
            />
            <ConditionalField
              show={displayParams.phoneNumber}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Cell Phone Number:</p>
                  <p className={style.infoItem}>(000)000-0000</p>
                </div>
          )}
            />

          </div>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.email}
              body={(
                <div className={style.infoLong}>
                  <p className={style.infoTitle}>Email Address:</p>
                  <p className={style.infoItem}>NeilArmstrong@gmail.com</p>
                </div>
          )}
            />

          </div>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.rentalAddress}
              body={(
                <div className={style.infoLong}>
                  <p className={style.infoTitle}>Rental Address:</p>
                  <p className={style.infoItem}>1234 N, North Logan(...)</p>
                </div>
          )}
            />

          </div>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.arrivalDate}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Arrival Date:</p>
                  <p className={style.infoItem}>{formatDate()}</p>
                </div>
          )}
            />

            <ConditionalField
              show={displayParams.departureDate}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Departure Date:</p>
                  <p className={style.infoItem}>{formatDate()}</p>
                </div>
          )}
            />

          </div>

          <ConditionalField
            show={displayParams.homeAddress}
            body={(
              <div className={style.rowBox}>
                <div className={style.Row}>
                  <div className={style.infoLong}>
                    <p className={style.infoTitle}>Home Address:</p>
                    <p className={style.infoItem}>1234 N, North Logan</p>
                  </div>

                </div>
                <div className={style.Row}>
                  <ConditionalField
                    show={displayParams.homeAddressCountry}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>Country:</p>
                        <p className={style.infoItem}>USA</p>
                      </div>
          )}
                  />
                  <ConditionalField
                    show={displayParams.homeAddressCity}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>City:</p>
                        <p className={style.infoItem}>Logan</p>
                      </div>
          )}
                  />

                </div>
                <div className={style.Row}>
                  <ConditionalField
                    show={displayParams.homeAddressState}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>State:</p>
                        <p className={style.infoItem}>Utah</p>
                      </div>
          )}
                  />
                  <ConditionalField
                    show={displayParams.homeAddressZip}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>Zip Code:</p>
                        <p className={style.infoItem}>00000</p>
                      </div>
          )}
                  />

                </div>
              </div>
          )}
          />
          <ConditionalField
            show={displayParams.businessAddress}
            body={(
              <div>
                <div className={style.Row}>
                  <div className={style.infoLong}>
                    <p className={style.infoTitle}>Business Address:</p>
                    <p className={style.infoItem}>1234 N, North Logan</p>
                  </div>

                </div>
                <div className={style.Row}>
                  <ConditionalField
                    show={displayParams.businessAddressCountry}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>Country:</p>
                        <p className={style.infoItem}>USA</p>
                      </div>
          )}
                  />
                  <ConditionalField
                    show={displayParams.businessAddressCity}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>City:</p>
                        <p className={style.infoItem}>Logan</p>
                      </div>
          )}
                  />

                </div>
                <div className={style.Row}>
                  <ConditionalField
                    show={displayParams.businessAddressState}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>State:</p>
                        <p className={style.infoItem}>Utah</p>
                      </div>
          )}
                  />
                  <ConditionalField
                    show={displayParams.businessAddressZip}
                    body={(
                      <div className={style.info}>
                        <p className={style.infoTitle}>Zip Code:</p>
                        <p className={style.infoItem}>00000</p>
                      </div>
          )}
                  />

                </div>
              </div>
          )}
          />
          {' '}

        </div>
        <div className={style.infoBox}>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.vehicleMake}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Vehicle Make:</p>
                  <p className={style.infoItem}>Toyota</p>
                </div>
          )}
            />
            <ConditionalField
              show={displayParams.vehicleModel}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Vehicle Model:</p>
                  <p className={style.infoItem}>Camry</p>
                </div>
          )}
            />

          </div>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.vehicleType}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Vehicle Type:</p>
                  <p className={style.infoItem}>Sedan</p>
                </div>
          )}
            />
            <ConditionalField
              show={displayParams.vehicleColor}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Vehicle Color:</p>
                  <p className={style.infoItem}>Black</p>
                </div>
          )}
            />

          </div>
          <div className={style.Row}>
            <ConditionalField
              show={displayParams.vehiclePlate}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>License Plate:</p>
                  <p className={style.infoItem}>ABC 123</p>
                </div>
          )}
            />
            <ConditionalField
              show={displayParams.vehicleFleetNumber}
              body={(
                <div className={style.info}>
                  <p className={style.infoTitle}>Vehicle Fleet Number:</p>
                  <p className={style.infoItem}>000123</p>
                </div>
          )}
            />

          </div>

        </div>

      </div>
    </PopUp>
  );
}
PassPreview.defaultProps = {
  displayParams: undefined,
  close: undefined,
};
