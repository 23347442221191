import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';

const CREATE_NEW_PASS = gql`
mutation Mutation(
  $portal: String!, 
  $communityId: String!
) {
  createPassInfo(
    portal: $portal,
    communityId: $communityId
  ) {
    success
    error
    data {
      passInfoId
    }
  }
}
`;

export default function useInitialization() :{
  createPass: () => void,
  newPassId?: string,
  newPassError?: string,
  communityName: string,
  setCommunityName: (val: string) => void,
  portalName: string,
  setPortalName: (val: string) => void,
  } {
  const [communityName, setCommunityName] = useState<string>('');
  const [portalName, setPortalName] = useState<string>('');

  const [doCreatePass, {
    data: newPassData, error: newPassError,
  }] = useMutation(CREATE_NEW_PASS);
  const createPass = () => {
    doCreatePass({
      variables: {
        communityId: communityName,
        portal: portalName,
      },
    });
  };

  return {
    createPass,
    newPassId: newPassData?.createPassInfo?.data?.passInfoId,
    newPassError: newPassError?.message || newPassData?.createPassInfo?.error || undefined,
    communityName,
    setCommunityName,
    portalName,
    setPortalName,
  };
}
