import { gql } from '@apollo/client';

export type groupType = {
  communityId: string,
  passInfoList: string[],
  passGroupId: string
  name: string
}
export type passInfoType = {
  passInfoId: string,
  complete: boolean,
  name: string,
}

export const GET_PASS_GROUP = gql`
    query getPassGroups (
        $communityId: String!
    ) {
        getPassGroups(
            communityId: $communityId
          ){
            success
            error
            data {
                communityId
                passInfoList
                passGroupId
                name
            }
          }
    }
`;
export type GET_PASS_GROUP_REQUEST = {
    communityId: string,
}
export type GET_PASS_GROUP_RESPONSE = {
  getPassGroups: {
    success: boolean,
    error: string,
    data: null | Array<groupType>
  }
}

export const GET_PASS_INFO = gql`
    query getPassInfo (
        $passInfoId: String!
    ) {
        getPassInfo(
            passInfoId: $passInfoId
          ){
            success
            error
            data {
                passInfoId
                complete
                name
            }
          }
    }
`;
export type GET_PASS_INFO_REQUEST = {
    passInfoId: string,
}
export type GET_PASS_INFO_RESPONSE = {
    success: boolean,
    error: string,
    data: null | passInfoType
}

export const DELETE_PASS = gql`
    mutation deletePassInfo (
        $passInfoId: String!
    ) {
        deletePassInfo(
            passInfoId: $passInfoId
          ){
            success
            error
          }
    }
`;
export type DELETE_PASS_REQUEST = {
    passInfoId: string,
}
export type DELETE_PASS_RESPONSE = {
  deletePassInfo: {
    success: boolean,
    error: string,
  }
}

export const SET_PASS_STATUS = gql`
    query getPassGroups (
        $communityId: String!
    ) {
        getPassGroups(
            communityId: $communityId
          ){
            success
            error
            data {
                communityId
            }
          }
    }
`;
export type SET_PASS_STATUS_REQUEST = {
  communityId: string,
}
export type SET_PASS_STATUS_RESPONSE = {
  getPassGroups: {
    success: boolean,
    error: string,
  }
}

export const DELETE_GROUP = gql`
    mutation deletePassGroup (
        $passGroupId: String!
    ) {
        deletePassGroup(
            passGroupId: $passGroupId
          ){
            success
            error
          }
    }
`;
export type DELETE_GROUP_REQUEST = {
  passGroupId: string,
}
export type DELETE_GROUP_RESPONSE = {
  deletePassGroup: {
    success: boolean,
    error: string,
  }
}

export const ADD_PASSES_TO_GROUP = gql`
    mutation addPassesToGroup (
        $passGroupId: String!
        $passInfoIds: [String!]!
    ) {
        addPassesToGroup(
            passGroupId: $passGroupId
            passInfoIds: $passInfoIds
          ){
            success
            error
          }
    }
`;
export type ADD_PASSES_TO_GROUP_REQUEST = {
  passGroupId: string,
  passInfoIds: Array<string>,
}
export type ADD_PASSES_TO_GROUP_RESPONSE = {
  deletePassGroup: {
    success: boolean,
    error: string,
  }
}

export const REMOVE_PASS_FROM_GROUP = gql`
    mutation removePassFromGroup (
        $passGroupId: String!
        $passInfoId: String!
    ) {
        removePassFromGroup(
            passGroupId: $passGroupId
            passInfoId: $passInfoId
        ){
            success
            error
        }
    }
`;
export type REMOVE_PASS_FROM_GROUP_REQUEST = {
  passGroupId: string,
  passInfoId: string,
}
export type REMOVE_PASS_FROM_GROUP_RESPONSE = {
  deletePassGroup: {
    success: boolean,
    error: string,
  }
}

// export const REMOVE_PASS = gql`
//     mutation deletePassInfo (
//         $passInfoId: String!
//     ) {
//         deletePassInfo(
//             passInfoId: $passInfoId
//         ){
//             success
//             error
//         }
//     }
// `;

export const CREATE_GROUP = gql`
    mutation createPassGroup (
        $name: String!
        $communityId: String!
    ) {
        createPassGroup(
            name: $name
            communityId: $communityId
        ){
            success
            error
        }
    }
`;
export type CREATE_GROUP_REQUEST = {
  name: string,
  communityId: string,
}
export type CREATE_GROUP_RESPONSE = {
  success: boolean,
  error: string,
}

export type passType = {
  passInfoId: string,
  communityId: string,
  portal: string,
  complete: boolean,
  name: string,
  description: string,
  educationRequired: string,
}
export const GET_ALL_PASS = gql`
    query getPassInfosByCommunity (
        $communityId: String!
        $complete: Boolean!
    ) {
        getPassInfosByCommunity(
            communityId: $communityId
            complete: $complete
        ){
            success
            error
            data {
                passInfoId
                communityId
                portal
                complete
                name
                description
                educationRequired
            }
        }
    }
`;
export type GET_ALL_PASS_REQUEST = {
  communityId: string,
  complete: boolean,
}
export type GET_ALL_PASS_RESPONSE = {
  getPassInfosByCommunity: {
    success: boolean,
    error: string,
    data: null | Array<passType>,
  }
}
