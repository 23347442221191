import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import style from './education.module.css';
import useEducationForm from './useEducationForm';

type Props = {
    registrationId: string,
    editBtnLink?: string,
    showContinueBtn?: boolean,
    stepNum?: number,
    communityId: string,
}

export default function EducationForm(props: Props) :ReactElement {
  const {
    registrationId, editBtnLink, showContinueBtn, stepNum, communityId,
  } = props;
  const {
    educations,
    passData,
    editPassData,
    submitHandler,
  } = useEducationForm(communityId);
  const history = useHistory();

  let form;

  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  return (
    <form
      className={form}
      onSubmit={(e:React.FormEvent) => {
        e.preventDefault();
        submitHandler()
          .then(() => {
            history.push(`/pass-builder/${registrationId}/document-info`);
          });
      }}
    >
      {
            editBtnLink
              ? (
                <div className={style.editBtn}>
                  <Link to={editBtnLink} className={style.link}>
                    <GenericButton
                      title="Edit"
                    />
                  </Link>

                </div>
              ) : null
          }
      <div className={style.educationBox}>
        <div className={style.educationTitleBox}>
          <h1 className={style.educationTitle}>
            Step
            {' '}
            {stepNum || 4}
            : Education
          </h1>

        </div>
        <div className={style.optionBox}>
          <h4 className={style.optionTitle}>
            Select if education is required for this pass
          </h4>
          <div className={style.optionBtnsBox}>
            <div className={` ${passData.isEducationRequired === 'required' ? style.btnBlue : style.btnWhite}`}>
              <GenericButton
                title="Required"
                type="button"
                clickHandler={() => {
                  editPassData('isEducationRequired', 'required');
                }}
                color="none"
              />

            </div>
            <div className={` ${passData.isEducationRequired === 'optional'
              ? style.btnBlue : style.btnWhite}`}
            >
              <GenericButton
                title="Optional"
                type="button"
                clickHandler={() => {
                  editPassData('isEducationRequired', 'optional');
                }}
                color="none"
              />
            </div>
            <div className={` ${passData.isEducationRequired === 'disabled' ? style.btnBlue : style.btnWhite}`}>
              <GenericButton
                title="Disabled"
                type="button"
                clickHandler={() => {
                  editPassData('isEducationRequired', 'disabled');
                }}
                color="none"
              />

            </div>

          </div>
        </div>
        {
          passData.isEducationRequired !== 'disabled'
            ? (
              <div>
                <h4 className={style.optionTitle}>
                  Select which education is required for this pass

                </h4>
                <label htmlFor="edu-english-select" className={style.selectLabel}>
                  <div className={style.languageBox}>
                    <input
                      type="checkbox"
                      className={style.checkbox}
                      checked={passData.englishEducationEnabled}
                      onChange={(e) => {
                        editPassData('englishEducationEnabled', e.target.checked);
                      }}
                    />
                    English

                  </div>
                  <select
                    id="edu-english-select"
                    className={style.select}
                    value={passData.englishEducation || ''}
                    onChange={(e:React.ChangeEvent<HTMLSelectElement>) => {
                      editPassData('englishEducation', e.target.value);
                    }}
                  >
                    <option
                      value=""
                    >
                      None
                    </option>
                    {educations.map((edu) => (
                      <option
                        key={edu.educationId}
                        value={edu.educationId}
                      >
                        {edu.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label htmlFor="edu-spanish-select" className={style.selectLabel}>
                  <div className={style.languageBox}>
                    <input
                      type="checkbox"
                      className={style.checkbox}
                      checked={passData.spanishEducationEnabled}
                      onChange={(e) => {
                        editPassData('spanishEducationEnabled', e.target.checked);
                      }}
                    />
                    Spanish
                  </div>
                  <select
                    id="edu-spanish-select"
                    className={style.select}
                    value={passData.spanishEducation || ''}
                    onChange={(e:React.ChangeEvent<HTMLSelectElement>) => {
                      editPassData('spanishEducation', e.target.value);
                    }}
                  >
                    <option
                      value=""
                    >
                      None
                    </option>
                    {educations.map((edu) => (
                      <option
                        key={edu.educationId}
                        value={edu.educationId}
                      >
                        {edu.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            ) : null
        }

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />

          </div>
        </div>
      ) : null}
    </form>
  );
}
EducationForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  stepNum: undefined,
};
