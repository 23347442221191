import React, { ReactChild, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './infoBackground.module.css';
import positionArrowImg from '../../assets/positionArrow.svg';

interface Props {
    children?: ReactChild | Array<ReactChild>;
    title?:string;
    showBackBtn?:boolean;
    showPosition?:boolean;
    showReviewPass?:boolean;
    hideLine?:boolean;
    communityName?:string;
    portalName?:string;
  }

export default function InfoBackground(props:Props) :ReactElement {
  const {
    children, title, showBackBtn, showPosition, showReviewPass, hideLine, communityName, portalName,
  } = props;
  const history = useHistory();

  let boxTitle;
  let box;
  if (hideLine) {
    box = style.boxWithoutLine;
    boxTitle = style.boxTitleWithoutLine;
  }
  if (!hideLine) {
    box = style.box;
    boxTitle = style.boxTitle;
  }

  return (

    <div className={box}>

      <div className={boxTitle}>
        {
        showBackBtn
          ? (
            <button
              onClick={() => history.goBack()}
              className={style.backBtn}
            >
              Back
            </button>
          ) : null

      }
        {title}
        {
        showPosition
          ? (
            <div className={style.positonArea}>
              <span className={style.communityNameBox}>{communityName}</span>
              <img src={positionArrowImg} alt="positionArrow" className={style.positionArrow} />
              <span className={style.portalNameBox}>{portalName}</span>

            </div>
          ) : null

      }
      </div>
      {showReviewPass ? (
        <h4 className={style.reviewPassTitle}>Review Current Pass</h4>) : null}
      {children}
    </div>

  );
}
InfoBackground.defaultProps = {
  children: (<></>),
  title: '',
  showBackBtn: false,
  showPosition: false,
  showReviewPass: false,
  hideLine: false,
  communityName: '',
  portalName: '',
};
