import { checkBoxes } from './display';
import { displayTypeInput } from './displayTypes';

export const objToGql = (obj: checkBoxes): displayTypeInput => {
  const gqlObj: displayTypeInput = {
    arrivalDate: true,
    departureDate: true,
    firstName: false,
    lastName: false,
    cellPhoneNumber: false,
    email: false,
    emergencyContactNumber: false,
    businessName: false,
    businessAddress: {
      country: false,
      city: false,
      state: false,
      zipCode: false,
    },
    businessPhoneNumber: false,
    make: false,
    vehicleModel: false,
    type: false,
    color: false,
    licensePlate: false,
    fleetNumber: false,
    licensePlateState: false,
    primaryDriverName: false,
    licensePrimaryDriver: false,
    qrCode: false,
    homeAddress: {
      country: false,
      city: false,
      state: false,
      zipCode: false,
    },
    rentalAddress: false,
  };
  for (const key in obj) { // eslint-disable-line
    switch (key) {
      case 'country':
        break;
      case 'city':
        break;
      case 'state':
        break;
      case 'zipCode':
        break;
      case 'businessAddress':
        gqlObj.businessAddress.country = obj.country;
        gqlObj.businessAddress.state = obj.state;
        gqlObj.businessAddress.city = obj.city;
        gqlObj.businessAddress.zipCode = obj.zipCode;
        break;
      case 'homeAddressCountry':
        break;
      case 'homeAddressCity':
        break;
      case 'homeAddressState':
        break;
      case 'homeAddressZipCode':
        break;
      case 'homeAddress':
        gqlObj.homeAddress.country = obj.homeAddressCountry;
        gqlObj.homeAddress.state = obj.homeAddressState;
        gqlObj.homeAddress.city = obj.homeAddressCity;
        gqlObj.homeAddress.zipCode = obj.homeAddressZipCode;
        break;
      case '__typename':
        break;
      default:
        gqlObj[key] = obj[key];
    }
  }
  return gqlObj;
};

export const gqlToObj = (gqlObj: displayTypeInput): checkBoxes => {
  let bufferObject: checkBoxes = { // eslint-disable-line
    arrivalDate: true,
    departureDate: true,
    firstName: false,
    lastName: false,
    cellPhoneNumber: false,
    email: false,
    emergencyContactNumber: false,
    businessName: false,
    businessAddress: false,
    country: false,
    city: false,
    state: false,
    zipCode: false,
    businessPhoneNumber: false,
    make: false,
    vehicleModel: false,
    type: false,
    color: false,
    licensePlate: false,
    fleetNumber: false,
    licensePlateState: false,
    primaryDriverName: false,
    licensePrimaryDriver: false,
    qrCode: false,
    homeAddress: false,
    homeAddressCountry: false,
    homeAddressCity: false,
    homeAddressState: false,
    homeAddressZipCode: false,
    rentalAddress: false,
    destination: false,
  };
  for (const key in gqlObj) { // eslint-disable-line
    switch (key) {
      case 'businessAddress':
        bufferObject.businessAddress = true;
        for (const subKey in gqlObj[key]) { // eslint-disable-line
          switch (subKey) {
            case 'country':
              bufferObject.country = gqlObj.businessAddress?.country
                ? gqlObj.businessAddress.country
                : false;
              break;
            case 'city':
              bufferObject.city = gqlObj.businessAddress?.city
                ? gqlObj.businessAddress.city
                : false;
              break;
            case 'state':
              bufferObject.state = gqlObj.businessAddress?.state
                ? gqlObj.businessAddress.state
                : false;
              break;
            case 'zipCode':
              bufferObject.zipCode = gqlObj.businessAddress?.zipCode
                ? gqlObj.businessAddress.zipCode
                : false;
              break;
            default:
              break;
          }
        }
        break;

      case 'homeAddress':
        bufferObject.homeAddress = true;
          for (const subKey in gqlObj[key]) { // eslint-disable-line
          switch (subKey) {
            case 'country':
              bufferObject.homeAddressCountry = gqlObj.homeAddress?.country
                ? gqlObj.homeAddress.country
                : false;
              break;
            case 'city':
              bufferObject.homeAddressCity = gqlObj.homeAddress?.city
                ? gqlObj.homeAddress.city
                : false;
              break;
            case 'state':
              bufferObject.homeAddressState = gqlObj.homeAddress?.state
                ? gqlObj.homeAddress.state
                : false;
              break;
            case 'zipCode':
              bufferObject.homeAddressZipCode = gqlObj.homeAddress?.zipCode
                ? gqlObj.homeAddress.zipCode
                : false;
              break;
            default:
              break;
          }
        }
        break;

      default:
        bufferObject[key] = Boolean(gqlObj[key]);
    }
  }
  const result = bufferObject;
  return result;
};
