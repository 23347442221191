import React, { ReactElement, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './userProfileButton.module.css';
import store from '../../common_lib_front/utilities/store';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import { ReactComponent as UserImg } from '../../assets/userMobile.svg';

export const UserProfileButton = () :ReactElement => {
  const { open, containerId } = useContainerBlur('user-profile-btn', {
    defaultState: false,
    autoCloseInnerButton: true,
    autoOpen: true,
  });
  const [user, setUser] = useState<any>(store.getState().user);
  store.subscribe(() => setUser(store.getState().user));
  const history = useHistory();
  const smallScreen = useMediaQuery('(max-width: 767px)');

  return (
    <div id={containerId} className={style.box}>
      <div className={style.profileBtn}>
        <GenericButton
          // color={smallScreen ? 'transparent' : 'yellow'}
        // icon={smallScreen ? 'user' : 'none'}
          icon={(className) => (smallScreen
            ? <UserImg className={style.userImg} />
            : <></>)}
          shape={smallScreen ? 'rectangle' : 'circle'}
          title={smallScreen ? '' : user?.firstName?.slice(0, 1).toUpperCase() || ''}
        />

      </div>
      {open
        ? (
          <div className={`${style.itemsBox} `}>
            <h4 className={`${style.name} `}>{`${user?.firstName} ${user?.lastName}`}</h4>
            <p className={`${style.email} `}>{user?.email}</p>
            <Link to="/edit-profile/my-profile">
              <button className={`${style.btn} `}>
                <div className={style.imgUser} />
                {' '}
                My Profile
              </button>
            </Link>
            <button
              onClick={() => {
                store.dispatch({ type: 'user/logout' });
                history.push('/login');
              }}
              className={`${style.btn} `}
            >
              <div className={style.imgSignout} />
              Sign Out
            </button>
            <p className={`${style.help} `}>Help</p>
          </div>
        )
        : null}
    </div>
  );
};

export default UserProfileButton;
