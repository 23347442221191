import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { gql, useMutation } from '@apollo/client';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './userInfo.module.css';
import UserInfoForm from './userInfoForm';
import { DragAndDropItem } from '../../../components/dragAndDrop/dragAndDrop';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';

const EDIT_USER_INFO = (portalName:string) => {
  switch (portalName) {
    case 'guest':
      return gql`
        mutation SetGuestInfoConfig (
          $newInfoConfig: GuestInfoConfigInput!,
          $passInfoId: String!,
        ) {
          setGuestInfoConfig (
            newGuestInfoConfig: $newInfoConfig,
            passInfoId: $passInfoId
          ) {
            error
            success
          }
        }`;
    case 'vendor':
      return gql`
        mutation SetVendorInfoConfig (
          $newInfoConfig: VendorInfoConfigInput!,
          $passInfoId: String!,
        ) {
          setVendorInfoConfig (
            newVendorInfoConfig: $newInfoConfig,
            passInfoId: $passInfoId
          ) {
            error
            success
          }
        }`;
    default:
      return gql`
        mutation SetGuestInfoConfig (
          $newInfoConfig: GuestInfoConfigInput!,
          $passInfoId: String!,
        ) {
          setGuestInfoConfig (
            newGuestInfoConfig: $newInfoConfig,
            passInfoId: $passInfoId
          ) {
            error
            success
          }
        }`;
  }
};

type Props = {
  communityName?: string,
  portalName: string,
  stepNum?: number,
}

export default function UserInfo(props: Props) :ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  const history = useHistory();
  const [alert, setAllert] = useState<string>('');

  const [doEdit] = useMutation(EDIT_USER_INFO(portalName?.toLowerCase() || ''));
  const submitHandler = (data: Array<DragAndDropItem>) => {
    const newInfoConfig :any = {};
    data.forEach((i:DragAndDropItem) => {
      if (portalName?.toLowerCase() === 'guest' && i.name === 'numberVendors') {
        return;
      }
      if (portalName?.toLowerCase() === 'vendor' && i.name === 'numberGuests') {
        return;
      }
      newInfoConfig[i.name] = i.value;
    });
    doEdit({
      variables: {
        passInfoId: registrationId,
        newInfoConfig,
      },
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.setVendorInfoConfig?.success) {
          history.push(`/pass-builder/${registrationId}/employee-info`);
        }
        if (res?.data?.setGuestInfoConfig?.success) {
          history.push(`/pass-builder/${registrationId}/vehicle-info`);
        }
      })
      .catch((err) => setAllert(err.message));
  };

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <GenericAlert
          title={alert}
          hidden={!alert}
          color="red"
        />
        <UserInfoForm
          portalName={portalName || 'guest'}
          registrationId={registrationId}
          showContinueBtn
          submitHandler={submitHandler}
          stepNum={stepNum}
        />
      </InfoBackground>

    </div>
  );
}
UserInfo.defaultProps = {
  communityName: undefined,
  stepNum: undefined,
};
