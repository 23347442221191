import React, { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import style from './price.module.css';
import usePriceInfoForm, { AddOnType, FeeType, PriceFormProps } from './usePriceForm';

export default function PriceInfoForm(props: PriceFormProps): ReactElement {
  const {
    editBtnLink, showContinueBtn, stepNum,
  } = props;

  let cashedFees: Array<FeeType> | null = null;
  let cashedAddons: Array<AddOnType> | null = null;
  const {
    data,
    editData,
    alert,
    submitHandlerWrapper,
    editBulkDiscount,
    addBulkDiscount,
    deleteBulkDiscount,
    editAddon,
    addAddon,
    deleteAddon,
    editFee,
    addFee,
    deleteFee,
    form,
  } = usePriceInfoForm(props);

  const [showDiscount, setShowDiscount] = useState<boolean>(false);
  const [showChargePerDay, setShowChargePerDay] = useState<boolean>(false);
  const [initialChargePerDay, setInitialChargePerDay] = useState<number>(0);
  useEffect(() => {
    if (data.chargePerDay > 0) {
      setShowChargePerDay(true);
      setInitialChargePerDay(data.chargePerDay);
    }
  }, [data]);
  return (
    <form
      className={form}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        submitHandlerWrapper();
      }}
    >
      <GenericAlert
        title={alert}
        hidden={!alert}
        color="red"
      />
      {
        editBtnLink
          ? (
            <div className={style.editBtn}>
              <Link to={editBtnLink} className={style.link}>
                <GenericButton
                  title="Edit"
                />
              </Link>

            </div>
          ) : null
      }
      <div className={style.priceBox}>
        <div className={style.priceTitleBox}>
          <h1 className={style.priceTitle}>
            Step
            {' '}
            {stepNum || 6}
            : Pass Price

          </h1>

        </div>
        <div className={style.priceInfoBox}>
          <div className={style.col}>
            <h4 className={style.mainTitle}>
              <span className={style.dot} />
              Set a Price
            </h4>
            <div className={style.optionBtnsBox}>
              <div className={` ${!data.isFree ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="Custom Price"
                  clickHandler={() => {
                    editData('isFree', false);
                  }}
                />

              </div>
              <div className={` ${data.isFree ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="Free"
                  clickHandler={() => {
                    editData('isFree', true);
                  }}
                />

              </div>
            </div>
            {
              !data.isFree
                ? (
                  <div>
                    <div className={style.passPriceInput}>
                      <InputField
                        labelTitle="Pass Price"
                        htmlFor="pass-price-inpt"
                        inputType="number"
                        step="any"
                        min="0"
                        inputValue={data.price.toString()}
                        changeHandler={(e) => {
                          editData('price', Number(e.target.value));
                        }}
                      />

                    </div>
                    {/* Charge Per day layout */}
                    <div className={style.chargePer}>
                      <h4 className={style.mainTitle}>
                        <span className={style.dot} />
                        Charge per number of days (charge per day, three days, one week, etc.)
                      </h4>
                      <div className={style.btnLeftRightAddonBox}>
                        <div className={showChargePerDay ? style.btnBlue : style.btnWhite}>
                          <GenericButton
                            title="Yes"
                            clickHandler={() => {
                              setShowChargePerDay(true);
                              editData('chargePerDay', initialChargePerDay);
                            }}
                          />

                        </div>
                        <div className={showChargePerDay ? style.btnWhite
                          : style.btnBlue}
                        >
                          <GenericButton
                            title="No"
                            clickHandler={() => {
                              setShowChargePerDay(false);
                              editData('chargePerDay', 0);
                            }}
                          />

                        </div>
                      </div>
                      {
                        showChargePerDay ? (
                          <div className={style.nameInput}>
                            <InputField
                              labelTitle="Enter number of days"
                              htmlFor="charge-per-day"
                              inputType="number"
                              inputValue={String(data.chargePerDay)}
                              changeHandler={(e) => {
                                editData('chargePerDay', parseInt(e.target.value, 10));
                              }}
                            />
                          </div>
                        ) : (<></>)
                      }
                    </div>
                    {/* End Charge Per day layout */}

                    <div className={style.transactionBox}>
                      <h4 className={style.mainTitle}>
                        <span className={style.dot} />
                        Custom Transaction Fee
                      </h4>
                      <div className={style.optionBtnsBox}>
                        <div className={` ${data.allowFees ? style.btnBlue : style.btnWhite}`}>
                          <GenericButton
                            title="Custom Transaction Fee"
                            clickHandler={() => {
                              editData('allowFees', true);
                              if (cashedFees) {
                                editData('fees', cashedFees);
                                cashedFees = null;
                              }
                            }}
                          />

                        </div>
                        <div className={` ${!data.allowFees ? style.btnBlue : style.btnWhite}`}>
                          <GenericButton
                            title="No Fee"
                            clickHandler={() => {
                              editData('allowFees', false);
                              if (data.fees.length > 0) {
                                cashedFees = data.fees;
                                editData('fees', []);
                              }
                            }}
                          />

                        </div>
                      </div>
                      {data.allowFees
                        ? (
                          <div>
                            {
                              data.fees.map((fee, idx) => (
                                <>
                                  <div className={style.feeHeader}>
                                    {`Fee ${idx + 1}`}
                                    <GenericButton
                                      title="Remove"
                                      clickHandler={() => deleteFee(idx)}
                                    />
                                  </div>
                                  <div className={style.nameInput}>
                                    <InputField
                                      labelTitle="Please enter the name of the fee"
                                      htmlFor={`fee-name-inpt-${idx}`}
                                      inputValue={fee.name}
                                      changeHandler={(e) => {
                                        editFee(idx, 'name', e.target.value);
                                      }}
                                    />

                                  </div>
                                  <h4 className={style.selectFeeTitle}>
                                    Select if the fee is applied on a per-pass basis or on a
                                    per-transaction basis
                                  </h4>
                                  <div className={`${fee.feeType === 0 ? style.feeSelectorBtnBlue : style.feeSelectorBtnWhite}`}>
                                    <GenericButton
                                      title="Apply fee on a per-pass basis"
                                      clickHandler={() => {
                                        editFee(idx, 'feeType', 0);
                                      }}
                                    />

                                  </div>
                                  <div className={`${fee.feeType === 1 ? style.feeSelectorBtnBlue : style.feeSelectorBtnWhite}`}>
                                    <GenericButton
                                      title="Apply fee on a per-transaction basis"
                                      clickHandler={() => {
                                        editFee(idx, 'feeType', 1);
                                      }}
                                    />

                                  </div>
                                  <div className={style.btnLeftRightBox}>
                                    <div className={`${fee.amountType === 0 ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                                      <GenericButton
                                        title="Percentage of the total cost"
                                        clickHandler={() => {
                                          editFee(idx, 'amountType', 0);
                                        }}
                                      />

                                    </div>
                                    <div className={`${fee.amountType === 1 ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                                      <GenericButton
                                        title="Fixed Fee"
                                        clickHandler={() => {
                                          editFee(idx, 'amountType', 1);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className={`${fee.amountType === 0 ? style.PercentageInput : style.fixedInput}`}>
                                    <InputField
                                      htmlFor={`fee-cost-inpt-${idx}`}
                                      labelTitle="Fee"
                                      inputType="number"
                                      min="0"
                                      inputValue={(fee.amountType === 0 ? fee.amount * 100 : fee.amount).toString()}
                                      changeHandler={(e) => {
                                        editFee(idx, 'amount', Number(e.target.value) / (fee.amountType === 0 ? 100 : 1));
                                      }}
                                    />

                                  </div>
                                </>
                              ))
                            }
                            <div className={style.addFeeBox}>
                              <div>
                                <GenericButton
                                  title="Add a Fee +"
                                  type="button"
                                  clickHandler={addFee}
                                />

                              </div>

                            </div>

                          </div>
                        ) : null}

                    </div>

                    <div className={style.transactionBox}>
                      <h4 className={style.mainTitle}>
                        <span className={style.dot} />
                        Charge For Full Capacity
                        (Note: charges will be based on the capacity of the unit and
                        not the number of passes requested)
                      </h4>
                      <div className={style.optionBtnsBox}>
                        <div className={` ${data.chargeForFullCapacity ? style.btnBlue : style.btnWhite}`}>
                          <GenericButton
                            title="Yes"
                            clickHandler={() => {
                              editData('chargeForFullCapacity', true);
                            }}
                          />

                        </div>
                        <div className={` ${!data.chargeForFullCapacity ? style.btnBlue : style.btnWhite}`}>
                          <GenericButton
                            title="No"
                            clickHandler={() => {
                              editData('chargeForFullCapacity', false);
                            }}
                          />

                        </div>
                      </div>
                    </div>

                  </div>
                ) : null

            }
            {/* enable discount section */}
            {showChargePerDay ? (
              <div className={style.enableDiscount}>
                {' '}
                <h4 className={style.mainTitle}>
                  <span className={style.dot} />
                  Enable Bulk Discounts
                </h4>
                <div className={style.btnLeftRightAddonBox}>
                  <div className={style.optionBtnsBox}>
                    <div className={data.discount ? style.btnBlue
                      : style.btnWhite}
                    >
                      <GenericButton
                        title="Yes"
                        clickHandler={() => {
                          editData('discount', true);
                        }}
                      />

                    </div>
                    <div className={data.discount ? style.btnWhite
                      : style.btnBlue}
                    >
                      <GenericButton
                        title="No"
                        clickHandler={() => {
                          editData('discount', false);
                        }}
                      />

                    </div>
                  </div>
                </div>
                {data.discount
                  && (
                    <div>
                      <div className={style.dayInput}>
                        <InputField
                          labelTitle="Minimum # of Days for Daily Discount
                    (Charge per Day,  User Specified Date Range Only)"
                          htmlFor="daily-discount-days"
                          inputType="number"
                          step="1"
                          min="0"
                          inputValue={data.discountDuration.toString()}
                          changeHandler={(e) => {
                            editData('discountDuration', Number(e.target.value));
                          }}
                        />
                      </div>
                      <div className={style.fixedInput}>
                        <InputField
                          htmlFor="bulk-discount-price"
                          labelTitle="Bulk Discount Pass Price Per Day"
                          inputType="number"
                          min="0"
                          inputValue={data.discountPrice.toString()}
                          changeHandler={(e) => {
                            editData('discountPrice', Number(e.target.value));
                          }}
                        />
                      </div>

                    </div>
                  )}
              </div>
            ) : null}
            {/* enable discount section */}
          </div>
          <div className={style.col}>
            <h4 className={style.mainTitle}>
              <span className={style.dot} />
              Pass Add-on

            </h4>
            <div className={style.optionBtnsBox}>
              <div className={` ${data.allowAddons ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="Custom Add-on"
                  clickHandler={() => {
                    editData('allowAddons', true);
                    if (cashedAddons) {
                      editData('addons', cashedAddons);
                      cashedAddons = null;
                    }
                  }}
                />

              </div>
              <div className={` ${!data.allowAddons ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="No Add-on"
                  clickHandler={() => {
                    if (data.addons.length > 0) {
                      cashedAddons = data.addons;
                      editData('addons', []);
                    }
                    editData('allowAddons', false);
                  }}
                />

              </div>
            </div>
            {data.allowAddons ? (
              <>
                <div>
                  {data.addons.map((elem, idx) => (
                    <>
                      <div className={style.addonHeader}>
                        Add-on
                        {` ${idx + 1}`}
                        <div className={style.removeBtn}>
                          <GenericButton
                            title="Remove"
                            clickHandler={() => {
                              deleteAddon(idx);
                            }}
                          />

                        </div>
                      </div>
                      <div className={style.nameInput}>
                        <InputField
                          labelTitle="Add-on Name"
                          htmlFor={`add-on-name-inpt-${idx}`}
                          inputValue={elem.name}
                          changeHandler={(e) => {
                            editAddon(idx, 'name', e.target.value);
                          }}
                        />

                      </div>
                      <label htmlFor={`add-on-description-inpt-${idx}`}>
                        <h4 className={style.addonDescriptionTitle}>
                          Add-on Description
                        </h4>
                        <textarea
                          className={style.textarea}
                          value={elem.description}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            editAddon(idx, 'description', e.target.value);
                          }}
                        />
                      </label>
                      <div className={style.lineBox}>
                        <div className={style.btnLeftRightAddonBox}>
                          <div className={`${!elem.isFree ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                            <GenericButton
                              title="Custom Add-on Price"
                              clickHandler={() => {
                                editAddon(idx, 'isFree', false);
                              }}
                            />

                          </div>
                          <div className={`${elem.isFree ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                            <GenericButton
                              title="Free"
                              clickHandler={() => {
                                editAddon(idx, 'isFree', true);
                              }}
                            />

                          </div>
                        </div>

                      </div>
                      {!elem.isFree ? (
                        <div>
                          <div className={style.fixedInput}>
                            <InputField
                              htmlFor={`add-on-price-inpt-${idx}`}
                              labelTitle="Add-on Price"
                              inputType="number"
                              step="any"
                              min="0"
                              inputValue={elem.price.toString()}
                              changeHandler={(e) => {
                                editAddon(idx, 'price', Number(e.target.value));
                              }}
                            />
                          </div>
                          <h4 className={`${style.selectFeeTitle} ${style.margin}`}>
                            Select if fees apply to add-on products

                          </h4>
                          <div className={style.btnLeftRightAddonBox}>
                            <div className={`${elem.feesApply ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                              <GenericButton
                                title="Yes"
                                clickHandler={() => {
                                  editAddon(idx, 'feesApply', true);
                                }}
                              />

                            </div>
                            <div className={`${!elem.feesApply ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                              <GenericButton
                                title="No"
                                clickHandler={() => {
                                  editAddon(idx, 'feesApply', false);
                                }}
                              />

                            </div>
                          </div>
                          {data.applyAddon === 'yes' ? (
                            <div>
                              <div className={style.addonFeeHeader}>
                                Fee
                                <div className={style.addFeeBtn}>
                                  <GenericButton
                                    title="Add a fee"
                                  />

                                </div>
                                <div className={style.removeBtn}>
                                  <GenericButton
                                    title="Remove"
                                  />

                                </div>
                              </div>
                              <div className={style.nameInput}>
                                <InputField
                                  htmlFor="add-on-fee-1-name"
                                  labelTitle="Please enter the name of the fee"
                                />

                              </div>
                              <div>
                                <h4 className={`${style.selectFeeTitle} `}>
                                  Select if the fee is applied on a per-product basis
                                  or a per-transaction basis

                                </h4>
                                <div className={`${data.selectAddonFeeBasis === 'perPass' ? style.feeSelectorBtnBlue : style.feeSelectorBtnWhite}`}>
                                  <GenericButton
                                    title="Apply fee on a per-pass basis"
                                    clickHandler={() => {
                                      editData('selectAddonFeeBasis', 'perPass');
                                    }}
                                  />

                                </div>
                                <div className={`${data.selectAddonFeeBasis === 'perTransaction' ? style.feeSelectorBtnBlue : style.feeSelectorBtnWhite}`}>
                                  <GenericButton
                                    title="Apply fee on a per-transaction basis"
                                    clickHandler={() => {
                                      editData('selectAddonFeeBasis', 'perTransaction');
                                    }}
                                  />

                                </div>
                              </div>
                              <div className={style.btnLeftRightBox}>
                                <div className={`${data.addonFeePaymentMethod === 'percentage' ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                                  <GenericButton
                                    title="Percentage of the total add-on cost"
                                    clickHandler={() => {
                                      editData('addonFeePaymentMethod', 'percentage');
                                    }}
                                  />

                                </div>
                                <div className={`${data.addonFeePaymentMethod === 'fixed' ? style.leftRightBtnBlue : style.leftRightBtnWhite}`}>
                                  <GenericButton
                                    title="Fixed Fee"
                                    clickHandler={() => {
                                      editData('addonFeePaymentMethod', 'fixed');
                                    }}
                                  />

                                </div>
                              </div>
                              <div className={`${data.addonFeePaymentMethod === 'percentage' ? style.PercentageInput : style.fixedInput}`}>
                                <InputField
                                  htmlFor="add-on-fee-1-cost"
                                  labelTitle="Fee"
                                />

                              </div>

                            </div>
                          ) : null}

                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
                <div className={style.addFeeBox}>
                  <div>
                    <GenericButton
                      title="Add an Add-on +"
                      clickHandler={() => {
                        addAddon();
                      }}
                    />

                  </div>

                </div>
              </>
            ) : null}

          </div>
          <div className={style.col}>
            {/* <label
              htmlFor="pos-option"
            >
              <h4 className={style.mainTitle}>
                <span className={style.dot} />
                Select POS Option

              </h4>
              <select
                id="pos-option"
                className={style.select}
                value={data.posOption}
                onChange={(e) => {
                  editData('posOption', e.target.value);
                }}
              >
                <option value="stripe">Stripe</option>
              </select>
            </label>
            <h4 className={style.mainTitle}>
              <span className={style.dot} />
              Custom Credit card fee
            </h4>
            <div className={style.optionBtnsBox}>
              <div className={` ${data.creditCardFeeType === 'percentage'
                ? style.btnBlue : style.btnWhite}`}
              >
                <GenericButton
                  title="Percentage of Total"
                  clickHandler={() => {
                    editData('creditCardFeeType', 'percentage');
                  }}
                />
              </div>
              <div className={` ${data.creditCardFeeType === 'fixedAmount'
                ? style.btnBlue : style.btnWhite}`}
              >
                <GenericButton
                  title="Fixed Fee"
                  clickHandler={() => {
                    editData('creditCardFeeType', 'fixedAmount');
                  }}
                />
              </div>
            </div> */}
            {/* <div>
              <div className={
                `${data.creditCardFeeType === 'percentage' ?
                style.PercentageInput :
                style.fixedInput} `
              }>
                <InputField
                  labelTitle="Percentage of the total transaction fee"
                  htmlFor="cc-fee-percent"
                  inputType="number"
                  step="any"
                  min="0"
                  inputValue={data.creditCardFee.toString()}
                  changeHandler={(e) => {
                    editData('creditCardFee', Number(e.target.value));
                  }}
                />
              </div>
            </div>
            <h4 className={`${style.mainTitle} ${style.marginTop}`}>
              <span className={style.dot} />
              Select if ACH payments are allowed
            </h4>
            <div className={style.optionBtnsBox}>
              <div className={` ${data.allowAch ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="yes"
                  clickHandler={() => {
                    editData('allowAch', true);
                  }}
                />

              </div>
              <div className={` ${!data.allowAch ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="No"
                  clickHandler={() => {
                    editData('allowAch', false);
                  }}
                />

              </div>
            </div>
            {data.allowAch ? (
              <div>
                <h4 className={style.selectFeeTitle}>
                  Custom ACH Payments Fee

                </h4>
                <div className={style.btnLeftRightBox}>
                  <div className={`${data.achFeeType === 0 ?
                    style.leftRightBtnBlue :
                    style.leftRightBtnWhite}`}>
                    <GenericButton
                      title="Percentage of Total"
                      clickHandler={() => {
                        editData('achFeeType', 0);
                      }}
                    />

                  </div>
                  <div className={`${data.achFeeType === 1 ?
                    style.leftRightBtnBlue :
                    style.leftRightBtnWhite}`}>
                    <GenericButton
                      title="Fixed Fee"
                      clickHandler={() => {
                        editData('achFeeType', 1);
                      }}
                    />

                  </div>
                </div>
                <div>
                  <div className={data.achFeeType === 0 ? style.PercentageInput : style.fixedInput}>
                    <InputField
                      labelTitle="Percentage of the total transaction fee"
                      htmlFor="ach-fee-ammount"
                      inputType="number"
                      step="any"
                      min="0"
                      inputValue={data.achFee.toString()}
                      changeHandler={(e) => {
                        console.log(e.target.value);
                        editData('achFee', Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null} */}
            {/* <h4 className={`${style.mainTitle} ${style.marginTop}`}>
              <span className={style.dot} />
              Select if Cash/Physical Checks are allowed

            </h4>
            <div className={`${style.optionBtnsBox} ${style.noneBorder}`}>
              <div className={` ${data.allowCashOrCheck ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="yes"
                  clickHandler={() => {
                    editData('allowCashOrCheck', true);
                  }}
                />

              </div>
              <div className={` ${!data.allowCashOrCheck ? style.btnBlue : style.btnWhite}`}>
                <GenericButton
                  title="No"
                  clickHandler={() => {
                    editData('allowCashOrCheck', false);
                  }}
                />

              </div>
            </div> */}
            {/* <div className={style.col}>
              <h4 className={`${style.mainTitle} ${style.marginTop}`}>
                <span className={style.dot} />
                Select if Discounts are allowed

              </h4>
              <div className={`${style.optionBtnsBox} ${style.noneBorder}`}>
                <div className={` ${data.discount ? style.btnBlue : style.btnWhite}`}>
                  <GenericButton
                    title="yes"
                    clickHandler={() => {
                      editData('discount', true);
                    }}
                  />

                </div>
                <div className={` ${!data.discount ? style.btnBlue : style.btnWhite}`}>
                  <GenericButton
                    title="No"
                    clickHandler={() => {
                      editData('discount', false);
                    }}
                  />
                </div>
                {data.discount ? (
                  <div>
                    <div className={style.dayInput}>
                      <InputField
                        labelTitle="Discount Duration"
                        htmlFor="discount-duration-inpt"
                        inputType="number"
                        step="any"
                        min="0"
                        inputValue={data.discountDuration.toString()}
                        changeHandler={(e) => {
                          editData('discountDuration', Number(e.target.value));
                        }}
                      />
                    </div>
                    <div className={style.passPriceInput}>
                      <InputField
                        labelTitle="Discount Price"
                        htmlFor="discount-price-inpt"
                        inputType="number"
                        step="any"
                        min="0"
                        inputValue={data.discountPrice.toString()}
                        changeHandler={(e) => {
                          editData('discountPrice', Number(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div> */}
            <h4 className={style.mainTitle}>
              <span className={style.dot} />
              Bulk Discounts
            </h4>
            <div className={style.addFeeBox}>
              <div>
                <GenericButton
                  title="Add an Discount +"
                  clickHandler={() => {
                    addBulkDiscount();
                  }}
                />
              </div>
            </div>
            {data.discounts.map((elem, idx) => (
              <>
                <div className={style.addonHeader}>
                  Discount
                  {` ${idx + 1}`}
                  <div className={style.removeBtn}>
                    <GenericButton
                      title="Remove"
                      clickHandler={() => {
                        console.log({ idx });
                        deleteBulkDiscount(idx);
                      }}
                    />
                  </div>
                </div>
                <div className={style.nameInput}>
                  <InputField
                    labelTitle="Quantity"
                    htmlFor={`add-on-quantity-inpt-${idx}`}
                    inputValue={(elem.quantity)}
                    changeHandler={(e) => {
                      editBulkDiscount(idx, 'quantity', +(e.target.value));
                    }}
                  />
                  <InputField
                    labelTitle="Price"
                    htmlFor={`add-on-price-inpt-${idx}`}
                    inputValue={elem.price}
                    changeHandler={(e) => {
                      editBulkDiscount(idx, 'price', +(e.target.value));
                    }}
                  />
                  <InputField
                    labelTitle="Discount Type"
                    htmlFor={`add-on-discountType-inpt-${idx}`}
                    inputValue={elem.discountType}
                    changeHandler={(e) => {
                      editBulkDiscount(idx, 'discountType', e.target.value);
                    }}
                  />
                  <InputField
                    labelTitle="Special Price"
                    htmlFor={`add-on-specialPrice-inpt-${idx}`}
                    inputValue={elem.specialPrice}
                    changeHandler={(e) => {
                      editBulkDiscount(idx, 'specialPrice', +(e.target.value));
                    }}
                  />
                </div>
              </>
            ))}
          </div>
          <div className={style.col}><></></div>
        </div>

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />

          </div>
        </div>
      ) : null}

    </form>
  );
}
PriceInfoForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  stepNum: undefined,
  submitHandler: undefined,
};
