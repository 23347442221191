import React, { ReactElement, useState } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import CommunitySelect from '../../components/communitySelect/communitySelect';
import InfoBackground from '../../components/infoBackground/infoBackground';
import style from './posConfig.module.css';

export default function PosConfig() :ReactElement {
  const [community, setCommunity] = useState<string>('');
  return (
    <div className={style.container}>
      <div className={style.box}>
        <InfoBackground
          title="POS Configuration"
        >
          <div className={style.communitySelectBox}>
            <div className={style.innerBox}>
              <CommunitySelect
                id="community-selector"
                value={community}
                changeHandler={setCommunity}
              />
              <div>
                {
          community
            ? (
              <div className={style.infoInputPart}>
                <div className={style.strip} />
                <div className={style.inputBox}>
                  <div className={style.idInput}>
                    <InputField
                      htmlFor="stripe-acct-id"
                      labelTitle="Community stripe account ID"
                    />

                  </div>
                  <div className={style.generateBtn}>
                    <GenericButton
                      title="Generate a link"
                    />

                  </div>

                </div>
                <p className={style.text}>
                  don&apos;t have an account?
                  {' '}
                  <a href="/" className={style.link}>Create an account</a>

                </p>
                <div className={style.idInput}>
                  <InputField
                    htmlFor="stripe-configuration"
                    labelTitle="Stripe Configuration"
                  />

                </div>
                <div className={style.saveBtnBox}>
                  <div className={style.saveBtn}>
                    <GenericButton
                      title="Save Configuration"
                    />

                  </div>

                </div>
              </div>
            ) : null
        }
              </div>

            </div>

          </div>
        </InfoBackground>

      </div>
    </div>
  );
}
