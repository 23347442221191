import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import useMediaQuery from '../../../common_lib_front/utilities/useMediaQuery';
import style from './wrapper.module.css';

const GET_DATA = gql`
query WrapperGetEducationInfoConfig(
  $passInfoId: String!
) {
  getPassInfo(
    passInfoId: $passInfoId
  ) {
    success
    error
    data {
      communityId
      portal
    }
  }
}
`;

export default function useWrapper() :{
  // communityName?: string,
  // setCommunityName: (val: string) => void,
  communityId?: string,
  portalName?: string,
  smallScreen: boolean,
  stepNum: number,
  setStepNum: (val: number) => void,
  location: any,
  showSteps: boolean,
  setShowSteps: (val: boolean) => void,
  registrationId?: string,
  getLinkClassName: (linkNum:number | string) => string,
  hideNav: boolean,
  hideInvisibleBoxes: boolean,
  pages?: string,
  wrapper?: string,
  steps?: string,
  getStepNumByName: (stepName: string) => number | undefined,
  } {
  const { registrationId } = useParams<{ registrationId?: string }>();
  const [fetchPassData, {
    data: passData,
  }] = useLazyQuery(GET_DATA);
  useEffect(() => {
    if (registrationId) {
      fetchPassData({
        variables: { passInfoId: registrationId },
      });
    }
  }, [registrationId]);

  const location = useLocation();
  const smallScreen = useMediaQuery('(max-width: 767px)');
  const [showSteps, setShowSteps] = useState<boolean>(true);
  const [stepNum, setStepNum] = useState<number>(0);

  const getStepNumByName = (stepName:string) :number | undefined => ([
    'pass-info',
    'user-info',
    'employee-info',
    'vehicle-info',
    // 'education-info',
    // 'document-info',
    'price-info',
    'pass-duration',
    'instructions',
    'display-info',
    'review',
  ]
    .filter((e:string) => {
      if (passData?.getPassInfo?.data?.portal?.toLowerCase() === 'guest') {
        return !['employee-info'].includes(e);
      }
      if (passData?.getPassInfo?.data?.portal?.toLowerCase() === 'vendor') {
        return !['user-info'].includes(e);
      }
      return true;
    })
    .findIndex((e:string) => e === stepName) + 1 || undefined
  );

  useEffect(() => {
    switch (true) {
      // case /pass-builder$/.test(location.pathname):
      //   setStepNum(0);
      //   break;
      case /pass-info/.test(location.pathname):
        setStepNum(getStepNumByName('pass-info') || 1);
        break;
      case /user-info/.test(location.pathname):
        setStepNum(getStepNumByName('user-info') || 2);
        break;
      case /employee-info/.test(location.pathname):
        setStepNum(getStepNumByName('employee-info') || 3);
        break;
      case /vehicle-info/.test(location.pathname):
        setStepNum(getStepNumByName('vehicle-info') || 3);
        break;
      // case /education-info/.test(location.pathname):
      //   setStepNum(getStepNumByName('education-info') || 4);
      //   break;
      // case /document-info/.test(location.pathname):
      //   setStepNum(getStepNumByName('document-info') || 5);
      //   break;
      case /price-info/.test(location.pathname):
        setStepNum(getStepNumByName('price-info') || 4);
        break;
      case /pass-duration/.test(location.pathname):
        setStepNum(getStepNumByName('pass-duration') || 5);
        break;
      case /instructions/.test(location.pathname):
        setStepNum(getStepNumByName('instructions') || 6);
        break;
      case /display-info/.test(location.pathname):
        setStepNum(getStepNumByName('display') || 7);
        break;
      case /review/.test(location.pathname):
        setStepNum(getStepNumByName('review') || 8);
        break;
      default:
        setStepNum(0);
    }
  }, [location.pathname, passData]);

  const getLinkClassName = (_linkNum: number | string) :string => {
    let linkNum = _linkNum;
    if (typeof linkNum === 'string') {
      linkNum = getStepNumByName(linkNum) || 0;
    }
    if (linkNum === stepNum) {
      return style.activeLink; // TODO use real css class
    }
    if (linkNum < stepNum) {
      return style.completeLink; // TODO use real css class
    }
    return 'inactive'; // TODO use real css class
  };

  const hideNav = /pass-builder($|\/$|.*manage-passes$)/.test(location.pathname);
  const hideInvisibleBoxes = /pass-builder.*manage-passes$/.test(location.pathname);

  let pages;
  let wrapper;
  if (hideInvisibleBoxes) {
    wrapper = style.wrapperShortPadding;
    pages = style.pagesLarge;
  }
  if (!hideInvisibleBoxes) {
    wrapper = style.wrapper;
    pages = style.pages;
  }

  let steps;
  if (showSteps) {
    steps = style.stepsDown;
  }
  if (!showSteps) {
    steps = style.stepsUp;
  }

  return {
    communityId: passData?.getPassInfo?.data?.communityId,
    portalName: passData?.getPassInfo?.data?.portal,
    smallScreen,
    stepNum,
    setStepNum,
    location,
    showSteps,
    setShowSteps,
    registrationId,
    getLinkClassName,
    hideNav,
    hideInvisibleBoxes,
    wrapper,
    pages,
    steps,
    getStepNumByName,
  };
}
