import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router';

const GET_EDUCATION_INFO = gql`
query GetEducationInfo(
  $passInfoId: String!,
  $communityId: String!
) {
  getEducationInfoConfig(
    passInfoId: $passInfoId
  ) {
    success
    error
    data {
      isEducationRequired
      isEducationRequiredEmployees
      englishEducationEnabled
      spanishEducationEnabled
      englishEducation
      spanishEducation
    }
  }
  getEducationsByCommunity(
    communityId: $communityId
  ) {
    success
    error
    data {
      name
      educationId
    }
  }
}
`;

const SET_EDUCATION_INFO = gql`
mutation SetEducationInfo(
  $newEducationInfoConfig: EducationInfoConfigInput!,
  $passInfoId: String!
) {
  setEducationInfoConfig(
    newEducationInfoConfig: $newEducationInfoConfig,
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

type educationConfig = {
  isEducationRequired: string,
  isEducationRequiredEmployees: string,
  englishEducationEnabled: boolean,
  spanishEducationEnabled: boolean,
  englishEducation: string | null,
  spanishEducation: string | null,
  [key: string]: boolean | string | null,
}
const makeEducationConfig = (data:any = {}) => ({
  isEducationRequired: data.isEducationRequired || 'optional',
  isEducationRequiredEmployees: data.isEducationRequiredEmployees || 'optional',
  englishEducationEnabled: data.englishEducationEnabled || !!data.englishEducation || false,
  spanishEducationEnabled: data.spanishEducationEnabled || !!data.spanishEducation || false,
  englishEducation: data.englishEducation || data.englishEducationId || null,
  spanishEducation: data.spanishEducation || data.spanishEducationId || null,
});
type education = {
  name: string,
  educationId: string,
}

export default function useEducationForm(communityId: string) :{
  educations: Array<education>,
  passData: educationConfig,
  editPassData: (key: keyof educationConfig, val: educationConfig[string]) => void,
  submitHandler: () => Promise<any>,
} {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [passData, setPassData] = useState<educationConfig>(makeEducationConfig());
  const editPassData = (key: keyof educationConfig, val: educationConfig[string]) => {
    const res = { ...passData };
    res[key] = val;
    setPassData(res);
  };

  const { data } = useQuery<{
    getEducationInfoConfig: {
      success: boolean,
      error: string,
      data?: educationConfig
    }
    getEducationsByCommunity: {
      success: boolean,
      error: string,
      data?: Array<education>
    }
  }>(GET_EDUCATION_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
      communityId,
    },
    onCompleted: (d) => {
      if (d.getEducationInfoConfig.data) {
        setPassData(makeEducationConfig(d.getEducationInfoConfig.data));
      }
    },
  });

  const [doEditEducation] = useMutation(SET_EDUCATION_INFO);

  return {
    educations: data?.getEducationsByCommunity.data || [],
    passData,
    editPassData,
    submitHandler: () => doEditEducation({
      variables: {
        passInfoId: registrationId,
        newEducationInfoConfig: passData,
      },
    }),
  };
}
