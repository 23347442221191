import React, { ReactElement } from 'react';
import {
  Link, Route,
} from 'react-router-dom';
import * as Pages from '../..';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import useWrapper from './useWrapper';
import style from './wrapper.module.css';

export default function PBWrapper() :ReactElement {
  const {
    communityId: communityName,
    portalName,
    smallScreen,
    showSteps,
    setShowSteps,
    registrationId,
    getLinkClassName,
    hideNav,
    hideInvisibleBoxes,
    pages,
    wrapper,
    steps,
    stepNum,
    getStepNumByName,
  } = useWrapper();

  return (
    <div className={wrapper}>
      {
        hideNav
          ? (<div className={hideInvisibleBoxes ? '' : style.invisbleBox} />) : (
            <div className={style.linkBox}>
              <h3 className={steps}>
                {' '}
                <GenericButton
                  title="Steps"
                  clickHandler={() => {
                    setShowSteps(!showSteps);
                  }}
                />

              </h3>
              <div className={style.stepsBox}>
                <div className={style.stepsLine}>
                  <div className={style.rectangle} />
                  <div className={style.arrow} />
                </div>
                {smallScreen && showSteps ? null : (
                  <div className={style.linkItemsBox}>
                    <Link
                      to={`/pass-builder/${registrationId}/pass-info`}
                      className={getLinkClassName('pass-info')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('pass-info')}
                        : Pass Information
                      </p>
                      <div className={style.line} />
                    </Link>
                    {portalName?.toLowerCase() !== 'vendor'
                      ? (
                        <Link
                          to={`/pass-builder/${registrationId}/user-info`}
                          className={getLinkClassName('user-info')}
                        >
                          <p>
                            Step
                            {' '}
                            {getStepNumByName('user-info')}
                            :
                            {' '}
                            {portalName
                              ? `${portalName?.charAt(0).toUpperCase()}${portalName?.slice(1)}`
                              : portalName}
                            {' '}
                            Information
                          </p>
                          <div className={style.line} />
                        </Link>
                      ) : null}
                    {portalName?.toLowerCase() !== 'guest'
                      ? (
                        <Link
                          to={`/pass-builder/${registrationId}/employee-info`}
                          className={getLinkClassName('employee-info')}
                        >
                          {' '}
                          <p>
                            Step
                            {' '}
                            {getStepNumByName('employee-info')}
                            : Employee Information
                          </p>
                          <div className={style.line} />
                        </Link>
                      ) : null}
                    <Link
                      to={`/pass-builder/${registrationId}/vehicle-info`}
                      className={getLinkClassName('vehicle-info')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('vehicle-info')}
                        : Vehicle Information
                      </p>
                      <div className={style.line} />
                    </Link>
                    {/* <Link
                      to={`/pass-builder/${registrationId}/education-info`}
                      className={getLinkClassName('education-info')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('education-info')}
                        : Education
                      </p>
                      <div className={style.line} />
                    </Link> */}
                    {/* <Link
                      to={`/pass-builder/${registrationId}/document-info`}
                      className={getLinkClassName('document-info')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('document-info')}
                        : Upload Documents
                      </p>
                      <div className={style.line} />
                    </Link> */}
                    <Link
                      to={`/pass-builder/${registrationId}/price-info`}
                      className={getLinkClassName('price-info')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('price-info')}
                        : Pass Price
                      </p>
                      <div className={style.line} />
                    </Link>
                    <Link
                      to={`/pass-builder/${registrationId}/pass-duration`}
                      className={getLinkClassName('pass-duration')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('pass-duration')}
                        : Pass Duration
                      </p>
                      <div className={style.line} />
                    </Link>
                    <Link
                      to={`/pass-builder/${registrationId}/instructions`}
                      className={getLinkClassName('instructions')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('instructions')}
                        :  Custom Pass Instructions
                      </p>
                      <div className={style.line} />
                    </Link>
                    <Link
                      to={`/pass-builder/${registrationId}/display-info`}
                      className={getLinkClassName('display-info')}
                    >
                      <p>
                        Step
                        {' '}
                        {getStepNumByName('display-info')}
                        : Select Info Displayed on Pass
                      </p>
                      <div className={style.line} />
                    </Link>
                    <Link
                      to={`/pass-builder/${registrationId}/review`}
                      className={getLinkClassName('review')}
                    >
                      <p>
                        Review and Complete Pass
                      </p>
                      <div className={style.line} />
                    </Link>

                  </div>
                ) }

              </div>
            </div>
          )
      }
      <div className={pages}>
        <Route exact path="/pass-builder">
          <Pages.PBInitialization />
        </Route>
        <Route path="/pass-builder/:registrationId/pass-info">
          <Pages.PBPassInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/user-info">
          <Pages.PBUserInfo
            communityName={communityName}
            portalName={portalName || 'guest'}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/employee-info">
          <Pages.PBEmployeeInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/vehicle-info">
          <Pages.PBVehicleInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/price-info">
          <Pages.PBPriceInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/pass-duration">
          <Pages.PBDurationInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/instructions">
          <Pages.PBInstructionInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/display-info">
          <Pages.PBDisplayInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:registrationId/review">
          <Pages.PBReviewInfo
            communityName={communityName}
            portalName={portalName}
            stepNum={stepNum}
          />
        </Route>
        <Route exact path="/pass-builder/:communityName/:portalName/manage-passes">
          <Pages.PBManagePasses />
        </Route>
      </div>
      <div className={hideInvisibleBoxes ? '' : style.invisbleBox} />
    </div>
  );
}
