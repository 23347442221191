import React, { ReactElement, useState } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './educationUpload.module.css';
import cloudImg from '../../assets/cloud.svg';
import fileImg from '../../assets/file.svg';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';

export default function EducationUpload(props: {
    language?: string,
    id: string,
    uploadHandler?: (name: string, file: string) => Promise<any>,
}) :ReactElement {
  const {
    language, id, uploadHandler,
  } = props;
  const [showUploading, setShowUploading] = useState<string>('');
  const [uploadError, setUploadError] = useState<string>('');

  return (
    <div
      onClickCapture={(e:React.MouseEvent) => {
        document.getElementById(`${id}-file-inpt`)?.click();
      }}
      className={style.box}
    >
      <div className={style.dashedBox}>
        <input
          id={`${id}-file-inpt`}
          type="file"
          hidden
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
            if (!uploadHandler) return;
            const targetFile = e.target?.files?.[0];
            if (!targetFile) return;
            setShowUploading(targetFile.name);
            const fileReader = new FileReader();
            fileReader.readAsDataURL(targetFile);
            fileReader.onloadend = () => {
              const res = fileReader.result;
              console.log(res);
              console.log(typeof res);
              if (res === null) {
                setUploadError('Could not convert file to uploadable format');
                return;
              }
              uploadHandler(targetFile.name, res.toString()
                .replace('data:', '')
                .replace(/^.+,/, ''))
                .then(() => setShowUploading(''))
                .catch(() => setUploadError('Something went wrong'));
            };
          }}
        />
        <img src={cloudImg} alt="" className={style.cloudImg} />
        <h4 className={style.dragTitle}>
          Drag Files to Upload

        </h4>
        {
          language
            ? (
              <>
                {' '}
                <h4 className={style.language}>
                  {language}

                  {' '}
                  Version

                </h4>
              </>
            ) : null
        }
        <div className={style.browseBtn}>
          <GenericButton
            title="Browse Files"
          />

        </div>

      </div>
      {
        showUploading
          ? (
            <div className={style.upoloading}>
              <div className={style.uploadingTitle}>Uploading</div>
              <div className={style.uploadingProcess}>

                <span className={style.uploadingItem}>
                  <img src={fileImg} alt="" />
                  <div className={style.colum}>
                    {showUploading}
                    {/* <div className={style.line} />
                    0% done */}
                    {
                      uploadError
                        ? (
                          <GenericAlert
                            title={uploadError}
                            color="red"
                          />
                        )
                        : <LoadingDiamonds />
                    }
                  </div>
                </span>
                {/* <button className={style.cancel}>&nbsp;</button> */}
              </div>
              <div />
            </div>
          ) : null
      }
    </div>
  );
}
EducationUpload.defaultProps = {
  language: undefined,
  uploadHandler: undefined,
};
