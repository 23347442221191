import React, { ReactElement, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
// import LoginBackground from '../../../components/loginBackground/loginBackground';
import style from './forgotPasswordReset.module.css';
// import { RESET_PASSWORD_MUTATION } from '../../../common_lib_front/utilities/BackendAPI';
// import SchemeDecider from '../../../common_lib_front/utilities/schemeDecider';
import ForgetPWPagesBackground from '../forgetPWPagesBackground/forgetPWPagesBackground';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $confirmPassword: String!,
    $newPassword: String!,
    $resetCode: String!,
  ) {
    resetPassword(
      confirmPassword: $confirmPassword,
      newPassword: $newPassword,
      resetCode: $resetCode,
    ) {
      success,
      error
    }
  }
  `;

// const colorStyle = SchemeDecider();
export default function ForgotPasswordReset() :ReactElement {
  const [newPass, setNewPass] = useState<string>('');
  const [confPass, setConfPass] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  // const identifier: string = useLocation<any>().state?.identifier;
  const { identifier } = useParams<{ identifier: string }>();

  const [doReset] = useMutation(RESET_PASSWORD_MUTATION, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (d.resetPassword.success) {
        setRedirect('/login');
      } else {
        setAlert(d.resetPassword.error);
        setAlertColor('red');
      }
    },
  });

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }
  if (!identifier) {
    setRedirect('/forgot-password/verification-code');
  }

  return (
    <ForgetPWPagesBackground>
      <div>
        <h3 className={`${style.box__mainTitleRight} textColorMain `}>Forgot Password?</h3>
        <h5 className={`${style.box__subTitle} textColorDark `}>Please enter your new password below</h5>
        <form
          onSubmit={(e:React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!newPass) {
              setAlert('Please fill out the required information');
              setAlertColor('red');
              return;
            }
            if (newPass !== confPass) {
              setAlert('Those passwords do not match');
              setAlertColor('red');
              return;
            }
            doReset({
              variables: {
                confirmPassword: confPass,
                newPassword: newPass,
                resetCode: identifier,
              },
            });
          }}
        >
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert
                color={alertColor}
                title={alert}
                hidden={!alert}
              />

            </div>

          </div>
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                htmlFor="new-pass-inpt"
                labelTitle="Enter your new password"
                inputType="password"
                inputValue={newPass}
                changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                  setNewPass(e.target.value);
                }}
              />

            </div>
            <div className={style.box__inputField}>
              <InputField
                htmlFor="cont-pass-inpt"
                labelTitle="Confirm your new password"
                inputType="password"
                inputValue={confPass}
                changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                  setConfPass(e.target.value);
                }}
              />

            </div>

          </div>
          <div className={style.box__btnRight}>
            <div className={style.box__btnRightInnerBox}>
              <GenericButton
                size="medium"
                color="blue"
                title="Reset My Password"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </ForgetPWPagesBackground>
  );
}
