import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import DragAndDrop, { DragAndDropItem } from '../../../components/dragAndDrop/dragAndDrop';
import style from './documents.module.css';

const GET_DOCS = gql`
query GetDocumentsInfo ( 
  $passInfoId: String!
) {
  getDocumentsInfo (
    passInfoId: $passInfoId
  ) {
    success
    error
    data {
      documentInfoId
      name
      required
    }
  }
} 
`;

type Props = {
    registrationId: string,
    editBtnLink?: string,
    addDocument?: (documentInfoId: string) => Promise<any>,
    deleteDocument?: (name:string) => Promise<any>,
    submitHandler?: (data: Array<DragAndDropItem>) => Promise<any>,
    showContinueBtn?: boolean,
    stepNum?: number,
}

export default function DocumentsForm(props: Props) :ReactElement {
  const {
    registrationId,
    editBtnLink,
    deleteDocument,
    addDocument,
    submitHandler,
    stepNum,
    showContinueBtn,
  } = props;
  const history = useHistory();

  const [documentList, setDocumentList] = useState<Array<DragAndDropItem>>([]);
  const updateItem = (name:string, value:string) => {
    const res = [...documentList];
    for (let i = 0; i < res.length; i += 1) {
      if (res[i].name === name) {
        res[i].value = value;
        break;
      }
    }
    setDocumentList(res);
  };

  const { refetch } = useQuery(GET_DOCS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { passInfoId: registrationId },
    onCompleted: (d) => {
      if (d.getDocumentsInfo.data) {
        setDocumentList(d.getDocumentsInfo.data.map((doc :{
          documentInfoId: string,
          name: string,
          required: string,
        }) => ({
          name: doc.documentInfoId,
          title: doc.name,
          value: doc.required,
        })));
      }
    },
  });

  const addDocumentWrapper = () => {
    if (!addDocument) return;
    const docName = window.prompt('Please enter the name of the document');
    if (!docName) return;
    addDocument(docName)
      .then(() => refetch());
  };

  const deleteDocumentWrapper = (docName: string) => {
    if (!deleteDocument) return;
    deleteDocument(docName)
      .then(() => refetch());
  };

  const submitHandlerWrapper = () => {
    if (!submitHandler) return;
    submitHandler(documentList)
      .then(() => history.replace(`/pass-builder/${registrationId}/price-info`));
  };

  let form;
  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }
  return (
    <form
      className={form}
      onSubmit={(e:React.FormEvent) => {
        e.preventDefault();
        submitHandlerWrapper();
      }}
    >
      {
            editBtnLink
              ? (
                <div className={style.editBtn}>
                  <Link to={editBtnLink} className={style.link}>
                    <GenericButton
                      title="Edit"
                    />
                  </Link>

                </div>
              ) : null
          }
      <div className={style.documentsBox}>
        <div className={style.documentsTitleBox}>
          <h1 className={style.documentsTitle}>
            Step
            {' '}
            {stepNum || 5}
            : Upload Document
          </h1>

        </div>
        <DragAndDrop
          id="document-info-dnd"
          updateItem={updateItem}
          containers={[
            { title: 'Required Document', value: 'required' },
            { title: 'Optional Document', value: 'optional' },
          ]}
          deleteItem={deleteDocumentWrapper}
          items={documentList}
        />
        {' '}

      </div>
      {
        addDocument
          ? (
            <div className={style.addDocumentBtn}>
              <GenericButton
                title="add Document +"
                clickHandler={() => addDocumentWrapper()}
              />
            </div>
          ) : null
      }
      {' '}
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />

          </div>
        </div>
      ) : null}

    </form>
  );
}
DocumentsForm.defaultProps = {
  editBtnLink: undefined,
  addDocument: undefined,
  deleteDocument: undefined,
  submitHandler: undefined,
  showContinueBtn: false,
  stepNum: undefined,
};
