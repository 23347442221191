import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useEffect, useState } from 'react';

const GET_ALL_COMMUNITIES = gql`
query GetAllCommunities {
  getAllCommunities {
    success
    error
    data {
      communityId
      name
    }
  }
}
`;
type GetAllCommunitiesType = {
  getAllCommunities: {
    success: boolean,
    error: string,
    data?: Array<{
      communityId: string,
      name: string,
    }>,
  }
}

const GET_COMMUNITY = gql`
query GetCommunityById (
  $communityId: String!
) {
  getCommunityById (
    communityId: $communityId
  ) {
    success
    error
    data {
      communityId
      name
      registrationCode
      featuresConfig {
        passFeatures
        gateCode
        host
      }
    }
  }
}
`;
type GetCommunityType = {
  getCommunityById: {
    success: boolean,
    error: string,
    data?: Array<{
      communityId: string,
      name: string,
      registrationCode: string,
      featuresConfig: {
        passFeatures: boolean,
        gateCode: boolean,
        host: boolean,
      }
    }>,
  }
}

const INSERT_COMMUNITY = gql`
mutation InsertCommunity(
	$host: Boolean!, 
	$gateCode: Boolean!, 
	$passFeatures: Boolean!, 
	$communityName: String!, 
	$registrationCode: String!, 
	$communityId: String!
) {
  insertCommunity(host: $host
		gateCode: $gateCode
		passFeatures: $passFeatures
		communityName: $communityName
		registrationCode: $registrationCode
		communityId: $communityId
  ) {
    success
    error
  }
}
`;
type InsertCommunityType = {
  success: boolean,
  error: string,
}

const EDIT_COMMUNITY = gql`
mutation EditCommunity(
  $communityInfo: CommunityInput!, 
  $communityId: String!
) {
  editCommunity(
    communityInfo: $communityInfo, 
    communityId: $communityId
  ) {
    success
    error
  }
}
`;
type EditCommunityType = {
  success: boolean,
  error: string,
}

type CommunityInfo = {
  communityId: string,
  name: string,
  registrationCode: string,
  passFeatures: boolean,
  gateCode: boolean,
  host: boolean,
  [key: string]: string | boolean,
}
const newCommunityInfo = (data: any = {}) :CommunityInfo => ({
  communityId: data.communityId || '',
  name: data.name || '',
  registrationCode: data.registrationCode || '',
  passFeatures: data.passFeatures || data.featuresConfig?.passFeatures || false,
  gateCode: data.gateCode || data.featuresConfig?.gateCode || false,
  host: data.host || data.featuresConfig?.host || false,
});

export default function useAddCommunity() :{
  currentCommunity: CommunityInfo,
  setCurrentCommunityId: (communityId: string) => void,
  allCommunities: Array<Pick<CommunityInfo, 'communityId' | 'name' >> | undefined,
  editCommunity: (data: Partial<CommunityInfo>) => void,
  saveChanges: () => void,
  } {
  const [currentCommunity, setCurrentCommunity] = useState<CommunityInfo>(newCommunityInfo());
  // fetch all communities
  const {
    data: allCommunities,
    error: allCommunitiesError,
    loading: allCommunitiesLoading,
    refetch: allCommunitiesRefetch,
  } = useQuery<GetAllCommunitiesType>(GET_ALL_COMMUNITIES);

  // fetch individual community
  const [fetchCommunity] = useLazyQuery<GetCommunityType>(GET_COMMUNITY, {
    onCompleted: (d) => {
      if (d.getCommunityById.data?.length) {
        setCurrentCommunity(newCommunityInfo(d.getCommunityById.data[0]));
      }
    },
  });

  // logic to edit and add communities
  const [editCommunity] = useMutation(EDIT_COMMUNITY);
  const [addCommunity] = useMutation(INSERT_COMMUNITY);
  const saveChanges = () => {
    if (allCommunities?.getAllCommunities.data?.some(
      (c) => c.communityId === currentCommunity.communityId,
    )) {
      editCommunity({
        variables: {
          communityId: currentCommunity.communityId,
          communityInfo: Object.keys(currentCommunity)
            .filter((k) => !['communityId', 'host', 'passFeatures', 'gateCode'].includes(k))
            .reduce((res, k) => Object.assign(res, {
              [k]: currentCommunity[k],
            }), {}),
        },
      })
        .then(() => fetchCommunity({
          variables: {
            communityId: currentCommunity.communityId,
          },
        }));
    } else {
      addCommunity({
        variables: {
          ...currentCommunity,
          communityName: currentCommunity.name,
        },
      })
        .then(() => allCommunitiesRefetch());
    }
  };

  return {
    currentCommunity,
    setCurrentCommunityId: (communityId = '') => {
      if (communityId) {
        fetchCommunity({
          variables: {
            communityId,
          },
        });
      } else {
        setCurrentCommunity(newCommunityInfo());
      }
    },
    editCommunity: (data: Partial<CommunityInfo>) => setCurrentCommunity(newCommunityInfo({
      ...currentCommunity,
      ...data,
    })),
    allCommunities: allCommunities?.getAllCommunities.data,
    saveChanges,
  };
}
