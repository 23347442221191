import { gql, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { DragAndDropItem } from '../../../components/dragAndDrop/dragAndDrop';

const GET_EMPLOYEE_CONFIG = gql`
query GetEmployeeConfig(
  $passInfoId: String!
) {
  getEmployeeInfoConfig(
    passInfoId: $passInfoId
  ) {
    error
    success
    data {
      passInfoId
      isEmployeeInfoRequired
      firstName
      lastName
      email
      cellPhone
      jobTitle
      employeePicture
      driverLicenseImage
      driverLicenseScan
    }
  }
}
`;

const SET_EMPLOYEE_CONFIG = gql`
mutation SetEmployeeInfoConfig(
	$passInfoId: String!, 
	$newEmployeeInfoConfig: EmployeeInfoConfigInput!
) {
  setEmployeeInfoConfig(
    passInfoId: $passInfoId,
	  newEmployeeInfoConfig: $newEmployeeInfoConfig
  ) {
    success
    error
  }
}
`;

type options = 'required' | 'optional' | 'disabled';
export default function useEmployeeInfoForm(registrationId: string) :{
  requireEmployeeInfo: options,
  setRequireEmployeeInfo: (val:options) => void,
  employeeDocsList: Array<DragAndDropItem>,
  editEmployeeDocsList: (name: string, val: string) => void,
  employeeInfoList: Array<DragAndDropItem>,
  editEmployeeInfoList: (name: string, val: string) => void,
  submitHandler: () => Promise<any>,
  } {
  const [requireEmployeeInfo, setRequireEmployeeInfo] = useState<options>('required');
  const [employeeDocsList, setEmployeeDocsList] = useState<Array<DragAndDropItem>>([
    { title: 'Employee Picture', name: 'employeePicture', value: 'optional' },
    { title: 'Driver\'s License Image', name: 'driverLicenseImage', value: 'optional' },
    { title: 'Driver\'s License Scan', name: 'driverLicenseScan', value: 'optional' },
  ]);
  const [employeeInfoList, setEmployeeInfoList] = useState<Array<DragAndDropItem>>([
    {
      title: 'First Name', name: 'firstName', value: 'required', lock: true,
    },
    {
      title: 'Last Name', name: 'lastName', value: 'required', lock: true,
    },
    { title: 'Email', name: 'email', value: 'required' },
    { title: 'Phone', name: 'cellPhone', value: 'required' },
    { title: 'Job Title', name: 'jobTitle', value: 'optional' },
  ]);

  const editEmployeeInfoList = (name: string, val: string) => {
    const res = [...employeeInfoList];
    for (let i = 0; i < res.length; i += 1) {
      if (res[i].name === name) {
        res[i].value = val;
        break;
      }
    }
    setEmployeeInfoList(res);
  };
  const editEmployeeDocsList = (name: string, val: string) => {
    const res = [...employeeDocsList];
    for (let i = 0; i < res.length; i += 1) {
      if (res[i].name === name) {
        res[i].value = val;
        break;
      }
    }
    setEmployeeDocsList(res);
  };

  const { refetch } = useQuery<{
    getEmployeeInfoConfig: {
      error: string,
      success: boolean,
      data?: {
        isEmployeeInfoRequired: string,
        firstName: string,
        lastName: string,
        email: string,
        cellPhone: string,
        jobTitle: string,
        employeePicture: string,
        driverLicenseImage: string,
        driverLicenseScan: string,
        [key:string]: string,
      }
    }
  }>(GET_EMPLOYEE_CONFIG, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
    onCompleted: (d) => {
      if (d.getEmployeeInfoConfig.data) {
        setRequireEmployeeInfo(d.getEmployeeInfoConfig.data.isEmployeeInfoRequired as options);
        setEmployeeDocsList(employeeDocsList.map((doc) => ({
          ...doc,
          value: d.getEmployeeInfoConfig.data
            ? d.getEmployeeInfoConfig.data[doc.name]
            : doc.value,
        })));
        setEmployeeInfoList(employeeInfoList.map((doc) => ({
          ...doc,
          value: d.getEmployeeInfoConfig.data
            ? d.getEmployeeInfoConfig.data[doc.name]
            : doc.value,
        })));
      }
    },
  });

  const [doSetEmployeeInfo] = useMutation<{
    setEmployeeInfoConfig: {
      success: boolean,
      error: string,
    }
  }>(SET_EMPLOYEE_CONFIG);
  const submitHandler = async () => {
    const newEmployeeInfoConfig :{
      [key: string]: string,
    } = {
      isEmployeeInfoRequired: requireEmployeeInfo,
    };
    employeeDocsList.forEach((doc) => {
      newEmployeeInfoConfig[doc.name] = doc.value;
    });
    employeeInfoList.forEach((info) => {
      newEmployeeInfoConfig[info.name] = info.value;
    });
    return doSetEmployeeInfo({
      variables: {
        passInfoId: registrationId,
        newEmployeeInfoConfig,
      },
    })
      .then((d) => {
        if (!d.data?.setEmployeeInfoConfig.success) {
          refetch();
          throw Error(d.data?.setEmployeeInfoConfig.error);
        }
      })
      .catch((e) => {
        refetch();
        throw e;
      });
  };

  return {
    requireEmployeeInfo,
    setRequireEmployeeInfo,
    employeeDocsList,
    editEmployeeDocsList,
    employeeInfoList,
    editEmployeeInfoList,
    submitHandler,
  };
}
