import { ApolloError, gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router';
import style from './price.module.css';

const GET_PRICE_INFO = gql`
query GetPriceInfo (
  $passInfoId: String!
) {
  getPriceInfo (
    passInfoId: $passInfoId
  ) {
    success
    error
    data {
      isFree
      price
      allowAddons
      posOption
      creditCardFeeType
      creditCardFee
      allowAch
      allowCashOrCheck
      achFeeType
      achFee
      fees {
        name
        feeType
        amountType
        amount
      }
      addons {
        name
        description
        isFree
        price
        feesApply
      }
      chargePerDay
      chargeForFullCapacity
      discount
      discountDuration
      discountPrice
      discounts {
        quantity
        price
        discountType
        specialPrice
      }
    }
  }
}
`;

export type FeeType = {
  name: string,
  feeType: number,
  amountType: number,
  amount: number,
  [key: string]: any,
}
export type AddOnType = {
  name: string,
  description: string,
  isFree: boolean,
  price: number,
  feesApply: boolean,
  [key: string]: any,
}
export type BulkDiscount = {
  quantity: string,
  price: string,
  discountType: string,
  specialPrice: string,
  [key: string]: any,
}
export type DataType = {
  isFree: boolean,
  price: number,
  allowAddons: boolean,
  posOption: string,
  creditCardFeeType: 'percentage' | 'fixedAmount',
  creditCardFee: number,
  allowAch: boolean,
  allowCashOrCheck: boolean,
  achFeeType: number,
  achFee: number,
  allowFees?: boolean,
  fees: Array<FeeType>
  addons: Array<AddOnType>
  chargePerDay: number,
  discount: boolean,
  discountDuration: number,
  discountPrice: number,
  discounts: Array<BulkDiscount>,
  chargeForFullCapacity: boolean,
  [key: string]: any,
}
const makeDataType = (data:any = {}) :DataType => ({
  isFree: data.isFree || false,
  price: data.price || 0,
  allowAddons: data.allowAddons || false,
  posOption: data.posOption || '',
  creditCardFeeType: data.creditCardFeeType || 'percentage',
  creditCardFee: data.creditCardFee || 0,
  allowAch: data.allowAch || false,
  allowCashOrCheck: data.allowCashOrCheck || false,
  achFeeType: data.achFeeType || 0,
  achFee: data.achFee || 0,
  allowFees: data.allowFees || data.fees?.length > 0 || false,
  fees: data.fees?.map((f:any):FeeType => ({
    name: f.name || '',
    feeType: f.feeType || 0,
    amountType: f.amountType || 0,
    amount: f.amount || 0,
  })) || [],
  addons: data.addons?.map((a:any):AddOnType => ({
    name: a.name || '',
    description: a.description || '',
    isFree: a.isFree || false,
    price: a.price || 0,
    feesApply: a.feesApply || false,
  })) || [],
  chargePerDay: data.chargePerDay || 0,
  discount: data.discount || false,
  discountDuration: data.discountDuration || 0,
  discountPrice: data.discountPrice || 0,
  discounts: data.discounts?.map((a: any):BulkDiscount => ({
    quantity: a.quantity || 0,
    price: a.price || 0,
    discountType: a.discountType || '',
    specialPrice: a.specialPrice || 0,
  })) || [],
  chargeForFullCapacity: data.chargeForFullCapacity || false,
});

export type PriceFormProps = {
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  stepNum?: number,
  submitHandler?: (data:DataType) => Promise<any>
}

export default function usePriceInfoForm(props: PriceFormProps) :{
  data: DataType,
  editData: (key: keyof DataType, val: DataType[string]) => void,
  alert: string,
  submitHandlerWrapper: () => void,
  editAddon: (idx: number, key: keyof AddOnType, value: AddOnType[string]) => void,
  addAddon: () => void,
  deleteAddon: (idx: number) => void,
  editBulkDiscount: (idx: number, key: keyof BulkDiscount, value: BulkDiscount[string]) => void,
  addBulkDiscount: () => void,
  deleteBulkDiscount: (idx: number) => void,
  editFee: (idx: number, key: keyof FeeType, value: FeeType[string]) => void,
  addFee: () => void,
  deleteFee: (idx: number) => void,
  form: string,
} {
  const {
    registrationId, editBtnLink, submitHandler,
  } = props;
  const history = useHistory();

  const [alert, setAlert] = useState<string>('');
  const [data, setData] = useState<DataType>(makeDataType());
  const [chargePerDay, setChargePerDay] = useState<number>(0);

  const editData = (idx:keyof DataType, val: any) => {
    const res = { ...data };
    res[idx] = val;
    setData(res);
  };
  const editFee = (idx:number, key: keyof FeeType, val:FeeType[string]) => {
    const res = [...data.fees];
    res[idx][key] = val;
    editData('fees', res);
  };
  const deleteFee = (idx:number) => {
    editData('fees', data.fees.filter((_, i) => (i !== idx)));
  };
  const addFee = () => {
    editData('fees', [...data.fees, {
      name: '',
      feeType: 0,
      amountType: 0,
      amount: 0,
    }]);
  };
  const editAddon = (idx:number, key: keyof AddOnType, val:AddOnType[string]) => {
    const res = [...data.addons];
    res[idx][key] = val;
    editData('addons', res);
  };
  const deleteAddon = (idx:number) => {
    editData('addons', data.addons.filter((_, i) => (i !== idx)));
  };
  const addAddon = () => {
    editData('addons', [...data.addons, {
      name: '',
      description: '',
      isFree: true,
      price: 0,
      feesApply: false,
    }]);
  };

  const editBulkDiscount = (idx:number, key: keyof BulkDiscount, val:BulkDiscount[string]) => {
    const res = [...data.discounts];
    res[idx][key] = val;
    editData('discounts', res);
  };
  const deleteBulkDiscount = (idx:number) => {
    editData('discounts', data.discounts.filter((_, i) => (i !== idx)));
  };
  const addBulkDiscount = () => {
    editData('discounts', [...data.discounts, {
      quantity: 0,
      price: 0,
      discountType: '',
      specialPrice: 0,
    }]);
  };

  useQuery(GET_PRICE_INFO, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
    onCompleted: (d) => {
      if (d?.getPriceInfo?.data) {
        setData(makeDataType(d.getPriceInfo.data));
      }
    },
  });

  const submitHandlerWrapper = () => {
    if (submitHandler) {
      const res = { ...data };
      delete res.allowFees;
      // eslint-disable-next-line
      submitHandler(res)
        .then(() => history.replace(`/pass-builder/${registrationId}/pass-duration`))
        .catch((submitErr:ApolloError) => setAlert(submitErr.message));
    }
  };

  let form;
  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  return {
    data,
    editData,
    alert,
    submitHandlerWrapper,
    editAddon,
    addAddon,
    deleteAddon,
    editBulkDiscount,
    addBulkDiscount,
    deleteBulkDiscount,
    editFee,
    addFee,
    deleteFee,
    form,
  };
}
