import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import style from './card.module.css';

interface Props {
	img: any;
	alt?: string;
	title: string;
	description: string;
	buttonLabel: string;
	href: string;
}

export const Card = (props: Props) :ReactElement => {
  const {
    img, alt, title, description, buttonLabel, href,
  } = props;

  const body = (
    <div className={style.cardBox}>
      <div className={`${style.card} white `}>
        <img src={img} alt={alt} className={style.image} />
        <h4 className={`${style.title}  `}>{title}</h4>
        <p className={`${style.description} `}>{description}</p>
        <div className={style.btnBox}><button className={`${style.btn}  `}>{buttonLabel}</button></div>
      </div>
    </div>
  );

  if (href.startsWith('https')) {
    return (
      <a href={href}>
        {body}
      </a>
    );
  }
  return (
    <Link to={href}>
      {body}
    </Link>
  );
};
Card.defaultProps = {
  alt: 'card img',
};
export default Card;
