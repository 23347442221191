import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import { PopUp } from '../../../components/popUp/popUp';
import style from './renameGroup.module.css';

const RENAME_PASS_GROUP = gql`
  mutation renamePassGroup($name: String!, $passGroupId: String!) {
    renamePassGroup(name: $name, passGroupId: $passGroupId) {
      success
      error
    }
  }
`;

type IRenamePassGroupResponse = {
  renamePassGroup: {
    success: boolean;
    error: string;
  };
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function RenameGroup(): ReactElement {
  const history = useHistory();
  const { groupId: passGroupId } = useParams<{ groupId: string }>();
  const query = useQuery();
  const [alert, setAlert] = useState<string>('');
  const [newName, setNewName] = useState<string>(query?.get('name') || '');

  const [onRenamePassGroup] = useMutation<IRenamePassGroupResponse>(
    RENAME_PASS_GROUP,
    {
      onError: (e) => {
        setAlert(e.message);
      },
      onCompleted: (d) => {
        if (!d.renamePassGroup.success) {
          setAlert(d.renamePassGroup.error);
        } else {
          history.goBack();
        }
      },
    },
  );

  return (
    <PopUp title="" close={() => history.goBack()}>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          onRenamePassGroup({
            variables: {
              name: newName,
              passGroupId,
            },
          });
        }}
        className={style.form}
      >
        <GenericAlert color="red" title={alert} hidden={!alert} />
        <div className={style.innerBox}>
          <div className={style.nameInput}>
            <InputField
              labelTitle="Please enter the group name"
              htmlFor="group-name-inpt"
              inputValue={newName}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={style.createBtn}>
          <GenericButton title="Save Changes" type="submit" />
          {' '}
        </div>
      </form>
    </PopUp>
  );
}
