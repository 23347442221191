import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ADD_PASSES_TO_GROUP,
  ADD_PASSES_TO_GROUP_REQUEST,
  ADD_PASSES_TO_GROUP_RESPONSE,
  DELETE_GROUP,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_RESPONSE,
  DELETE_PASS,
  DELETE_PASS_REQUEST,
  DELETE_PASS_RESPONSE,
  GET_ALL_PASS,
  GET_ALL_PASS_REQUEST,
  GET_ALL_PASS_RESPONSE,
  GET_PASS_GROUP,
  GET_PASS_GROUP_REQUEST,
  GET_PASS_GROUP_RESPONSE,
  groupType,
  passType,
  REMOVE_PASS_FROM_GROUP,
  REMOVE_PASS_FROM_GROUP_REQUEST,
  REMOVE_PASS_FROM_GROUP_RESPONSE,
} from './manageGqls';

export interface ParamTypes {
  communityName: string
  portalName: string
}

export default function useManagePasses() :{
  communityId: string,
  portalName: string,
  showOrder: boolean,
  setShowOrder: (val: boolean) => void,
  showOrderDetails: boolean,
  setShowOrderDetails: (val: boolean) => void,
  groups: groupType[],
  passMoveModeOn: boolean,
  activeGroup: string,
  setActiveGroup: (val: string) => void,
  activeGroupData: groupType | null,
  handleGroupDelete: (groupId: string) => void,
  // passList: passType[],
  handlePassToGroupRemoval: (passInfoId: string) => void,
  togglePassMoveMode: (val?: boolean) => void,
  handleAddPassConfirm: () => void,
  allPassData: passType[],
  handleSelectedPassForGroup: (e:React.ChangeEvent<HTMLInputElement>, pass: passType) => void,
  handlePassRemoval: (passId: string) => void,
  } {
  // info from url
  const { communityName: communityId, portalName } = useParams<ParamTypes>();
  // passGroupId of active group
  const [activeGroup, setActiveGroup] = useState<string>('');
  // // array of selected passes
  // const [passList, setpassList] = useState<passType[]>([]);

  /* toggles for display */
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [showOrderDetails, setShowOrderDetails] = useState<boolean>(false);
  const [passMoveModeOn, setpassMoveModeOn] = useState<boolean>(false);
  // passes that are selected to be asigned to a group
  const [selectedPassToMove, setselectedPassToMove] = useState<string[]>([]);
  // query for all complete passes by community
  const { data: allPassData, refetch: getAllPassData } = useQuery<
    GET_ALL_PASS_RESPONSE,
    GET_ALL_PASS_REQUEST
  >(GET_ALL_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
  });

  // query for all groups for speciffic community
  const { data: groupData, refetch: getGroup } = useQuery<
  GET_PASS_GROUP_RESPONSE,
  GET_PASS_GROUP_REQUEST
  >(GET_PASS_GROUP, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
    },
  });

  // memoize info for active group
  const activeGroupData = useMemo(
    () => groupData?.getPassGroups.data?.find((g) => g.passGroupId === activeGroup) || null,
    [groupData, activeGroup],
  );

  // =============== ADD PASS TO GROUP ===============
  const [addPassesToGroup] = useMutation<
    ADD_PASSES_TO_GROUP_RESPONSE,
    ADD_PASSES_TO_GROUP_REQUEST
  >(ADD_PASSES_TO_GROUP, {
    onError: () => {
      // console.log(e);
    },
    onCompleted: () => {
      getGroup();
    },
  });
  // helper function
  const handleAddPassConfirm = useCallback(() => {
    if (activeGroup) {
      addPassesToGroup({
        variables: {
          passInfoIds: selectedPassToMove,
          passGroupId: activeGroup,
        },
      })
        .then(() => {
          setselectedPassToMove([]);
          setpassMoveModeOn(false);
        });
    }
  }, [addPassesToGroup, activeGroup, selectedPassToMove]);
  // helper to go into add pass to group mode
  const togglePassMoveMode = (val?: boolean) => {
    if (activeGroup) {
      setpassMoveModeOn(val !== undefined ? val : !passMoveModeOn);
    } else {
      window.alert('please select a group first'); // eslint-disable-line
    }
  };
  // add pass to selectedPassToMove when checkbox is checked
  const handleSelectedPassForGroup = (e:React.ChangeEvent<HTMLInputElement>, pass: passType) => {
    if (e.target.checked) {
      setselectedPassToMove([...selectedPassToMove, pass.passInfoId]);
    } else {
      const bufferList = selectedPassToMove;
      const index = bufferList.indexOf(pass.passInfoId);
      if (index > -1) {
        bufferList.splice(index, 1);
      }
      setselectedPassToMove([...bufferList]);
    }
  };

  // =============== REMOVE PASS FROM GROUP ===============
  const [removePassToGroup] = useMutation<
    REMOVE_PASS_FROM_GROUP_RESPONSE,
    REMOVE_PASS_FROM_GROUP_REQUEST
  >(REMOVE_PASS_FROM_GROUP, {
    onError: () => {
      // console.log(e);
    },
    onCompleted: () => {
      getGroup();
    },
  });
  // helper function
  const handlePassToGroupRemoval = useCallback((passInfoId: string) => {
    if (activeGroupData?.passGroupId) {
      removePassToGroup({
        variables: {
          passGroupId: activeGroupData?.passGroupId,
          passInfoId,
        },
      });
    }
  }, [removePassToGroup, activeGroupData]);

  // =============== DELETE PASS ===============
  const [removePass] = useMutation<
    DELETE_PASS_RESPONSE,
    DELETE_PASS_REQUEST
  >(DELETE_PASS, {
    onError: () => {
      // console.log(e);
    },
    onCompleted: () => {
      getAllPassData();
    },
  });
  // helper to delete pass
  const handlePassRemoval = useCallback((passInfoId: string) => {
    removePass({
      variables: {
        passInfoId,
      },
    });
  }, [removePass]);

  // =============== DELETE GROUP ===============
  const [deleteGroup] = useMutation<
    DELETE_GROUP_RESPONSE,
    DELETE_GROUP_REQUEST
  >(DELETE_GROUP, {
    onError: () => {
      // console.log(e);
    },
    onCompleted: () => {
      getGroup();
    },
  });
  // helper to delete group
  const handleGroupDelete = useCallback((groupId: string) => {
    deleteGroup({
      variables: {
        passGroupId: groupId,
      },
    });
    if (activeGroup === groupId) {
      setActiveGroup('');
    }
  }, [deleteGroup, activeGroup]);

  // const finalPassData = async (promisses: any) => {
  //   const finalPassesDataa = await Promise.all(promisses);
  //   return finalPassesDataa;
  // };

  // const getPass = async (id: string) => {
  //   const { data: passInfoData } = await backendClient.query({
  //     query: GET_PASS_INFO,
  //     variables: {
  //       passInfoId: id,
  //     },
  //   });
  //   return passInfoData;
  // };

  // const getPassGroup = (data: any) => {
  //   if (!data) {
  //     return;
  //   }
  //   if (!data.getPassGroups) {
  //     return;
  //   }
  //   if (!data.getPassGroups.success) {
  //     return;
  //   }
  //   setgroups(data.getPassGroups.data);
  //   const allPases = [];
  //   for (let i = 0; i < data.getPassGroups.data.length; i += 1) {
  //     allPases.push(...data.getPassGroups.data[i].passInfoList.map((id: string) => getPass(id)));
  //   }
  //   finalPassData(allPases).then((res) => {
  //     setallPassList(res);
  //   });
  // };

  // delete pass mutation
  // const [deletePass] = useMutation(DELETE_PASS, {
  //   onError: () => {
  //     // console.log(e);
  //   },
  //   onCompleted: () => {
  //     // getGroup();
  //   },
  // });
  // delete pass helper
  // const handlePassDelete = (passId: string) => {
  //   deletePass({
  //     variables: {
  //       passInfoId: passId,
  //     },
  //   });
  //   getGroup();
  //   getAllPassData();
  // };

  // const handlePassToGroupAddition = (passGroupId: string, passInfoId: string) => {
  //   addPassesToGroup({
  //     variables: {
  //       passGroupId,
  //       passInfoId,
  //     },
  //   });
  // };

  // const getPassToGroupPromise = async (passInfoId: string, passGroupId: string) => {
  //   await addPassesToGroup({
  //     variables: {
  //       passInfoId,
  //       passGroupId,
  //     },
  //   });
  // };

  // useEffect(() => {
  //   if (communityId) {
  //     getGroup();
  //     getAllPassData();
  //   }
  //   if (activeGroupData && activeGroupData.passInfoList) {
  //     const allPasses = activeGroupData.passInfoList.map((id: string) => getPass(id));
  //     finalPassData(allPasses).then((res) => {
  //       setpassList([...res]);
  //     });
  //   }
  // }, [communityId, activeGroupData]);

  // useEffect(() => {
  //   if (data && data.getPassGroups && data.getPassGroups.success) {
  //     setgroups(data.getPassGroups.data);
  //     const allPases = [];
  //     for (let i = 0; i < data.getPassGroups.data.length; i += 1) {
  //       allPases.push(...data.getPassGroups.data[i].passInfoList
  // .map((id: string) => getPass(id)));
  //     }
  //     finalPassData(allPases).then((res) => {
  //       setallPassList(() => res);
  //     });
  //     data.getPassGroups.data.forEach((i: any) => {
  //       if (i.name === activeGroup) {
  //         setactiveGroupData(i);
  //       }
  //     });
  //   }
  // }, [data]);

  return {
    communityId,
    portalName,
    showOrder,
    setShowOrder,
    showOrderDetails,
    setShowOrderDetails,
    groups: groupData?.getPassGroups.data || [],
    passMoveModeOn,
    activeGroup,
    setActiveGroup,
    activeGroupData,
    handleGroupDelete,
    // passList,
    handlePassToGroupRemoval,
    togglePassMoveMode,
    handleAddPassConfirm,
    allPassData: allPassData?.getPassInfosByCommunity.data || [],
    handleSelectedPassForGroup,
    handlePassRemoval,
  };
}
