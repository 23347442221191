import React, { ReactElement, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
// import LoginBackground from '../../../components/loginBackground/loginBackground';
import style from './forgotPasswordVerification.module.css';
// import SchemeDecider from '../../../common_lib_front/utilities/schemeDecider';
import ForgetPWPagesBackground from '../forgetPWPagesBackground/forgetPWPagesBackground';
// import { RESET_PASSWORD_EMAIL_QUERY } from '../../../common_lib_front/utilities/BackendAPI';
//
interface Props {
  location?: any;
}

// const colorStyle = SchemeDecider();
export function ForgotPasswordVerification(props:Props) :ReactElement {
  const { location } = props;
  const [code, setCode] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');
  // console.log(props);
  const [allert, setAllert] = useState<string>('');

  // const [doResend] = useLazyQuery(RESET_PASSWORD_EMAIL_QUERY, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (d) => {
  //     if (d.sendResetCode.success) {
  //       setAllert('Verification email has been sent');
  //     }
  //   },
  // });

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }
  return (
    <ForgetPWPagesBackground>
      <div>
        <p className={`${style.allert} textColorAlert `}>{allert}</p>
        <h3 className={`${style.box__mainTitleRight}  `}>Forgot Password?</h3>
        <h5 className={`${style.box__subTitle}  `}>Please enter the verification code</h5>
        <form
          onSubmit={(e:React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setRedirect(`/forgot-password/reset-password/${code || 'abc'}`);
          }}
        >
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                htmlFor="code-inpt"
                labelTitle="Verification Code"
                inputType="text"
                inputValue={code}
                changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </div>

          <div className={style.box__btnRight}>
            <div className={style.emailBox}>
              <GenericButton
                outline="no-border"
                clickHandler={() => {
                  if (location?.state?.email) {
                  // doResend({
                  //   variables: { email: props.location.state.email },
                  // });
                  } else {
                    setRedirect('/forgot-password');
                  }
                }}
                title="Did not recieve an email?"
              />

            </div>
            <div className={style.box__btnRightInnerBox}>
              <GenericButton
                size="medium"
                color="blue"
                title="Enter"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </ForgetPWPagesBackground>
  );
}
ForgotPasswordVerification.defaultProps = {
  location: undefined,
};
export default withRouter(ForgotPasswordVerification);
