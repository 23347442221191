import { gql, useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import style from './communitySelect.module.css';

type Props = {
  id: string,
  value?: string,
  changeHandler?: (val: string) => void,
}

const GET_ALL_COMMUNITIES = gql`
query GetAllCommunities {
  getAllCommunities {
    success
    error
    data {
      communityId
      name
    }
  }
}
`;

export default function CommunitySelect(props: Props) :ReactElement {
  const {
    id, value, changeHandler,
  } = props;

  const { data, loading, error } = useQuery<{
    getAllCommunities: {
      error?: string,
      loading?: boolean,
      data?: [{
        communityId: string,
        name: string,
      }]
    }
  }>(GET_ALL_COMMUNITIES);

  return (
    <label htmlFor={`${id}-community-select`} className={style.selectLabel}>
      Select a community
      <select
        id={`${id}-community-select`}
        value={value}
        onChange={(e:React.ChangeEvent<HTMLSelectElement>) => {
          if (changeHandler) {
            changeHandler(e.target.value);
          }
        }}
        className={style.select}
      >
        <option hidden>
          {(() :string => {
            if (error || data?.getAllCommunities?.error) {
              return 'Something went wrong ...';
            }
            if (loading) {
              return 'Loading ...';
            }
            return '';
          })()}
        </option>
        {data && data.getAllCommunities?.data?.map((community) => (
          <option
            key={community.communityId}
            value={community.communityId}
          >
            {community.name}
          </option>
        ))}
      </select>
    </label>
  );
}
CommunitySelect.defaultProps = {
  value: '',
  changeHandler: undefined,
};
