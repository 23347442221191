import React, { ReactElement } from 'react';
import style from './checkBoxArea.module.css';

type Props = {
  title?: string,
  value?: boolean,
  clickHandler?: (val:boolean) => void,
  isSubCheckBox?: boolean,
  hidden?: boolean,
  checkDisabled?: boolean,
}

export default function CheckBoxArea(props: Props) :ReactElement {
  const {
    title, value, clickHandler, isSubCheckBox, hidden, checkDisabled,
  } = props;

  if (isSubCheckBox) {
    // TODO : set css for smaller grey boxes (like
    //    the country, city, state, and zip options on dispalyed info page)
  }

  if (hidden) {
    return (
      <></>
    );
  }
  return (
    <div
      onClickCapture={(e:React.MouseEvent<HTMLDivElement>) => {
        if (clickHandler) {
          clickHandler(!value);
        }
      }}
      className={`${isSubCheckBox ? style.displayItemSubBox : style.displayItemBox}`}
    >
      {title}
      <input
        type="checkbox"
        checked={value}
        className={style.checkBox}
        disabled={checkDisabled}
      />
    </div>
  );
}
CheckBoxArea.defaultProps = {
  title: '',
  value: false,
  clickHandler: undefined,
  isSubCheckBox: false,
  hidden: false,
  checkDisabled: false,
};
