import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import CheckBoxArea from '../../../components/checkBoxArea/checkBoxArea';
import PassPreview from '../../popups/passPreview/passPreview';
import { checkBoxes } from './display';
import style from './display.module.css';
import { gqlToObj, objToGql } from './displayUtils';

type Props = {
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  stepNum?: number,
  editOff: boolean
}

const EDIT_DISPLAY_INFO = gql`
mutation Mutation(
	$newDisplayInfo: DisplayInfoInput!,
	$passInfoId: String!
) {
  setDisplayInfo(
    newDisplayInfo: $newDisplayInfo
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

const GET_INFORMATION = gql`
query getDisplayInfo(
  $passInfoId: String!
){
  getDisplayInfo(
    passInfoId: $passInfoId
  ){
    success
    error
    data {
      firstName
      lastName
      cellPhoneNumber
      email
      emergencyContactNumber
      businessName
      businessPhoneNumber
      make
      vehicleModel
      type
      color
      licensePlate
      fleetNumber
      licensePlateState
      primaryDriverName
      licensePrimaryDriver
      qrCode
      rentalAddress
      homeAddress{
        country
        city
        state
        zipCode
      }
      destination
      businessAddress{
        country
        city
        state
        zipCode
      }
    }
  }
}
`;

export default function DisplayForm(props: Props): React.ReactElement {
  const {
    registrationId,
    editBtnLink,
    showContinueBtn,
    stepNum,
    editOff,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();

  let form;

  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  const [boxes, setBoxes] = useState<checkBoxes>({
    firstName: false,
    lastName: false,
    cellPhoneNumber: false,
    email: false,
    emergencyContactNumber: false,
    businessName: false,
    businessAddress: false,
    country: false,
    city: false,
    state: false,
    zipCode: false,
    businessPhoneNumber: false,
    make: false,
    vehicleModel: false,
    type: false,
    color: false,
    licensePlate: false,
    fleetNumber: false,
    licensePlateState: false,
    primaryDriverName: false,
    licensePrimaryDriver: false,
    qrCode: false,
    homeAddress: false,
    homeAddressCountry: false,
    homeAddressCity: false,
    homeAddressState: false,
    homeAddressZipCode: false,
    rentalAddress: false,
    destination: false,
  });

  const setBox = (key: keyof checkBoxes, val: boolean) => {
    const res = { ...boxes };
    res[key] = val;
    setBoxes(res);
  };

  const [doEdit, { error: editError }] = useMutation(EDIT_DISPLAY_INFO);
  const { data, loading, error } = useQuery<{
    getDisplayInfo: {
      data: any, error: string, success: boolean,
    }
  }>(GET_INFORMATION, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
  });

  useEffect(() => {
    if (data?.getDisplayInfo.data) {
      const obj = gqlToObj(data?.getDisplayInfo.data);
      setBoxes({ ...boxes, ...obj });
    }
  }, [data]);

  const submitHandler = () => {
    const finalData = objToGql(boxes);
    doEdit({
      variables: {
        newDisplayInfo: finalData,
        passInfoId: registrationId,
      },
    });
    history.push(`/pass-builder/${registrationId}/review`);
  };

  return (
    <form
      className={form}
      onSubmit={(e) => {
        e.preventDefault();
        if (submitHandler) {
          submitHandler();
        }
      }}
    >
      <div className={style.preview}>
        <PassPreview
          displayParams={boxes}
          close={() => setIsOpen(false)}
          isOpen={isOpen}
        />

      </div>
      {

        editBtnLink
          ? (
            <div className={style.editBtn}>
              <Link to={editBtnLink} className={style.link}>
                <GenericButton
                  title="Edit"
                />
              </Link>
            </div>
          ) : null
      }
      <div className={style.displayBox}>
        <div className={style.displayTitleBox}>
          <h4 className={style.displayTitle}>
            Step
            {' '}
            {stepNum || 9}
            : Select Info Displayed On the Pass
          </h4>
          <button
            className={style.reviewBtn}
            onClick={() => setIsOpen(true)}
            type="button"
          >
            Preview
          </button>
        </div>
        <div className={style.colBox}>
          <div className={style.col}>
            <div className={`${style.titleBox} ${style.subCheckBoxs}`}>
              Vendor Information
            </div>
            <CheckBoxArea
              checkDisabled
              isSubCheckBox
              title="Arrival Date"
              value
              // clickHandler={(val: boolean) => setBox('arrivalDate', val)}
            />
            <CheckBoxArea
              checkDisabled
              isSubCheckBox
              title="Departure Date"
              value
              // clickHandler={(val: boolean) => setBox('arrivalDate', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="First Name"
              value={boxes.firstName}
              clickHandler={(val: boolean) => setBox('firstName', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Last Name"
              value={boxes.lastName}
              clickHandler={(val: boolean) => setBox('lastName', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Cell Phone Number"
              value={boxes.cellPhoneNumber}
              clickHandler={(val: boolean) => setBox('cellPhoneNumber', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Email Address"
              value={boxes.email}
              clickHandler={(val: boolean) => setBox('email', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Emergency Contact Number"
              value={boxes.emergencyContactNumber}
              clickHandler={(val: boolean) => setBox('emergencyContactNumber', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Business Name (Vendor Only)"
              value={boxes.businessName}
              clickHandler={(val: boolean) => setBox('businessName', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Business Address (Including Country, City, State, Zip Code)"
              value={boxes.businessAddress}
              clickHandler={(val: boolean) => setBox('businessAddress', val)}
            />
            <div className={style.subCheckBoxs}>
              <CheckBoxArea
                checkDisabled={editOff}
                title="Country"
                value={boxes.country}
                clickHandler={(val: boolean) => setBox('country', val)}
                isSubCheckBox
                hidden={!boxes.businessAddress}
              />
              <CheckBoxArea
                checkDisabled={editOff}
                title="City"
                value={boxes.city}
                clickHandler={(val: boolean) => setBox('city', val)}
                isSubCheckBox
                hidden={!boxes.businessAddress}
              />
              <CheckBoxArea
                checkDisabled={editOff}
                title="State"
                value={boxes.state}
                clickHandler={(val: boolean) => setBox('state', val)}
                isSubCheckBox
                hidden={!boxes.businessAddress}
              />
              <CheckBoxArea
                checkDisabled={editOff}
                title="Zip"
                value={boxes.zipCode}
                clickHandler={(val: boolean) => setBox('zipCode', val)}
                isSubCheckBox
                hidden={!boxes.businessAddress}
              />

            </div>
            <CheckBoxArea
              checkDisabled={editOff}
              title="Business Phone Number"
              value={boxes.businessPhoneNumber}
              clickHandler={(val: boolean) => setBox('businessPhoneNumber', val)}
            />

          </div>
          <div className={style.col}>
            <div className={style.titleBox}>
              Vehicle Information
            </div>
            <CheckBoxArea
              checkDisabled={editOff}
              title="Vehicle Make"
              value={boxes.make}
              clickHandler={(val: boolean) => setBox('make', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Vehicle Model"
              value={boxes.vehicleModel}
              clickHandler={(val: boolean) => setBox('vehicleModel', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Vehicle Type"
              value={boxes.type}
              clickHandler={(val: boolean) => setBox('type', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Vehicle Color"
              value={boxes.color}
              clickHandler={(val: boolean) => setBox('color', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="License Plate"
              value={boxes.licensePlate}
              clickHandler={(val: boolean) => setBox('licensePlate', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="License plate state"
              value={boxes.licensePlateState}
              clickHandler={(val: boolean) => setBox('licensePlateState', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Primary Driver Name"
              value={boxes.primaryDriverName}
              clickHandler={(val: boolean) => setBox('primaryDriverName', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Primary Driver’s License #"
              value={boxes.licensePrimaryDriver}
              clickHandler={(val: boolean) => setBox('licensePrimaryDriver', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Vehicle Fleet Number"
              value={boxes.fleetNumber}
              clickHandler={(val: boolean) => setBox('fleetNumber', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Destination"
              value={boxes.destination}
              clickHandler={(val: boolean) => setBox('destination', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Rental Address"
              value={boxes.rentalAddress}
              clickHandler={(val: boolean) => setBox('rentalAddress', val)}
            />
            <CheckBoxArea
              checkDisabled={editOff}
              title="Home Address "
              value={boxes.homeAddress}
              clickHandler={(val: boolean) => setBox('homeAddress', val)}
            />
            <div className={style.subCheckBoxs}>
              <CheckBoxArea
                checkDisabled={editOff}
                title="Country"
                value={boxes.homeAddressCountry}
                clickHandler={(val: boolean) => setBox('homeAddressCountry', val)}
                isSubCheckBox
                hidden={!boxes.homeAddress}
              />
              <CheckBoxArea
                checkDisabled={editOff}
                title="City"
                value={boxes.homeAddressCity}
                clickHandler={(val: boolean) => setBox('homeAddressCity', val)}
                isSubCheckBox
                hidden={!boxes.homeAddress}
              />
              <CheckBoxArea
                checkDisabled={editOff}
                title="State"
                value={boxes.homeAddressState}
                clickHandler={(val: boolean) => setBox('homeAddressState', val)}
                isSubCheckBox
                hidden={!boxes.homeAddress}
              />
              <CheckBoxArea
                checkDisabled={editOff}
                title="Zip"
                value={boxes.homeAddressZipCode}
                clickHandler={(val: boolean) => setBox('homeAddressZipCode', val)}
                isSubCheckBox
                hidden={!boxes.homeAddress}
              />
            </div>
            <div className={style.titleBox}>
              QR Code
            </div>
            <CheckBoxArea
              checkDisabled={editOff}
              title="QR Code"
              value={boxes.qrCode}
              clickHandler={(val: boolean) => setBox('qrCode', val)}
            />

          </div>

        </div>

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />
          </div>
        </div>
      ) : null}
    </form>
  );
}
DisplayForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  stepNum: undefined,
};
