import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import DragAndDrop, { DragAndDropItem } from '../../../components/dragAndDrop/dragAndDrop';
import style from './vehicleInfo.module.css';

const GET_VEHICLE_INFO = gql`
query GetVehicleInfoConfig (
  $passInfoId: String!
) {
  getVehicleInfoConfig (
    passInfoId: $passInfoId
  ) {
    success
    error
    data {
      passInfoId
      make
      vehicleModel
      type
      color
      licensePlate
      fleetNumber
      licensePlateState
      primaryDriverName
      licensePrimaryDriver
      employeeList
      employeeSelectList
      vehicleSelect
      driverSelect
      name
      completeLater
      destination
      year
    }
  }
}
`;

type Props = {
    registrationId: string,
    editBtnLink?: string,
    showContinueBtn?: boolean,
    stepNum?: number,
    submitHandler?: (data: Array<DragAndDropItem>) => void,
}

export default function VehicleInfoForm(props: Props) :ReactElement {
  const {
    registrationId, editBtnLink, showContinueBtn, stepNum, submitHandler,
  } = props;
  const [itemList, setItemList] = useState<Array<DragAndDropItem>>([
    { title: 'Vehicle Make', name: 'make', value: 'optional' },
    { title: 'Vehicle Model', name: 'vehicleModel', value: 'optional' },
    { title: 'Vehicle Type', name: 'type', value: 'optional' },
    { title: 'Vehicle Color', name: 'color', value: 'optional' },
    { title: 'Vehicle Plate', name: 'licensePlate', value: 'optional' },
    { title: 'Vehicle Fleet Number', name: 'fleetNumber', value: 'optional' },
    { title: 'License plate state', name: 'licensePlateState', value: 'optional' },
    { title: 'Primary Driver Name', name: 'primaryDriverName', value: 'optional' },
    { title: 'Primary Driver’s License #', name: 'licensePrimaryDriver', value: 'optional' },
    { title: 'Employee List', name: 'employeeList', value: 'optional' },
    { title: 'Employee Select List', name: 'employeeSelectList', value: 'optional' },
    { title: 'Vehicle Select', name: 'vehicleSelect', value: 'optional' },
    { title: 'Driver Select', name: 'driverSelect', value: 'optional' },
    { title: 'Guest Name', name: 'name', value: 'optional' },
    { title: 'Complete Later (Rental Car)', name: 'completeLater', value: 'optional' },
    { title: 'Destination', name: 'destination', value: 'optional' },
    { title: 'Vehicle Year', name: 'year', value: 'optional' },
  ]);
  const updateItem = (name:string, value:string) => {
    const res = [...itemList];
    for (let i = 0; i < res.length; i += 1) {
      if (res[i].name === name) {
        res[i].value = value;
        break;
      }
    }
    setItemList(res);
  };

  useQuery<{ getVehicleInfoConfig: {
    success: boolean,
    error: string,
    data: {
      passInfoId: string,
      make: string,
      model: string,
      type: string,
      color: string,
      licensePlate: string,
      fleetNumber: string,
      year: string,
    }
  } }>(GET_VEHICLE_INFO, {
    fetchPolicy: 'network-only',
    variables: { passInfoId: registrationId },
    onError: (e) => console.log(e.message),
    onCompleted: (d) => {
      if (d.getVehicleInfoConfig?.success) {
        Object.entries(d.getVehicleInfoConfig.data || {})
          .forEach(([key, val]) => {
            console.log(key, val);
            updateItem(key, val ?? 'disabled');
          });
      }
    },
  });

  let form;
  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  return (
    <form
      className={form}
      onSubmit={(e:React.FormEvent) => {
        e.preventDefault();
        if (submitHandler) {
          submitHandler(itemList);
        }
      }}
    >
      {
            editBtnLink
              ? (
                <div className={style.editBtn}>
                  <Link to={editBtnLink} className={style.link}>
                    <GenericButton
                      title="Edit"
                    />
                  </Link>

                </div>
              ) : null
          }
      <div className={style.vehicleInfoBox}>
        <div className={style.vehicleInfoTitleBox}>

          <h1 className={style.vehicleInfoTitle}>
            Step
            {' '}
            {stepNum || 3}
            : Vehicle Information
          </h1>

        </div>
        <DragAndDrop
          id="user-info-dnd"
          containers={[
            { title: 'Required', value: 'required' },
            { title: 'Optional', value: 'optional' },
            { title: 'Disabled', value: 'disabled' },
          ]}
          items={itemList}
          updateItem={updateItem}
        />

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              type="submit"
              title="Continue"
            />

          </div>
        </div>
      ) : null}

    </form>
  );
}
VehicleInfoForm.defaultProps = {
  editBtnLink: '',
  showContinueBtn: false,
  stepNum: undefined,
  submitHandler: undefined,
};
