import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import DisplayForm from '../display/displayForm';
import DocumentsForm from '../documents/documentsForm';
import DurationForm from '../duration/durationForm';
import EducationForm from '../education/educationForm';
import InstructionsForm from '../instructions/instructionsForm';
import PassInfoForm from '../passInfo/passInfoForm';
import PriceInfoForm from '../price/priceForm';
import UserInfoForm from '../userInfo/userInfoForm';
import VehicleInfoForm from '../vehicleInfo/vehicleInfoForm';
import style from './review.module.css';
import EmployeeInfoForm from '../employeeInfo/employeeInfoForm';

const EDIT_PASS = gql`
mutation Mutation(
  $newPassInfo: PassInfoInput!, 
  $passInfoId: String!
) {
  setPassInfo(
    newPassInfo: $newPassInfo, 
    passInfoId: $passInfoId
  ) {
    success
    error
  }
}
`;

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

export default function Review(props: Props): ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  const history = useHistory();

  const [doEdit] = useMutation(EDIT_PASS);
  const submitHandler = () => {
    doEdit({
      variables: {
        passInfoId: registrationId,
        newPassInfo: { complete: true },
      },
    });
    history.push(`/pass-builder/${communityName}/${portalName}/manage-passes`);
  };

  return (
    <div>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        showReviewPass
        communityName={communityName}
        portalName={portalName}
      />
      <div className={style.box}>
        <PassInfoForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/pass-info`}
          stepNum={1}
        />
      </div>
      { portalName?.toLowerCase() !== 'vendor'
        ? (
          <div className={style.box}>
            <UserInfoForm
              portalName={portalName || 'guest'}
              registrationId={registrationId}
              editBtnLink={`/pass-builder/${registrationId}/user-info`}
              stepNum={2}
            />
          </div>
        ) : null}
      { portalName?.toLowerCase() !== 'guest'
        ? (
          <div className={style.box}>
            <EmployeeInfoForm
              registrationId={registrationId}
              editBtnLink={`/pass-builder/${registrationId}/employee-info`}
              stepNum={2}
            />
          </div>
        ) : null}
      <div className={style.box}>
        <VehicleInfoForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/vehicle-info`}
          stepNum={portalName?.toLowerCase() !== 'vendor' ? 4 : 3}
        />
      </div>
      {/* <div className={style.box}>
        <EducationForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/education-info`}
          stepNum={4}
          communityId={communityName || ''}
        />
      </div> */}
      {/* <div className={style.box}>
        <DocumentsForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/document-info`}
          stepNum={5}
        />
      </div> */}
      <div className={style.box}>
        <PriceInfoForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/price-info`}
          stepNum={portalName?.toLowerCase() !== 'vendor' ? 5 : 4}
        />
      </div>
      <div className={style.box}>
        <DurationForm
          // data={{
          //   option: '1',
          // }}
          // editData={() => null}
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/pass-duration`}
          stepNum={portalName?.toLowerCase() !== 'vendor' ? 6 : 5}
        />
      </div>
      <div className={style.box}>
        <InstructionsForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/instructions`}
          stepNum={portalName?.toLowerCase() !== 'vendor' ? 7 : 6}
        />
      </div>
      <div className={style.box}>
        <DisplayForm
          registrationId={registrationId}
          editBtnLink={`/pass-builder/${registrationId}/display-info`}
          stepNum={portalName?.toLowerCase() !== 'vendor' ? 8 : 7}
          editOff
        />
      </div>
      <div className={style.createBox}>
        <span className={style.invisiableBox} />
        <span className={style.warning}>
          Warning: Pass must be activated from the Pass List Page after completion

        </span>
        <span className={style.btn}>
          <GenericButton
            clickHandler={submitHandler}
            title="Create Pass"
          />
        </span>
      </div>
    </div>
  );
}
Review.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
