import React, { ReactElement, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import style from './login.module.css';
import store, { isLogedIn } from '../../../common_lib_front/utilities/store';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import logo from '../../../assets/symlivWhite.png';

export const LOGIN_QUERY = gql`
  query LoginUser(
    $password: String!,
    $email: String!,
  ) {
    loginUser(
      password: $password,
      email: $email
    ) {
      success
      error
      token
      data {
        firstName
        lastName
        email
        roles
      }
    }
  }
`;

interface Props {
  disableCreation?: boolean,
}

export default function Login(props: Props) :ReactElement {
  const {
    disableCreation,
  } = props;
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  if (isLogedIn()) {
    store.dispatch({ type: 'user/logout' });
  }

  const [doLogin] = useLazyQuery(LOGIN_QUERY, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (d.loginUser.success) {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: d.loginUser.token,
            user: d.loginUser.data,
          },
        });
        history.push('/');
      } else {
        setAlert(d.loginUser.error);
        setAlertColor('red');
      }
    },
  });

  const loginHandler = () => {
    doLogin({
      variables: {
        password,
        email,
      },
    });
  };

  return (
    <div className={style.container}>
      <div className={`${style.box}  `}>
        <div className={`${style.box__left}  `}>
          <img src={logo} alt="" className={style.box__img} />
          {
            disableCreation
              ? null : (
                <>
                  <h3 className={`${style.box__mainTitleLeft} `}> Dont have an account?</h3>
                  <Link to="/create-account">
                    <div className={style.box__btnLeft}>
                      <div>
                        <GenericButton
                          size="medium"
                          color="white"
                          title="Create Account"
                        />

                      </div>
                    </div>
                  </Link>
                </>
              )
          }
        </div>
        <form
          className={style.box__right}
          onSubmit={(e:React.FormEvent) => {
            e.preventDefault();
            loginHandler();
          }}
        >

          <div className={style.box__titleBoxRight}>
            <h3 className={`${style.box__mainTitleRight}  `}>
              Welcome to the Pass Builder Master System
              {/* Welcome to Community
              {` ${role.charAt(0).toUpperCase()}${role.slice(1)} `}
              Registration Portal */}

            </h3>

            <p className={`${style.box__paragraph} textColorMedium `}>
              {/* All property owners who have a short-term rental property in
              Palmetto Dunes are required to register with Palmetto Dunes
              Property Owners Association, Inc. (PDPOA). */}

              {/* Please create an account or login below. */}
              {/* Registration is required for all
              {` ${role}s `}
              entering the community.
              If you have previously created an account, please login below to
              register a new visit and receive your community access pass. */}

            </p>

          </div>

          <h5 className={`${style.box__subTitle} textColorDark `}>Please log in to your account</h5>
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert
                color={alertColor}
                title={alert}
                hidden={!alert}
              />

            </div>

          </div>
          <div className={style.inputLong}>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  htmlFor="email-inpt"
                  labelTitle="Email Address "
                  inputType="email"
                  inputValue={email}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />

              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  htmlFor="password-inpt"
                  labelTitle="Password "
                  inputType="password"
                  inputValue={password}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />

              </div>
            </div>
          </div>
          <div className={style.flexBox}>
            <Link to="/forgot-password"><h6 className={`${style.box__forgotPassWord} `}>Forgot Password?</h6></Link>
            <div className={style.box__btnRight}>
              <GenericButton
                size="medium"
                color="blue"
                title="Login"
                type="submit"
              />
            </div>
          </div>

        </form>
      </div>
    </div>
  );
}
Login.defaultProps = {
  disableCreation: true,
};
