import React from 'react';
import { useParams } from 'react-router-dom';
import InfoBackground from '../../../components/infoBackground/infoBackground';
import style from './display.module.css';
import DisplayForm from './displayForm';

type Props = {
  communityName?: string,
  portalName?: string,
  stepNum?: number,
}

// rentalAddress

export type checkBoxes = {
  firstName: boolean,
  lastName: boolean,
  cellPhoneNumber: boolean,
  email: boolean,
  emergencyContactNumber: boolean,
  businessName: boolean,
  businessAddress: boolean,
  country: boolean,
  city: boolean,
  state: boolean,
  zipCode: boolean,
  businessPhoneNumber: boolean,
  make: boolean,
  vehicleModel: boolean,
  type: boolean,
  color: boolean,
  licensePlate: boolean,
  fleetNumber: boolean,
  licensePlateState: boolean,
  primaryDriverName: boolean,
  licensePrimaryDriver: boolean,
  qrCode: boolean,
  destination: boolean,
  homeAddress: boolean,
  homeAddressCountry: boolean,
  homeAddressCity: boolean,
  homeAddressState: boolean,
  homeAddressZipCode: boolean,
  rentalAddress: boolean,
  [key: string]: boolean
}

export default function Display(props: Props): React.ReactElement {
  const {
    communityName, portalName, stepNum,
  } = props;

  const { registrationId } = useParams<{ registrationId: string }>();

  return (
    <div className={style.container}>
      <InfoBackground
        title="Pass Builder"
        showBackBtn
        showPosition
        communityName={communityName}
        portalName={portalName}
      >
        <DisplayForm
          registrationId={registrationId}
          showContinueBtn
          stepNum={stepNum}
          editOff={false}
        />
      </InfoBackground>
    </div>
  );
}
Display.defaultProps = {
  communityName: undefined,
  portalName: undefined,
  stepNum: undefined,
};
