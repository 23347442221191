import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import footerStyle from './footer.module.css';

export const Footer = () :ReactElement => (
  <div className={` ${footerStyle.footer}  `}>
    <p className={` ${footerStyle.footer__title}  `}>
      Copyright
      {' '}
      {' '}
      <span className={footerStyle.titleName}>Symliv</span>
      {' '}
      {' '}
      2021 |
      <Link to="/"><span className={` ${footerStyle.footer__link} `}> Terms of use</span></Link>
      | Powered by Pass Builder
    </p>
  </div>
);
