import { gql, useQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { DurationTypes } from '../../../common_lib/types/passBuilderInfo';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import usePriceInfoForm from '../price/usePriceForm';
import style from './duration.module.css';

const GET_DURATION = gql`
query getPassInfo(
  $passInfoId: String!
){
  getPassInfo(
    passInfoId: $passInfoId
  ){
    success
    error
    data {
      durationInfo { 
        type
        duration1
        duration2
      }
    }
  }
}
`;

type Props = {
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  stepNum?: number,
  submitHandler?: (durationData: DurationData) => void,
}

export type DurationData = {
  type: number,
  duration1: number,
  duration2: number,
}

const INIT_DURATION_TYPE = -1;

export default function DurationForm(props: Props): ReactElement {
  const {
    registrationId,
    editBtnLink,
    showContinueBtn,
    stepNum,
    submitHandler,
  } = props;

  let form;
  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  const [dataInfor, setDataInfor] = useState<DurationData>({
    type: INIT_DURATION_TYPE,
    duration1: 0,
    duration2: 0,
  });

  const editData = (
    name: keyof DurationData,
    val: DurationData[keyof DurationData],
  ) => {
    // check value to avoid user intentionally edits the style
    if (val !== INIT_DURATION_TYPE) {
      const res = { ...dataInfor };
      res[name] = val;
      setDataInfor(res);
    } else {
      // do nothing
    }
  };

  useQuery<{
    getPassInfo: {
      data?: {
        durationInfo?: DurationData,
      }, error: string, success: boolean,
    }
  }>(GET_DURATION, {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
    onCompleted: (d) => { // load data into state variable data
      if (d.getPassInfo.data?.durationInfo) {
        setDataInfor({
          type: d.getPassInfo.data.durationInfo.type,
          duration1: d.getPassInfo.data.durationInfo.duration1,
          duration2: d.getPassInfo.data.durationInfo.duration2,
        });
      }
    },
  });

  // help get charge per day at previous step
  const {
    data,
  } = usePriceInfoForm({ registrationId });

  // check disable when charge per day setted yes/no
  const checkDisableOption = (type: number) => {
    switch (type) {
      case DurationTypes.purchaseOnly:
        // if (data.chargePerDay) {
        editData('type', DurationTypes.purchaseOnly);
        // } else editData('type', INIT_DURATION_TYPE);
        break;
      case DurationTypes.purchaseAndActivation:
        // if (data.chargePerDay) {
        editData('type', DurationTypes.purchaseAndActivation);
        // } else editData('type', INIT_DURATION_TYPE);
        break;
      case DurationTypes.maxValidDays:
        // if (!data.chargePerDay) {
        editData('type', DurationTypes.maxValidDays);
        // } else editData('type', INIT_DURATION_TYPE);
        break;
      default: break;
    }
  };

  return (
    <form
      className={form}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        if (submitHandler) {
          submitHandler(dataInfor);
        }
      }}
    >
      {
        editBtnLink
          ? (
            <div className={style.editBtn}>
              <Link to={editBtnLink} className={style.link}>
                <GenericButton
                  title="Edit"
                />
              </Link>
            </div>
          ) : null
      }
      <div className={style.durationBox}>
        <div className={style.durationTitleBox}>
          <h4 className={style.durationTitle}>
            Step
            {' '}
            {stepNum || 7}
            : Pass Duration

          </h4>

        </div>
        <div
          onClickCapture={() => {
            checkDisableOption(DurationTypes.purchaseOnly);
          }}
          className={style.activeBox}
        >
          <div>
            <h4 className={style.timeInstruction}>
              Number of days from date of purchase that pass is valid
            </h4>

            <div className={style.dayInput}>
              <InputField
                htmlFor="day-opt-1-inpt"
                labelTitle="Days"
                inputType="number"
                step="1"
                min="0"
                inputValue={dataInfor.type === DurationTypes.purchaseOnly ? dataInfor.duration1.toString() : ''} // use to string
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editData('duration1', Number(e.target.value));
                }}
              />

            </div>
          </div>
          <div>
            <div
              className={`${dataInfor.type === DurationTypes.purchaseOnly ? style.circleActive : style.circleInactive} ${!data.chargePerDay ? style.disabled : ''}`}
            />
          </div>

        </div>

        <div>
          <div
            onClickCapture={() => {
              checkDisableOption(DurationTypes.purchaseAndActivation);
            }}
            className={style.activeBox}
          >

            <div>
              <h4 className={style.timeInstruction}>
                Number of days from date of purchase a ready-to-scan or incomplete pass is valid
              </h4>
              <div className={`${style.dayInput} ${style.marginBottom}`}>
                <InputField
                  htmlFor="day-opt-2-inpt"
                  labelTitle="Days"
                  inputType="number"
                  step="1"
                  min="0"
                  inputValue={dataInfor.type === DurationTypes.purchaseAndActivation ? dataInfor.duration1.toString() : ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => (
                    editData('duration1', Number(e.target.value))
                  )}
                />

              </div>
              <h4 className={style.timeInstruction}>
                Number of days pass is valid from the date of activation

              </h4>
              <div className={style.dayInput}>
                <InputField
                  htmlFor="day-opt-3-inpt"
                  labelTitle="Days"
                  inputType="number"
                  step="1"
                  min="0"
                  inputValue={dataInfor.type === DurationTypes.purchaseAndActivation ? dataInfor.duration2.toString() : ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => (
                    editData('duration2', Number(e.target.value))
                  )}
                />

              </div>

            </div>
            <div>
              <div
                className={`${dataInfor.type === DurationTypes.purchaseAndActivation ? style.circleActive : style.circleInactive} ${!data.chargePerDay ? style.disabled : ''}`}
              />
            </div>

          </div>

        </div>
        <div>
          <div
            onClickCapture={() => {
              checkDisableOption(DurationTypes.maxValidDays);
            }}
            // eslint-disable-next-line max-len
            className={dataInfor.type === DurationTypes.maxValidDays || data.chargePerDay === 0 ? style.activeBox : style.inactiveBox}
          >
            <div>
              <h4 className={style.timeInstruction}>
                Number of days that a user-specified pass date range can be valid
              </h4>

              <div className={style.dayInput}>
                <InputField
                  htmlFor="day-opt-4-inpt"
                  labelTitle="Days"
                  inputType="number"
                  step="1"
                  min="0"
                  disabled={false}
                  inputValue={dataInfor.type === DurationTypes.maxValidDays ? dataInfor.duration1.toString() : ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => (
                    editData('duration1', Number(e.target.value))
                  )}
                />

              </div>
            </div>
            <div>
              <div
                className={`${dataInfor.type === DurationTypes.maxValidDays ? style.circleActive : style.circleInactive} ${data.chargePerDay ? style.disabled : ''}`}
              />

            </div>
          </div>
        </div>
      </div>

      {showContinueBtn ? (
        // <Link to={`/pass-builder/${registrationId}/instructions`}>
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />
          </div>
        </div>
        // </Link>
      ) : null}
    </form>
  );
}
DurationForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  stepNum: undefined,
  submitHandler: undefined,
};
