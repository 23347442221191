import { gql, useQuery } from '@apollo/client';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import DragAndDrop from '../../../components/dragAndDrop/dragAndDrop';
import style from './userInfo.module.css';

const GET_USER_INFO = (portalName: string) => {
  switch (portalName) {
    case 'guest':
      return gql`
        query GetGuestInfoConfig (
          $passInfoId: String!
        ) {
          getGuestInfoConfig (
            passInfoId: $passInfoId
          ) {
            success
            error
            data {
              firstName
              email
              lastName
              address
              phoneNumber
              emergencyContact
              rentalAddress
              arrivalDate
              departureDate
              numberGuests
            }
          }
        }
        `;
    case 'vendor':
      return gql`
        query GetVendorInfoConfig (
          $passInfoId: String!
        ) {
          getVendorInfoConfig (
            passInfoId: $passInfoId
          ) {
            success
            error
            data {
              firstName
              lastName
              email
              businessName
              businessAddress
              cellPhone
              businessPhone
            }
          }
        }
        `;
    default:
      return gql`
        query GetGuestInfoConfig (
          $passInfoId: String!
        ) {
          getGuestInfoConfig (
            passInfoId: $passInfoId
          ) {
            success
            error
            data {
              firstName
              email
              lastName
              address
              phoneNumber
              emergencyContact
              rentalAddress
              arrivalDate
              departureDate
              numberGuests
            }
          }
        }
        `;
  }
};

const commonDefaultItems = [
  {
    title: 'First Name', name: 'firstName', value: 'required', lock: true,
  },
  {
    title: 'Last Name', name: 'lastName', value: 'required', lock: true,
  },
  {
    title: 'Email', name: 'email', value: 'required', lock: true,
  },
];

const vendorDefaultItems = [
  ...commonDefaultItems,
  { title: 'Cell Phone', name: 'cellPhone', value: 'optional' },
  {
    title: 'Business Name (Vendor ony)', name: 'businessName', value: 'required', lock: true,
  },
  { title: 'Business Address', name: 'businessAddress', value: 'optional' },
  { title: 'Business Phone Number', name: 'businessPhone', value: 'optional' },
];

const guestDefaultItems = [
  ...commonDefaultItems,
  { title: 'Cell Phone', name: 'phoneNumber', value: 'optional' },
  { title: 'Address', name: 'address', value: 'optional' },
  { title: 'Emergency Contact', name: 'emergencyContact', value: 'optional' },
  { title: 'Rental Address', name: 'rentalAddress', value: 'optional' },
  { title: 'Arrival Date', name: 'arrivalDate', value: 'optional' },
  { title: 'Departure Date', name: 'departureDate', value: 'optional' },
  { title: 'Number Of Guests', name: 'numberGuests', value: 'optional' },
];

export default function UserInfoForm(props: {
  portalName: string,
  registrationId: string,
  editBtnLink?: string,
  showContinueBtn?: boolean,
  submitHandler?: (data: Array<{
    title: string,
    name: string,
    value: string,
  }>) => void,
  stepNum?: number,
}): ReactElement {
  const {
    portalName, registrationId, editBtnLink, showContinueBtn, submitHandler, stepNum,
  } = props;

  const getItems = useCallback(() => {
    switch (portalName.toLowerCase()) {
      case 'guest': return guestDefaultItems;
      case 'vendor': return vendorDefaultItems;
      default: return commonDefaultItems;
    }
  }, [portalName]);

  const [itemList, setItemList] = useState<Array<{ title: string, name: string, value: string }>>(
    getItems(),
  );
  const updateItem = (name: string, value: string) => {
    const res = [...itemList];
    for (let i = 0; i < res.length; i += 1) {
      if (res[i].name === name) {
        res[i].value = value;
        break;
      }
    }
    setItemList(res);
  };

  useEffect(() => {
    const items = getItems();
    setItemList(items);
  }, [getItems]);

  let form;

  if (editBtnLink) {
    form = style.formReview;
  } else {
    form = style.form;
  }

  const { data, loading, error } = useQuery(GET_USER_INFO(portalName.toLowerCase()), {
    fetchPolicy: 'network-only',
    variables: {
      passInfoId: registrationId,
    },
    onCompleted: (d) => {
      if (d.getGuestInfoConfig?.data) {
        Object.entries(d.getGuestInfoConfig.data).forEach(([key, val]) => {
          updateItem(key, val as string);
        });
      }
    },
  });

  return (
    <form
      className={form}
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        if (submitHandler) {
          submitHandler(itemList);
        }
      }}
    >
      {
        editBtnLink
          ? (
            <div className={style.editBtn}>
              <Link to={editBtnLink} className={style.link}>
                <GenericButton
                  title="Edit"
                />
              </Link>

            </div>
          ) : null
      }
      <div className={style.userInfoBox}>
        <div className={style.userInfoTitleBox}>
          <h1 className={style.userInfoTitle}>
            Step
            {' '}
            {stepNum || 2}
            : User Information
          </h1>

        </div>
        <DragAndDrop
          id="user-info-dnd"
          containers={[
            { title: 'Required', value: 'required' },
            { title: 'Optional', value: 'optional' },
            { title: 'Disabled', value: 'disabled' },
          ]}
          items={itemList}
          updateItem={updateItem}
        />

      </div>
      {showContinueBtn ? (
        <div>
          <div className={style.continueBtn}>
            <GenericButton
              title="Continue"
              type="submit"
            />
          </div>
        </div>
      ) : null}
    </form>

  );
}
UserInfoForm.defaultProps = {
  editBtnLink: undefined,
  showContinueBtn: false,
  submitHandler: undefined,
  stepNum: undefined,
};
