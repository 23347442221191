import React, { ReactElement, useState } from 'react';
import DragItem from './dragItem';
import DropContainer from './dropContainer';
import style from './dragAndDrop.module.css';

export type DragAndDropItem = {
    title: string,
    name: string,
    value: string,
    lock?: boolean,
}

export type DragAndDropContainer = {
    title: string,
    value: string,
}

export default function DragAndDrop(props: {
  updateItem: (name:string, value:string) => void,
  deleteItem?: (name:string) => void,
  containers: Array<DragAndDropContainer>,
  items: Array<DragAndDropItem>,
  id: string,
}) : ReactElement {
  const {
    updateItem, deleteItem, containers, items, id,
  } = props;

  const [destContainer, setDestContainer] = useState<string>('');

  return (
    <div className={style.container}>
      {containers.map((c) => (
        <DropContainer
          id={`${id}-${c.value}`}
          key={`${id}-${c.value}`}
          title={c.title}
          onDragLeave={() => setDestContainer('')}
          onDragOver={() => setDestContainer(c.value)}
        >
          {items
            .filter((i) => i.value === c.value)
            .map((i) => (
              <DragItem
                id={`${id}-${c.value}-${i.title}`}
                deleteItem={deleteItem ? () => deleteItem(i.name) : undefined}
                key={`${id}-${c.value}-${i.title}`}
                title={i.title}
                onDrop={() => {
                  if (destContainer) {
                    updateItem(i.name, destContainer);
                  }
                }}
                locked={i.lock}
              />
            ))}
        </DropContainer>
      ))}
    </div>
  );
}
DragAndDrop.defaultProps = {
  deleteItem: undefined,
};
