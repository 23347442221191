import { Link, useHistory } from 'react-router-dom';
import React, { ReactElement, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import '../../../App';
import style from './createAccount.module.css';
import { Footer } from '../../../components/footer/footer';
// import { communityPicker, getAssets } from '../../../common_lib_front/communityConfigs';
import store from '../../../common_lib_front/utilities/store';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';

const REGISTER_MUTATION = gql`
  mutation RegisterUser(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $password: String!,
  ) {
    registerUser(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
    ) {
      success
      error
      token
      data {
        firstName
        lastName
        email
        roles
        communityCode
      }
    }
  }
`;

export default function CreateAccount() :ReactElement {
  const history = useHistory();
  const [fname, setFname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const [doRegister] = useMutation(REGISTER_MUTATION, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (d.registerUser.success) {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: d.registerUser.token,
            user: {
              ...d.registerUser.data,
              communityCode: d.registerUser.data.communityIdentifier,
            },
          },
        });
        history.push('/');
      } else {
        setAlert(d.registerUser.error);
        setAlertColor('red');
      }
    },
  });

  const submitHandler = () => {
    // const checkedCC = ['resident'].includes(role) ? registrationCode : communityPicker();
    // console.log(checkedCC);
    if ([fname, lname, email, password].some((i:string) => i === '')) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
    } else if (confirmPassword !== password) {
      setAlert('Those passwords to not match');
      setAlertColor('red');
    } else {
      doRegister({
        variables: {
          communityIdentifier: '', // communityPicker(),
          firstName: fname,
          lastName: lname,
          email,
          password,
        },
      });
    }
  };

  return (

    <div className={style.container}>
      <div className={`${style.box} white `}>
        <form
          className={style.box__left}
          onSubmit={(e:React.FormEvent) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <div className={style.box__titleBoxLeft}>
            <h3 className={`${style.box__mainTitleLeft} textColorDark `}>
              Welcome to Symliv Admin Registration Portal
            </h3>
            <p className={`${style.box__paragraph} textColorMedium `}>
              Registration is required for all Symliv Admins entering the community.
              Please create an account below to begin your registration
              and receive your community access pass.
            </p>

          </div>
          <h5 className={`${style.box__subTitle} textColorDark `}>Please create your account</h5>
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert
                color={alertColor}
                title={alert}
                hidden={!alert}
              />

            </div>

          </div>
          <div className={style.box__nameBox}>
            <div className={style.box__inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="first-name-inpt"
                labelTitle="First Name"
                inputType="text"
                inputValue={fname}
                changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                  setFname(e.target.value);
                }}
              />
            </div>
            <div className={style.invisbleBox} />
            <div className={style.box__inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="last-name-inpt"
                labelTitle="Last Name"
                inputType="text"
                inputValue={lname}
                changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                  setLname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.inputLong}>

            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="email-inpt"
                  labelTitle="Email Address"
                  inputType="email"
                  inputValue={email}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />

              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="password-inpt"
                  labelTitle="Password"
                  inputType="password"
                  inputValue={password}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />

              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="verify-password-inpt"
                  labelTitle="Verify Password"
                  inputType="password"
                  inputValue={confirmPassword}
                  changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmPassword(e.target.value);
                  }}
                />

              </div>
            </div>

          </div>

          <div className={style.box__btnLeft}>
            <div className={style.box__btnLeftInnerBox}>
              <GenericButton
                color="blue"
                size="medium"
                title="Create Account"
                type="submit"
              />

            </div>
          </div>
        </form>
        <div className={`${style.box__right}  linerGradient `}>

          <img src="bigLogo" alt="" className={style.box__img} />
          <h1 className={`${style.box__mainTitleRight} textColorWhite `}><div className={style.box__mainTitleRightInnerBox}>Do you already have an account?</div></h1>
          <Link to="/login">
            <div className={style.box__btnRight}>
              <div className={style.box__btnRightInnerBox}>
                <GenericButton
                  color="white"
                  size="medium"
                  title="Login"
                />

              </div>
            </div>
          </Link>

        </div>
      </div>
      <Footer />
    </div>

  );
}
