import {
  gql, OperationVariables, useLazyQuery, useQuery, ApolloQueryResult, useMutation,
} from '@apollo/client';
import React, { useEffect, useState } from 'react';

const DELETE_EDUCATION = gql`
mutation DeleteEducation($educationId: String!) {
  deleteEducation(educationId: $educationId) {
    success
    error
  }
}
`;

const GET_EDUCATIONS = gql`
query GetEducations(
  $communityId: String!
) {
  getEducationsByCommunity(
    communityId: $communityId
  ) {
    success
    error
    data {
      educationId
      name
    }
  }
}
`;

const UPLOAD_EDUCATION = gql`
mutation UploadEducation(
  $scormFile: String!, 
  $name: String!, 
  $communityId: String!
) {
  addEducation(
    scormFile: $scormFile, 
    name: $name, 
    communityId: $communityId
  ) {
    success
    error
  }
}
`;

type getEducationQueryType = {
  getEducationsByCommunity: {
    success: boolean,
    error: string,
    data?: Array<EducationType>,
  }
}

export type EducationType = {
  educationId: string,
  name: string,
}

export default function useUploadEducation() :{
  community: string,
  setCommunity: (val: string) => void,
  educations: Array<EducationType>,
  educationsLoading: boolean,
  educationsError?: string,
  deleteEducation: (educationId: string) => Promise<any>,
  educationsRefetch: () => Promise<ApolloQueryResult<getEducationQueryType>>,
  uploadEducation: (name: string, file: string) => Promise<any>,
  } {
  const [community, setCommunity] = useState<string>('');

  const [doDeleteEducation] = useMutation(DELETE_EDUCATION);
  const [doFetchEducations,
    {
      data, loading, error, refetch,
    },
  ] = useLazyQuery<getEducationQueryType>(GET_EDUCATIONS);
  useEffect(() => {
    if (community) {
      doFetchEducations({
        variables: { communityId: community },
      });
    }
  }, [community]);

  const [doUploadEducation] = useMutation(UPLOAD_EDUCATION);

  return {
    community,
    setCommunity,
    educations: data?.getEducationsByCommunity.data || [],
    educationsLoading: loading,
    educationsError: error?.message || data?.getEducationsByCommunity.error,
    deleteEducation: (educationId: string) => doDeleteEducation({
      variables: {
        educationId,
      },
    }),
    educationsRefetch: () => refetch({
      variables: {
        communityId: community,
      },
    }),
    uploadEducation: async (name: string, file: string) => doUploadEducation({
      variables: {
        scormFile: file,
        name,
        communityId: community,
      },
    })
      .then(() => refetch()),
  };
}
