import React, { ReactElement, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import EditProfileTemplate from '../../../components/editProfileTemplate/editProfileTemplate';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import alertIcon from '../../../assets/alert.svg';
import style from './myProfile.module.css';
import UserInfo, { newUserInfo } from '../../../common_lib_front/types/userInfo';
import { checkNull } from '../../../common_lib_front/utilities/BackendAPI';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import store from '../../../common_lib_front/utilities/store';
// import SchemeDecider from '../../../common_lib_front/utilities/schemeDecider';

// const colorStyle :any = {}; // SchemeDecider();

export const EDIT_USER_INFO = gql`
mutation EditUserInfo(
  $zipCode: String,
  $city: String,
  $state: String,
  $country: String,
  $address: String,
  $phoneNumber: String,
  $lastName: String,
  $firstName: String,
) {
  editUserInfo(
    data: {
      zipCode: $zipCode,
      city: $city,
      state: $state,
      country: $country,
      address: $address,
      phoneNumber: $phoneNumber,
      lastName: $lastName,
      firstName: $firstName,
    }
  ) {
    success
    error
  }
}
`;

export const GET_PROFILE = gql`
query getUserInfo {
  getUserInfo {
    success
    error
    data {
      firstName
      lastName
      phoneNumber
      address
      country
      city
      state
      zipCode
    }
  }
}
`;

export default function MyProfile(): ReactElement {
  const [userInfo, setUserInfo] = useState<UserInfo>(newUserInfo());
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const { data, refetch } = useQuery(GET_PROFILE, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.getUserInfo.success) {
        setAlert(d.getUserInfo.error);
        setAlertColor('red');
      }
    },
  });

  const [doSubmit] = useMutation(EDIT_USER_INFO, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      refetch();
      setUserInfo(newUserInfo());
      if (d.editUserInfo.success) {
        setAlert('changes saved successfully');
        setAlertColor('green');
        if (userInfo.firstName !== '' || userInfo.lastName !== '') {
          store.dispatch({
            type: 'UPDATE_PROFILE',
            payload: userInfo,
          });
        }
      } else {
        setAlert(d.editUserInfo.error);
        setAlertColor('red');
      }
    },
  });

  return (
    <EditProfileTemplate
      active="profile"
    >
      <div className={`${style.subHeader} `}>
        <div className={`${style.circle} white `} />
        <h1 className={`${style.title} textColorWhite `}>
          My Profile
        </h1>
      </div>
      <div className={style.form}>
        <form
          className={style.innerForm}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            doSubmit({
              variables: checkNull(userInfo),
            });
          }}
        >
          <GenericAlert
            color={alertColor}
            title={alert}
            hidden={!alert}
          />
          <div className={style.inputBox}>
            <div className={style.inputShort}>
              <InputField
                htmlFor="f-name-inpt"
                labelTitle="First Name"
                inputType="text"
                inputPlaceholder={data?.getUserInfo.data.firstName}
                inputValue={userInfo.firstName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUserInfo({ ...userInfo, firstName: e.target.value });
                }}
              />

            </div>
            <div className={style.inputShort}>
              <InputField
                htmlFor="l-name-inpt"
                labelTitle="Last Name"
                inputType="text"
                inputPlaceholder={data?.getUserInfo.data.lastName}
                inputValue={userInfo.lastName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUserInfo({ ...userInfo, lastName: e.target.value });
                }}
              />

            </div>
          </div>
          <div className={style.inputLong}>
            <InputField
              htmlFor="phone-inpt"
              labelTitle="Phone Number"
              inputType="tel"
              inputPlaceholder={data?.getUserInfo.data.phoneNumber}
              inputValue={userInfo.phoneNumber}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserInfo({ ...userInfo, phoneNumber: e.target.value });
              }}
            />
          </div>
          <div className={style.inputLong}>
            <InputField
              htmlFor="emergency-phone-inpt"
              labelTitle="Emergency Phone Number"
              inputType="tel"
            />

          </div>

          <div className={style.inputLong}>
            <InputField
              htmlFor="email-inpt"
              labelTitle="Email Address"
              inputType="email"
              inputPlaceholder={data?.getUserInfo.data.email}
              inputValue={userInfo.email}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserInfo({ ...userInfo, email: e.target.value });
              }}
            />
          </div>
          <label htmlFor="role-select" className={`${style.labelBox} textColorMedium`}>
            Role
            <select id="role-select" className={style.selectBox}>
              <option>Admin</option>
              <option>Vendor</option>
              <option>Resident</option>
              <option>Guest</option>
            </select>
          </label>
          <div className={style.alertBox}>
            <img src={alertIcon} alt="" />
            <p className={`${style.alert} textColorMedium `}>Please verify that the above information is current and accurate.</p>

          </div>
          <div className={style.btnBox}>
            <div className={style.saveBtn}>
              <GenericButton
                size="large"
                type="submit"
                title="Save Changes"
              />
            </div>
          </div>
        </form>
      </div>
    </EditProfileTemplate>
  );
}
