import React, { ReactElement } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './dragItem.module.css';

export default function DragItem(props: {
  id: string,
  title: string,
  deleteItem?: () => void,
  locked?: boolean,
  onDrop: () => void,
}) :ReactElement {
  const {
    id, title, locked, onDrop, deleteItem,
  } = props;

  return (
    <div
      id={id}
      draggable={!locked}
      onDragEnd={() => {
        onDrop();
      }}
      className={`${style.dragItemBox} ${locked ? style.locked : ''}`}
    >
      {title}
      {
        deleteItem
          ? (
            <div className={style.exitBtn}>
              <GenericButton
                title=""
                clickHandler={deleteItem}
              />

            </div>
          ) : null
      }
    </div>
  );
}
DragItem.defaultProps = {
  locked: false,
  deleteItem: undefined,
};
